import { useSelector } from 'react-redux';
import JSZip from 'jszip';

import Button from '@mui/material/Button';

import '../../../containers/Container.scss';
import { useLocation } from 'react-router-dom';
import { selectModules, selectSelectedWorkflow } from '../../../reducers/workflow';
import compile from '../../../compilerDecompiler/compiler';
import { selectCustomTextConfig, selectCustomUIConfig, selectSelectedLanguage } from '../../../reducers/editBranding';
import { compileUiConfig } from '../../../containers/uiConfigOperations';

function DownloadWorkflow() {
  const { search } = useLocation();
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const customTextConfig = useSelector(selectCustomTextConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const workflowModules = useSelector(selectModules);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const workflowId = new URLSearchParams(search).get('id');

  const handleClick = async () => {
    const compiledWorkflow = compile(selectedWorkflow, workflowModules);
    const zip = new JSZip();

    // Add JSON files to the ZIP
    zip.file(`${workflowId}.json`, JSON.stringify(compiledWorkflow));
    if (Object.keys(customUiConfig).length > 0) {
      const compiledUiConfig = compileUiConfig(customUiConfig, compiledWorkflow);
      zip.file(`${workflowId}_ui_config.json`, JSON.stringify(compiledUiConfig));
    }
    if (Object.keys(customTextConfig).length > 0) {
      zip.file(`${workflowId}_${selectedLanguage || 'en'}_text_config.json`, JSON.stringify(customTextConfig));
    }

    // Generate the ZIP blob
    const content = await zip.generateAsync({ type: 'blob' });

    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${workflowId}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      className="more-options-workflow"
    >
      Download Workflow
    </Button>
  );
}

export default DownloadWorkflow;
