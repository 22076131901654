import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomStringSelection from './CustomStringSelection';
import { selectFormComponents } from '../../../../reducers/dynamicForm';

function StringInput({ value, onChange, label }) {
  const orderOfModules = useSelector((state) => state.workflow.orderOfNodes);
  const moduleConfigs = useSelector((state) => state.workflow.modules);
  const selectedNodeId = useSelector((state) => state.workflow.selectedNode)?.id;
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);
  const formComponents = useSelector(selectFormComponents);

  const stringifiedValue = value === null || value === undefined ? '' : `${value}`;
  return (
    <CustomStringSelection
      value={stringifiedValue}
      moduleConfigs={moduleConfigs}
      setOutputValue={onChange}
      label={label}
      orderOfModules={orderOfModules}
      selectedNodeId={selectedNodeId}
      selectedWorkflow={selectedWorkflow}
      formComponents={formComponents}
    />
  );
}

StringInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default StringInput;
