import { useState } from 'react';
import PropTypes from 'prop-types';
import WorkflowOutputVariable from './WorkflowOutputVariable';

function WorkflowOutput({
  sdkResponse,
  setVariableName,
  addNewVariable,
  deleteVariable,
  setOutputValue,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const displayForm = () => (
    <div className="workflowOutput__bottomContent_body">
      <div className="conditional__content__rules_info">
        <div className="conditional__content__rules_info_heading">Rules for writing custom Input!</div>
        Strings should be in single quotes, Numbers should be like 200, 300, 400,
        Boolean should be among true or false.
      </div>
      {
        sdkResponse.map(({ variable, value, id }) => (
          <WorkflowOutputVariable
            key={id}
            id={id}
            variable={variable}
            value={value}
            setVariableName={setVariableName}
            setOutputValue={setOutputValue}
            deleteVariable={deleteVariable}
            sdkResponse={sdkResponse}
          />
        ))
      }
      <button type="button" onClick={addNewVariable} className="workflowInput__bottomContent_button">Add Output Variables</button>
    </div>
  );
  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">Workflow Outputs</div>
        <div className="workflowInput__topContent_subheading">
          If you want to get outputs from the workflow, please configure the SDK response below
        </div>
      </div>
      <div className="workflowInput__bottomContent">
        <div className="options-ribbon__configurations">
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(1)}
          >
            Configure
          </button>
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(2)}
          >
            Schema Preview
          </button>
        </div>
        {
            activeTab === 2
              ? <span>Work in progress</span>
              : displayForm()
          }
      </div>
    </>
  );
}

WorkflowOutput.propTypes = {
  setVariableName: PropTypes.func.isRequired,
  addNewVariable: PropTypes.func.isRequired,
  sdkResponse: PropTypes.array.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  setOutputValue: PropTypes.func.isRequired,
};
export default WorkflowOutput;
