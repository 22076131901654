/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import { Button } from '@mui/base';
import deleteIcon from '../../../../assests/icons/deleteIcon3.svg';
import Divider from '../../Divider';

function ConditionalVariableTitleRibbon({
  setShowDrawer, handleCreate, handleDelete, handleBackClick,
}) {
  const handleBackButton = () => {};

  const handleDeleteConditionalVariable = () => {};

  return (
    <>
      <div className="conditionalVar__title__div">
        <div className="conditionalVar__title__back_div">
          <button
            type="button"
            className="conditionalVar__title__back"
            onClick={handleBackClick}
          >
            <img src="" alt="" />
          </button>
          <div className="conditionalVar__title">
            New Conditional Variable
          </div>
        </div>
        <div className="conditionalVar__title__close-div">
          <Button
            className="conditionalVar__title__create"
            onClick={handleCreate}
          >
            Create

          </Button>
          <button
            type="button"
            className="conditionalVar__title__delete"
            onClick={handleDelete}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </div>
      </div>
      <Divider topMargin="4px" />
    </>
  );
}

export default ConditionalVariableTitleRibbon;

ConditionalVariableTitleRibbon.propTypes = {
  setShowDrawer: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};
