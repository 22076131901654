/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import './NewConditionDrawer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import EditConditionModal from './EditConditionModal';
import findPreviousModules from '../InputsToModule/utils/findPreviousModules';

import {
  selectSelectedNode, selectModules,
  selectSelectedWorkflow, selectOrderOfNodes, updateSelectedWorkflow,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { swapBranchesInCondition } from '../../../../containers/workflowOperations';
import { logConditionRuleUpdate } from '../../../../logger/logHighLevelWorkflowUpdates';

function NewConditionDrawer() {
  const dispatch = useDispatch();
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const selectedNodeId = useSelector(selectSelectedNode).id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const moduleConfigs = useSelector(selectModules);
  const [showConditionEditor, setShowConditionEditor] = useState(false);
  const [rule, setRule] = useState(selectedWorkflow?.conditions[selectedNodeId]?.rule);
  const [previousModules, setPreviousModules] = useState([]);

  const handleAddConditionClick = () => {
    setShowConditionEditor(true);
  };

  const handleClose = () => {
    setShowConditionEditor(false);
  };

  const onSwapBranch = () => {
    const editedWorkflow = swapBranchesInCondition(selectedNodeId, selectedWorkflow);
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    const editedWorkflow = cloneDeep(selectedWorkflow);
    const selectedRule = editedWorkflow.conditions[selectedNodeId];
    const oldRule = selectedRule.rule;
    if (selectedRule) selectedRule.rule = rule;
    logConditionRuleUpdate({
      id: selectedNodeId,
      oldRule,
      newRule: rule,
    });
    updateWorkflowInState(editedWorkflow);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  useEffect(() => {
    const indexOfCurrentModule = orderOfNodes.findIndex(
      (module) => module.id === selectedNodeId,
    );
    const previousModulesArr = findPreviousModules(orderOfNodes, indexOfCurrentModule);
    const previousModuleConfigs = [];

    selectedWorkflow.modules.forEach((module) => {
      if (previousModulesArr.includes(module.id)) {
        const config = cloneDeep(moduleConfigs[`${module.subType}_uiConfig`] || moduleConfigs[module.subType]);
        config.moduleId = module.id;
        config.moduleName = module.name;
        previousModuleConfigs.push(config);
      }
    });

    setPreviousModules(previousModuleConfigs);

    const condition = selectedWorkflow.conditions[selectedNodeId];
    setRule(condition.rule);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeId, orderOfNodes]);

  return (
    <div className="condition-properties-div">
      <div className="condition-properties-div__title">Branches</div>
      <button
        type="button"
        onClick={handleAddConditionClick}
        className="condition-properties-div__condition-button"
      >
        if (Branch 1)
      </button>
      <button
        type="button"
        className="condition-properties-div__condition-button"
        disabled
      >
        else (Branch 2)
      </button>

      <button
        type="button"
        className="condition-properties-div__swap-button"
        onClick={onSwapBranch}
      >
        Swap branches
      </button>

      {showConditionEditor && (
      <EditConditionModal
        closeModal={handleClose}
        rule={rule}
        setRule={setRule}
        previousModules={previousModules}
      />
      )}
    </div>
  );
}

export default NewConditionDrawer;
