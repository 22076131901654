/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { cloneDeep } from 'lodash';
import { getNextStepForModule } from '../components/utils';

const endStates = ['approve', 'decline', 'manualReview'];
// eslint-disable-next-line no-unused-vars
const isCountryModule = (workflowConfig, id) => {
  const module = workflowConfig?.modules?.filter((module) => module.id === id)[0];
  return (module?.type === 'countries');
};

// eslint-disable-next-line arrow-body-style, no-unused-vars
const attachGoto = (workflowConfig, id, path) => {
  if (workflowConfig.conditions[id]) {
    const existingNextNodeConfig = workflowConfig.conditions[id]?.next_node_type || {};
    workflowConfig.conditions[id].next_node_type = { ...existingNextNodeConfig, [path]: 'goto' };
  } else {
    const module = workflowConfig.modules?.filter((module) => module.id === id)[0];
    const existingNextNodeConfig = module.next_node_type || {};
    module.next_node_type = { ...existingNextNodeConfig, [path]: 'goto' };
  }
  return workflowConfig;
};

const hasGoto = (workflowConfig, id, path) => {
  if (workflowConfig.conditions[id]) {
    // Its an id of condition
    return workflowConfig.conditions[id]?.next_node_type?.[path] === 'goto';
  }
  // Its a module
  const module = workflowConfig.modules?.filter((module) => module.id === id)[0];
  return module?.next_node_type?.[path] === 'goto';
};

const getNextStepsForModule2 = (module) => {
  const nextSteps = getNextStepForModule(module);
  // filter for endstates
  const finalNextSteps = nextSteps
    .filter(({ nextStep }) => !endStates.includes(nextStep))
    .map(({ nextStep, path }) => ({ id: nextStep, path }));
  return finalNextSteps;
};

const updateGotoTagsInWorkflow = (workflow) => {
  // Update goto tags if they aren't present for some or all branches
  let workflowConfig = cloneDeep(workflow);
  const nextStepsMap = {};

  // Get nextSteps
  if (workflowConfig?.module || workflowConfig?.conditions) {
    (workflowConfig?.modules || []).forEach((module) => {
      const moduleId = module.id;
      nextStepsMap[moduleId] = [];
      if (module.type === 'dynamicForm') {
        // Remove next_node_type details from the dynamic form as the values get messed up if components are swapped/ added or deleted
        module.next_node_type = {};
        nextStepsMap[moduleId] = getNextStepsForModule2(module);
      } else if (!endStates.includes(module.nextStep)) {
        nextStepsMap[moduleId].push({
          id: module.nextStep,
          path: 'default',
        });
      }
    });
    const conditions = Object.keys(workflowConfig?.conditions || {});
    conditions.forEach((conditionId) => {
      nextStepsMap[conditionId] = [];
      const condition = workflowConfig.conditions[conditionId];
      if (!endStates.includes(condition.if_true)) {
        nextStepsMap[conditionId].push({
          id: condition.if_true,
          path: 'if_true',
        });
      }
      if (!endStates.includes(condition.if_false)) {
        nextStepsMap[conditionId].push({
          id: condition.if_false,
          path: 'if_false',
        });
      }
    });
  }

  const parentMap = {};
  const nodes = Object.keys(nextStepsMap);
  nodes.forEach((parentId) => {
    const children = nextStepsMap[parentId];
    children.forEach((child) => {
      const { id, path } = child;
      if (parentMap[id]) parentMap[id].push({ id: parentId, path });
      else parentMap[id] = [{ id: parentId, path }];
    });
  });

  // Populating goto
  const nodes2 = Object.keys(parentMap);
  nodes2.forEach((nodeId) => {
    const parents = parentMap[nodeId];
    // leave one and attach goto to all execpt for country as its parent is start
    let addGoto = isCountryModule(workflow, nodeId) ? true : false;
    parents.forEach((parent) => {
      const { id, path } = parent;
      if (addGoto) {
        workflowConfig = attachGoto(workflowConfig, id, path);
      } else if (!hasGoto(workflowConfig, id, path)) {
        addGoto = true;
      }
    });
  });
  return workflowConfig;
};

export default updateGotoTagsInWorkflow;
