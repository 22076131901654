/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

import './ColorPicker.scss';

function ColorPicker(props) {
  const {
    type, name, color, onChange,
  } = props;

  const [showPicker, setShowPicker] = useState(false);

  const handleColorChange = (newColor) => {
    onChange(type, newColor.hex);
  };

  const handleInputChange = (event) => {
    onChange(type, event.target.value);
  };

  const openPicker = () => {
    setShowPicker(true);
  };

  const closePicker = () => {
    setShowPicker(false);
  };

  return (
    <div>
      <div
        className={type ? 'color-picker' : 'button-color-picker'}
        onMouseLeave={closePicker}
      >
        <div className={type ? 'color-picker__container' : 'button-color-picker__container'}>
          <div className={type ? 'color-picker__label' : 'button-color-picker__label'}>
            {name}
          </div>
          <div className="color-picker__subcontainer" onClick={openPicker}>
            <div
              className="color-picker__preview"
              style={{ backgroundColor: color }}
            />
            <input
              type="text"
              className="color-input"
              value={color?.toUpperCase()}
              onChange={handleInputChange}
            />
          </div>
          {showPicker && (
            <ChromePicker
              color={color}
              className="color-picker__chrome-picker"
              onChange={handleColorChange}
            />
          )}
        </div>
      </div>

    </div>
  );
}
ColorPicker.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  type: '',
};

export default ColorPicker;
