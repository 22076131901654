import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SingleSelectDropDown.scss';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { selectModules, selectSelectedNode, selectSelectedWorkflow } from '../../../../reducers/workflow';
import { setAllowedInputCombinations, setSelectedInputCombination } from '../../../../reducers/inputsToModule';
import setDefaultValue from './utils/setDefaultValue';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { setDefaultParams, setModulePropertyInWorkflow, unsetModulePropertyInWorkflow } from '../InputsToModule/utils/updateWorkflow';

function SingleSelectDropDown({ options }) {
  const dispatch = useDispatch();
  const {
    title, values, text, workflowKey, key,
  } = options;

  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];
  const selectedWorkflowModules = selectedWorkflow.modules;
  const [selectedModule, setSelectedModule] = useState(selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  ));

  if (workflowKey === 'requestParametersCombinations') {
    dispatch(setAllowedInputCombinations({ inputsCombinations: values }));
  }

  const [selectedOption, setSelectedOption] = useState(
    setDefaultValue(selectedModule, workflowKey, options, values),
  );

  useEffect(() => {
    // Temporary fix to render exact state until this component is refactored
    setSelectedModule(selectedWorkflowModules.find(
      (module) => module.id === selectedNodeId,
    ));
  }, [JSON.stringify(selectedWorkflow)]);

  useEffect(() => {
    setSelectedModule(selectedWorkflowModules?.find(
      (module) => module.id === selectedNodeId,
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeId]);

  useEffect(() => {
    setSelectedOption(
      setDefaultValue(selectedModule, workflowKey, options, values),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule]);

  const onUnSelect = () => {
    const editedWorkflow = unsetModulePropertyInWorkflow(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
    );
    updateWorkflowInState(editedWorkflow);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    let changedValue = '';
    if (workflowKey === 'requestParametersCombinations') {
      changedValue = values.find((option) => option.join(',') === selectedValue);
    } else {
      changedValue = selectedValue;
    }
    if (changedValue === '') onUnSelect();
    else setSelectedOption(changedValue);
  };

  useEffect(() => {
    if (workflowKey === 'requestParametersCombinations') {
      const editedWorkflow = setDefaultParams(
        selectedOption,
        selectedModuleConfig,
        selectedWorkflow,
        selectedNodeId,
      );
      updateWorkflowInState(editedWorkflow, true);
      dispatch(setSelectedInputCombination({ selectedOption }));
    } else if (workflowKey === 'requestParameters') {
      // find the key in the requestParameters in the workflow
      const editedWorkflow = cloneDeep(selectedWorkflow);
      const selectedModuleInWorkflow = editedWorkflow.modules
        .find((module) => module.id === selectedNodeId);
      const currentReqParams = selectedModuleInWorkflow.properties.requestParameters;
      if (currentReqParams) {
        editedWorkflow.modules.forEach((mod, idx) => {
          if (mod.id === selectedNodeId) {
            currentReqParams.forEach((param, index) => {
              if (param.name === key) {
                // eslint-disable-next-line prefer-destructuring
                editedWorkflow.modules[idx].properties.requestParameters[index].value =
                  selectedOption[0];
              }
            });
          }
        });
      }
      updateWorkflowInState(editedWorkflow, true);
    } else {
      const editedWorkflow = setModulePropertyInWorkflow(
        selectedWorkflow,
        selectedNodeId,
        workflowKey,
        selectedOption,
        selectedModuleConfig,
      );
      updateWorkflowInState(editedWorkflow, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const isCurrentOptionSelected = (optionValue, selectedValue) => {
    const isEqual = JSON.stringify(optionValue) === JSON.stringify(selectedValue);
    return isEqual;
  };

  return (
    <div className="single-select-dropdown">
      <h2 className="single-select-dropdown__label">{title}</h2>
      <select
        className="single-select-dropdown__options"
        onChange={handleChange}
      >
        <option
          value={[]}
          selected={selectedOption.length === 0}
          disabled={workflowKey === 'requestParametersCombinations'}
        >
          { selectedOption.length === 0 ? 'Please select an option' : 'Remove selection'}
        </option>
        {values.map((option, index) => (
          <option
            key={`${option}select`}
            value={option}
            selected={isCurrentOptionSelected(option, selectedOption)}
          >
            {text[index]}
          </option>
        ))}
      </select>
    </div>
  );
}

SingleSelectDropDown.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default SingleSelectDropDown;
