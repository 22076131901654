/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import './DocumentsSelect.scss';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedCountries, updateSelectedDocuments } from '../../../../reducers/workflow';
import DocumentsPopup from './DocumentsPopup';
import SelectedDocumentsTable from './SelectedDocumentsTable';
import updatedWorkflow from './utils/updateWorkflow';
import selectedDocuments from './utils/updateSelectedDocuments';
import updateSelectedOptions from './utils/selectedOptions';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import findIndexOfDocumentCountry from './utils/findIndexOfDocumentCountry';
import handleConfigurationChange from './utils/handleConfigurationChange';

function DocumentSelect({ options }) {
  const dispatch = useDispatch();
  const documentList = useSelector((state) => state.workflow.globalConfig);
  const supportedDocuments = useSelector((state) => state.workflow.selectedDocuments);
  const selectedNodeId = useSelector((state) => state.workflow.selectedNode)?.id;
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);
  const selectedWorkflowModules = selectedWorkflow.modules;
  const selectedCountries = useSelector((state) => state.workflow.selectedCountries);
  const documentOverrideOptions = useSelector((state) => state.workflow.documentOverrideOptions);
  const selectedModule = selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  );

  // State to track whether the popup is open or closed
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([
    {
      country: '',
      countryName: '',
      documents: [],
      documentsName: [],
      configurations: {},
    }]);

  // Function to toggle the popup state
  const togglePopup = () => {
    setSelectedOptions(supportedDocuments);
    setIsOpen(!isOpen);
  };

  const updateWorkflow = (validSelectedDocuments) => {
    const editedWorkflow = updatedWorkflow(selectedWorkflow, validSelectedDocuments, selectedNodeId);
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    const intialDocumentsSupported = selectedDocuments(selectedModule, documentList);
    dispatch(updateSelectedDocuments({ documents: intialDocumentsSupported }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedModule)]);

  const checkcountryWithoutSelectedDocuments = () => {
    const selectedCountriesId = selectedCountries.map((country) => country.id);
    const selectedCountriesWithDoc = selectedOptions.map((option) => option.country);
    let ifCountryWithoutDocs = false;
    selectedCountriesId.forEach((country) => {
      if (!selectedCountriesWithDoc.includes(country)) ifCountryWithoutDocs = true;
    });
    return ifCountryWithoutDocs;
  };

  useEffect(() => {
    if (selectedCountries.length === 0) {
      const countryModule = selectedWorkflow.modules.filter((module) => module.type === 'countries')[0];
      const supportedCountryIds = countryModule.properties.countriesSupported;

      const newcountriesSelected = documentList.filter(
        (obj) => supportedCountryIds.includes(obj.id),
      )
        .map((obj) => ({ id: obj.id, name: obj.name }));

      dispatch(updateSelectedCountries({ countries: newcountriesSelected }));
    }
  }, []);

  const handleConfirmClick = () => {
    const validSelectedDocuments = selectedOptions.filter((documents) => documents.country !== '');

    const countryWithoutSelectedDocuments = validSelectedDocuments.filter(
      (document) => document.documents.length === 0,
    )[0];

    if ((countryWithoutSelectedDocuments
       && Object.keys(countryWithoutSelectedDocuments).length > 0)
       || checkcountryWithoutSelectedDocuments()) {
      alert('select atleast one document per country');
    } else {
      togglePopup();
      dispatch(updateSelectedDocuments({ documents: validSelectedDocuments }));
      updateWorkflow(validSelectedDocuments);
    }
  };

  useEffect(() => {
    setSelectedOptions(supportedDocuments);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(supportedDocuments)]);

  const handleOptionChange = (option, selectedOptionsParam, documentListParam) => {
    const newSelectedOptions = updateSelectedOptions(selectedOptionsParam, option, documentListParam);
    if (newSelectedOptions.length === 0) {
      setSelectedOptions([{
        country: '',
        countryName: '',
        documents: [],
        documentsName: [],
      }]);
    } else { setSelectedOptions(newSelectedOptions); }
  };

  const shouldCheckCountry = (option, selectedOptionsParam) => selectedOptionsParam.some((countryDocs) => (countryDocs.country === option.country && countryDocs.documents.length > 0));

  const shouldSelectCheckBox = (option, selectedOptionsParam) => findIndexOfDocumentCountry(selectedOptionsParam, option.country, option.document) !== -1;

  const shouldSelectConfigCheckBox = (option, selectedOptionsParam) => {
    const index = findIndexOfDocumentCountry(selectedOptionsParam, option.country, option.document);
    if (index === -1) return false;
    if (option.config.action === 'capture') {
      return selectedOptionsParam[index].configurations?.[option.document]?.sides?.includes(option.config.side);
    }
    return selectedOptionsParam[index].configurations?.[option.document]?.sidesConfig?.[option.config.action]?.includes(option.config.side);
  };

  const shouldDisableOption = (option, selectedOptionsParam) => {
    const index = findIndexOfDocumentCountry(selectedOptionsParam, option.country, option.document);
    if (index === -1) return true;
    if (option.config.action === 'capture') {
      return false;
    }
    return !(selectedOptionsParam[index].configurations?.[option.document]?.sides?.includes(option.config.side));
  };

  const handleCountrySelection = (isChecked, country, selectedOptionsParam, documentListParam) => {
    const documentsName = [];
    const documents = [];
    const configurations = {};
    const filteredCountryDocuments = documentListParam.filter(
      (list) => list.id === country.country,
    )[0]?.documents;

    if (isChecked) {
      // select all documents
      filteredCountryDocuments.forEach((doc) => {
        documentsName.push(doc.name);
        documents.push(doc.id);
        configurations[doc.id] = {
          sides: doc.sides,
        };
      });
    }
    const newSelectedOptions = [...selectedOptionsParam];
    const filteredCountryIndex = newSelectedOptions.findIndex(
      (option) => option.country === country.country,
    );

    if (filteredCountryIndex !== -1) {
      newSelectedOptions[filteredCountryIndex] = {
        ...country,
        documents,
        documentsName,
        configurations,
      };
    } else {
      newSelectedOptions.push({
        ...country,
        documents,
        documentsName,
        configurations,
      });
    }
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <div>
      <SelectedDocumentsTable
        options={options}
        supportedDocuments={supportedDocuments}
        togglePopup={togglePopup}
      />
      {isOpen && (
      <DocumentsPopup
        options={options}
        documentList={documentList}
        selectedCountries={selectedCountries}
        handleOptionChange={(option) => handleOptionChange(option, selectedOptions, documentList)}
        shouldSelectCheckBox={(option) => shouldSelectCheckBox(option, selectedOptions)}
        togglePopup={togglePopup}
        handleConfirmClick={handleConfirmClick}
        handleCountrySelection={(isChecked, country) => handleCountrySelection(isChecked, country, selectedOptions, documentList)}
        documentOverrideOptions={documentOverrideOptions}
        shouldSelectConfigCheckBox={(option) => shouldSelectConfigCheckBox(option, selectedOptions)}
        shouldDisableOption={(option) => shouldDisableOption(option, selectedOptions)}
        handleConfigurationChange={(option) => handleConfigurationChange(option, selectedOptions, documentList, setSelectedOptions)}
        shouldCheckCountry={(option) => shouldCheckCountry(option, selectedOptions)}
      />
      )}
    </div>
  );
}

DocumentSelect.propTypes = {
  options: PropTypes.instanceOf(object).isRequired,
};

export default DocumentSelect;
