/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CommonTablePaginate as CommonTable } from 'clm-components';
import { PermissionFunctionWrapper } from 'storybook-ui-components';
import { useNavigate } from 'react-router-dom';
import './WorkflowList.scss';
import { genTableData, tableHeaders } from './workflowTableUtils';
import useGetUserPermissions from '../../Permissions/hooks';
import getPermission from '../../Permissions/mapping';

function WorkflowTable({ workflows, isLoading }) {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const userPermissions = useGetUserPermissions();

  const clickWorkflow = (row) => {
    navigate(`/view?id=${row.name.data.id}`);
  };

  useEffect(() => {
    const tabData = genTableData(workflows);
    setTableData(tabData);
  }, [workflows]);

  return (
    <CommonTable
      tableHeader={tableHeaders}
      tableData={tableData}
      onRowClick={PermissionFunctionWrapper(clickWorkflow, getPermission('workflowDetailsPage'), userPermissions)}
      isLoading={isLoading}
      id="workflow_table"
      emptyStateMessage="No workflows yet"
    />
  );
}

WorkflowTable.propTypes = {
  workflows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default WorkflowTable;
