import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Toggle from './Toggle';

import ShowValidationDrawer from './ShowValidationDrawer';

function SetComponentValidations(props) {
  const {
    validationConfig, onChange, defaultValue, hideRegexTab = false,
  } = props;

  const [toggle, setToggle] = useState(!!(defaultValue && defaultValue.length));
  const prevValue = useRef(defaultValue || '');
  const [validations, setValidations] = useState(defaultValue);

  useEffect(() => {
    if (!toggle) setValidations([]);
  }, [toggle]);

  const handleValidationChange = (value, reference) => {
    setValidations((currValidations) => {
      const updatedValidations = [...currValidations];
      updatedValidations[reference] = value;
      return updatedValidations;
    });
  };

  const addValidation = () => {
    setValidations((currValidations) => [
      ...currValidations, { type: 'rule', value: '', errorMsg: '' },
    ]);
  };

  const handleToggle = (newToggleState) => {
    if (newToggleState) addValidation();
    setToggle(newToggleState);
  };

  useEffect(() => {
    if (JSON.stringify(validations) !== JSON.stringify(prevValue.current)) onChange(validations);
    prevValue.current = validations;
  }, [validations]);

  useEffect(() => {
    setValidations(defaultValue);
  }, [validationConfig]);

  return (
    <div className="edit-settings-div__validation-input">
      <Toggle
        label={validationConfig.name}
        value={toggle}
        onChange={handleToggle}
      />
      {(toggle && defaultValue) ? (
        defaultValue.map((val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${val}_${index}`}>
            <ShowValidationDrawer
              defaultValue={val}
              hideRegexTab={hideRegexTab}
              onChange={(value) => { handleValidationChange(value, index); }}
            />
          </div>
        ))
      ) : null}
      {toggle && (
        <div>
          <button
            className="edit-settings-div__condition_button"
            type="button"
            onClick={addValidation}
          >
            + add validation
          </button>
        </div>
      )}
    </div>
  );
}

SetComponentValidations.propTypes = {
  validationConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.array.isRequired,
  hideRegexTab: PropTypes.bool,
};

SetComponentValidations.defaultProps = {
  hideRegexTab: false,
};

export default SetComponentValidations;
