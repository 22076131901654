const AlertCode = {
  error: {
    login: 'Please Login Again',
    fetchUser: 'Error in fetching user',
    fetchWorkflows: 'Error in fetching workflows',
    default: 'Something went wrong',
    fetchModules: 'Error in fetching modules',
    fetchCountries: 'Error in fetching countries',
    docToken: 'Error in fetching doc token',
    fetchWorkflow: 'Error fetching workflow',
    fetchTextConfig: 'Error fetching text config',
    fetchUiConfig: 'Error fetching ui config',
    fetchDefaultConfigs: 'Error fetching default configs',
    updateUiConfigs: 'Error updating ui config',
    countryModuleNotCorrect: 'Error in uploading workflow: Error in country module',
    noEndStateReachable: 'Error in uploading workflow: Workflow doesnot have reachable end states',
    noTerminalsInDynamicForm: 'Error in uploading workflow: Next step of Dynamic form cannot be terminating state',
    errorUploadingWorkflow: 'Failed to upload workflow',
    errorUploadingTextConfig: 'Failed to upload textconfig',
    errorUploadingUiConfig: 'Failed to upload UIconfig',
    errorCreatingWorkflow: 'Failed to create new workflow',
    errorPublishingWorkflow: 'Failed to publish workflow',
  },
  copiedToClipBoard: 'Copied To Clipboard',

};
export default AlertCode;
