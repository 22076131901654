import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import {
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectSelectedModuleState,
  selectSelectedModuleSubType,
  selectSelectedScreenState,
  updateCustomTextConfig,
  updateCustomUiConfig,
  updateFontStyleSheets,
  selectDefaultTextConfig,
  selectCustomTextConfig,
  selectSupportedFonts,
  selectSupportedFontWeights,
  selectSelectedLanguage,
} from '../../reducers/editBranding';
import { selectSelectedWorkflow } from '../../reducers/workflow';
import DebouncedTextEditor from '../../components/Branding/DebouncedTextEditor';
import ColorPicker from '../../components/Branding/ColorPicker';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import FontPicker from '../../components/Branding/FontPicker';
import SingleSelectDropdown from '../../components/Common/SingleSelectDropdown';

function EditIndividualScreens(props) {
  const { selectedElement, onElementUpdate } = props;

  const dispatch = useDispatch();
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const defaultTextConfigs = useSelector(selectDefaultTextConfig);
  const customTextConfig = useSelector(selectCustomTextConfig);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const supportedFonts = useSelector(selectSupportedFonts);
  const supportedFontWeights = useSelector(selectSupportedFontWeights);
  const selectedModuleSubType = useSelector(selectSelectedModuleSubType);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const selectedModule = useSelector(selectSelectedModuleState);
  const selectedScreen = useSelector(selectSelectedScreenState);

  const defaultTextConfig = useMemo(() => defaultTextConfigs[`default_${selectedLanguage}_text_config`], [selectedLanguage]);

  const handleTextConfigChange = (textConfig) => {
    const editedWorkflow = cloneDeep(selectedWorkflow);
    editedWorkflow.properties.textConfigSource = {
      ...(editedWorkflow.properties.textConfigSource),
      [selectedLanguage]: 'custom',
    };
    updateWorkflowInState(editedWorkflow);
    dispatch(updateCustomTextConfig({ textConfig, language: selectedLanguage }));
  };

  const handleUiConfigChange = (uiConfig) => {
    const editedWorkflow = cloneDeep(selectedWorkflow);
    editedWorkflow.properties.uiConfigSource = 'custom';
    updateWorkflowInState(editedWorkflow);
    dispatch(updateCustomUiConfig({ uiConfig }));
  };

  const getUiConfig = () => (
    customUiConfig && Object.keys(customUiConfig).length > 0 ?
      customUiConfig :
      defaultUiConfig
  );

  const getTextConfig = () => (
    customTextConfig && Object.keys(customTextConfig).length > 0 ?
      customTextConfig :
      defaultTextConfig
  );

  const handleFontChange = (selectedFont) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', selectedFont);
    const id = selectedElement.font;
    const editedUiConfig = cloneDeep(getUiConfig());
    editedUiConfig.font[id] = selectedFont;
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [selectedFont]: url } }));
    handleUiConfigChange(editedUiConfig);
  };

  const handleFontWeightChange = (selectedFontWeight) => {
    const id = selectedElement.fontWeight;
    const editedUiConfig = cloneDeep(getUiConfig());
    editedUiConfig.fontWeight[id] = selectedFontWeight;
    handleUiConfigChange(editedUiConfig);
  };

  const handleTextChange = (updatedText) => {
    const editedTextConfig = cloneDeep(getTextConfig());
    const id = selectedElement.textConfig;
    const defaultConfigForSelectedSubModule =
      defaultTextConfig[selectedModuleSubType];

    if (defaultConfigForSelectedSubModule
        && defaultConfigForSelectedSubModule[id] !== updatedText) {
      editedTextConfig[selectedModuleSubType][id] = updatedText.trim();
      handleTextConfigChange(editedTextConfig);
    }
  };

  const updateColor = (colorValue, color) => {
    const element = color.key;
    const editedUiConfig = cloneDeep(getUiConfig());

    editedUiConfig.colors[element] = colorValue;
    handleUiConfigChange(editedUiConfig);
  };

  const getDefaultColor = (colorElement) => {
    if (colorElement && Object.keys(colorElement).length > 0) {
      const uiConfig = customUiConfig && Object.keys(customUiConfig).length > 0
        ? customUiConfig : defaultUiConfig;
      return uiConfig.colors[colorElement] || '#000000';
    }
    return '#000000';
  };

  const onSelectError = (error, selectedElementParam) => {
    const newBrandingElement = cloneDeep(selectedElementParam);
    newBrandingElement.textConfig = error;
    onElementUpdate(newBrandingElement);
  };

  const uiConfig = getUiConfig();
  const textConfig = getTextConfig();
  const {
    font: elementFont,
    fontWeight: elementFontWeight,
    type: elementType,
    textConfig: elementTextConfig,
    textConfigList: elementTextConfigList = [],
    colors: elementColors = [],
  } = selectedElement;

  const fontFamily = uiConfig.font?.[elementFont] || 'Inter';
  const fontWeight = uiConfig.fontWeight?.[elementFontWeight] || 'Normal';
  const text =
    textConfig?.[selectedModuleSubType]?.[elementTextConfig] ||
    defaultTextConfig[selectedModuleSubType]?.[elementTextConfig] || '';

  const errorList = useMemo(() => {
    if (!elementTextConfigList || !elementTextConfigList.length) return [];
    return elementTextConfigList
      ?.map((errorCode) => ({
        id: errorCode,
        value: errorCode,
        name: textConfig?.[selectedModuleSubType]?.[errorCode],
      }));
  }, [elementTextConfigList, selectedModuleSubType, textConfig]);

  if (elementType !== 'text' && elementType !== 'button' && elementType !== 'errorDropdown') return null;

  return (
    <div className="branding-properties-div">
      <div key={`${selectedModule}_${selectedScreen}_${elementTextConfig}`} className="branding-element">
        <div className="main-div">
          <div className="input-field">
            {
              (elementType === 'errorDropdown') && (
                <SingleSelectDropdown
                  items={errorList}
                  onElementSelect={(error) => onSelectError(error, selectedElement)}
                  defaultValue={elementTextConfig}
                />
              )
            }
            <div className="input-field__options">
              {(elementType === 'text' || elementFont) && (
                <FontPicker
                  supportedFontValues={supportedFonts}
                  fontValue={fontFamily}
                  onFontValueChange={handleFontChange}
                  pickerLabel="Font Style:"
                />
              )}
              {(elementType === 'text' || elementFontWeight) && (
                <FontPicker
                  supportedFontValues={supportedFontWeights}
                  fontValue={fontWeight}
                  onFontValueChange={handleFontWeightChange}
                  pickerLabel="Font Weight:"
                />
              )}
            </div>
            {(elementType === 'text' || elementTextConfig) && (
              <DebouncedTextEditor
                text={text}
                onTextChange={handleTextChange}
              />
            )}
            {elementType === 'button' && elementColors.map((color) => (
              <ColorPicker
                key={color.name}
                name={color.name}
                color={getDefaultColor(color.key)}
                onChange={(_, colorValue) => updateColor(colorValue, color)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIndividualScreens;

EditIndividualScreens.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  onElementUpdate: PropTypes.func.isRequired,
};
