import { findPreviousModulesWithNames } from './findPreviousModules';
import getOutputsOfModule from './getModuleOutputs';

export const defaultInputs = ['appId', 'appKey', 'accessToken', 'transactionId'];

export const getConditionalVariables = (workflow) => Object.keys(
  workflow?.conditionalVariables || {},
)
  .map((conVar) => {
    const name = workflow.conditionalVariables[conVar].name || conVar;
    return {
      id: conVar,
      name,
    };
  });

export const getWorkflowInputVariables = (workflow) => {
  const inputs = Object.keys(
    workflow?.properties?.inputsRequired || {},
  )
    .map((inputName) => {
      const name = inputName;
      return {
        id: name,
        name,
      };
    });

  defaultInputs.forEach((input) => {
    inputs.push({ id: input, name: input });
  });
  return inputs;
};

export const getModuleOutputs = (
  orderOfNodes,
  selectedNodeId,
  workflow,
  formComponentList,
  moduleConfigs,
) => {
  const indexOfCurrentModule = orderOfNodes.findIndex(
    (module) => module.id === selectedNodeId,
  );
  const previousModules = findPreviousModulesWithNames(
    orderOfNodes,
    indexOfCurrentModule,
    workflow,
  );
  const moduleOutputs = [];
  previousModules.forEach((module) => {
    const { id, name } = module;
    const outputVariables = getOutputsOfModule(
      id,
      formComponentList,
      workflow?.modules || [],
      moduleConfigs,
    );
    moduleOutputs.push({
      id,
      name,
      variables: outputVariables,
    });
  });
  return moduleOutputs;
};
