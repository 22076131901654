/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import PropTypes, { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectSelectedWorkflow } from '../../../../reducers/workflow';
import findConditionalVariablesToDisplay from './utils/findConditionalVariablesToDisplay';
import CondtionalVariablesDrawerOld from './ConditionalVariablesDrawer';
import generateUniqueID from '../../../../utils/generateUniqueId';

function ConditionalVariablePicker({ setValue, style }) {
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const [uniqueId, setUniqueId] = useState('');
  const [showConditionalVariables, setShowConditionalVariables] = useState(false);
  const [conditionalVarToDisplay, setConditionalVarsToDisplay] = useState({});

  useEffect(() => {
    const listToDisplay = findConditionalVariablesToDisplay(selectedWorkflow.conditionalVariables);

    setConditionalVarsToDisplay(listToDisplay);
  }, [selectedWorkflow]);

  useEffect(() => {
    const id = generateUniqueID();
    setUniqueId(id);
  }, [showConditionalVariables]);

  const selectConditionalVars = (condVar) => {
    setValue(condVar);
  };

  const handleCreateVar = () => {
    setShowConditionalVariables(true);
  };

  return (
    <div className="conditional-variable__picker" style={style}>
      <div className="conditional-variable__picker__content">
        {
        conditionalVarToDisplay && (
          Object.keys(conditionalVarToDisplay).map((condVar) => (
            <div
              key={condVar}
              className="conditional-variable__picker__value"
              onClick={() => selectConditionalVars(condVar)}
            >
              {conditionalVarToDisplay[condVar].name || condVar}
            </div>
          ))
        )
        }
      </div>
      <div className="conditional-variable__picker__add-div">
        <button
          type="button"
          className="conditionalVar__add"
          onClick={handleCreateVar}
        >
          + Add Conditional Variable
        </button>
      </div>
      {
        showConditionalVariables
        && (
        <CondtionalVariablesDrawerOld
          setShowDrawer={setShowConditionalVariables}
          uniqueid={uniqueId}
        />
        )
      }
    </div>
  );
}

export default ConditionalVariablePicker;

ConditionalVariablePicker.propTypes = {
  setValue: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(object).isRequired,
};
