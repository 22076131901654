/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes, { any } from 'prop-types';
import './NewConditionDrawer.scss';

import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-unused-vars
function ShowInputOptions({
  side, handleModuleSelect, selectedRuleKey,
  showOptionStyle, isConditionalVariable,
  showOption, setShowOption, handleInputSelect, closeVariableDrawer, handleCondVarSelect,
  handleWorkflowInputSelect,
}) {
  const divRef = useRef('null');
  const [closeOptionsModal, setCloseOptionsModal] = useState(!showOption);

  const handleCloseOptionsModal = () => {
    setCloseOptionsModal(true);
    closeVariableDrawer();
  };

  useEffect(() => {
    setShowOption(!closeOptionsModal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOptionsModal]);

  const handleCustomInputSelection = () => {
    handleInputSelect(selectedRuleKey, side);
  };

  const handleClickModuleSelect = (e) => {
    handleModuleSelect(e, selectedRuleKey, side, divRef);
    setCloseOptionsModal(false);
  };

  const handleClickConditionalVarSelect = (e) => {
    handleCondVarSelect(e, selectedRuleKey, side, divRef);
  };

  const handleClickWorklfowInputSelect = (e) => {
    handleWorkflowInputSelect(e, selectedRuleKey, side, divRef);
  };

  return (
    <div>
      {!closeOptionsModal && (
        <div>
          <div
            className="choose-input-options-modal"
            style={showOptionStyle}
            ref={divRef}
          >
            <div>
              <div
                className="choose-input-options-modal__options"
                onClick={handleCustomInputSelection}
              >
                Set Custom Input
              </div>
              <div
                className="choose-input-options-modal__options"
                onClick={(e) => {
                  handleClickModuleSelect(e);
                }}
              >
                Select Module Output
              </div>
              <div
                className="choose-input-options-modal__options"
                onClick={(e) => {
                  handleClickWorklfowInputSelect(e);
                }}
              >
                Select Workflow Input
              </div>
              {!isConditionalVariable && (
              <div
                className={`choose-input-options-modal__options
                }`}
                onClick={(e) => {
                  handleClickConditionalVarSelect(e);
                }}
              >
                Select Conditional Variable
              </div>
              )}
            </div>

          </div>
          <div className="choose-input-options-modal__background" onClick={handleCloseOptionsModal} />
        </div>
      )}
    </div>
  );
}

export default ShowInputOptions;

ShowInputOptions.propTypes = {
  side: PropTypes.string.isRequired,
  handleModuleSelect: PropTypes.func.isRequired,
  selectedRuleKey: PropTypes.string.isRequired,
  showOptionStyle: PropTypes.instanceOf(any).isRequired,
  showOption: PropTypes.bool.isRequired,
  setShowOption: PropTypes.func.isRequired,
  handleInputSelect: PropTypes.func.isRequired,
  closeVariableDrawer: PropTypes.func.isRequired,
  handleCondVarSelect: PropTypes.func.isRequired,
  handleWorkflowInputSelect: PropTypes.func.isRequired,
  isConditionalVariable: PropTypes.bool.isRequired,
};
