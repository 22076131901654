export const getLabel = (value, options) => {
  const {
    label, value: itemValue, items: subItems, displayLabel: mainLabel,
  } = options;
  if (value === itemValue) return mainLabel || label;
  let displayLabel = null;
  if (subItems?.length) {
    (options.items || []).forEach((item) => {
      const returnedLabel = getLabel(value, item);
      if (displayLabel === null) displayLabel = returnedLabel;
    });
  }
  return displayLabel;
};

export const getMenuItem = ({
  options, cb, isStartNode = false, mainLabel = '', addCustomInput = false,
}) => {
  const {
    label: itemLabel, items: subOptions, value = null, ...rest
  } = options;
  const label = isStartNode ? mainLabel : itemLabel;
  if (subOptions?.length) {
    const subItems = [];
    (options.items || []).forEach((item) => {
      subItems.push(getMenuItem({ options: item, cb }));
    });
    if (isStartNode && addCustomInput) {
      subItems.push(
        getMenuItem({
          options: {
            label: 'Custom Input',
            value: 'builder_customInput',
          },
          cb,
        }),
      );
    }
    return {
      label,
      items: subItems,
      ...rest,
    };
  }
  return {
    label,
    ...rest,
    callback: () => { cb(value); },
  };
};
