/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Toggle.scss';
import { useSelector } from 'react-redux';
import {
  selectSelectedNode, selectSelectedWorkflow, selectModules,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { fetchCurrentValueFromWorkflow, getSelectedModule, setModulePropertyInWorkflow } from '../InputsToModule/utils/updateWorkflow';

function Toggle({ description }) {
  const {
    workflowKey, off: offValue, on: onValue, text, default: defaultValue,
  } = description;

  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];

  const getDefaultToogleValue = (workflow, moduleId, key, moduleConfig) => {
    const module = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(
      module,
      key,
      moduleConfig,
    );

    const valueFetchedFromWorkflow = currentValue === onValue || currentValue === offValue;
    const finalValue = valueFetchedFromWorkflow ?
      (currentValue === onValue) : (defaultValue === onValue);

    return {
      value: finalValue,
      valueFetchedFromWorkflow,
    };
  };

  const getValueForToggle = (state) => (state === true ? onValue : offValue);

  const [toggle, setToggle] = useState(false);

  const updateValueInWorkflow = (toggleState) => {
    const valueToUpdate = getValueForToggle(toggleState);
    const editedWorkflow = setModulePropertyInWorkflow(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      valueToUpdate,
      selectedModuleConfig,
    );
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    const { value: currentToggleValue, valueFetchedFromWorkflow } = getDefaultToogleValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
    );
    setToggle(currentToggleValue);
    if (!valueFetchedFromWorkflow) updateValueInWorkflow(currentToggleValue);
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  const handleToggle = () => {
    updateValueInWorkflow(!toggle);
  };

  return (
    <div className="description-toggle">
      <div className="description-toggle__label">
        <p>{text}</p>
      </div>
      <div className="toggle">
        <label>
          <input type="checkbox" checked={toggle} onChange={handleToggle} />
          <span className="slider round" />
        </label>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  description: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Toggle;
