/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import ConditionalVariableTitleRibbon from './ConditionalVariableTitleRibbon';

import './ConditionalVariable.scss';
import '../NewConditionDrawer/NewConditionDrawer.scss';
import ConditionalVariableName from './ConditionalVariableName';
import IndividualConditionalVariable from './IndividualConditionalVariable';
import generateUniqueID from '../../../../utils/generateUniqueId';

import { selectSelectedWorkflow, updateSelectedWorkflow } from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { logCreateConditionalVariable } from '../../../../logger/logHighLevelWorkflowUpdates';

function ConditionalVariableDrawerOld({ uniqueId, setShowDrawer, from }) {
  const dispatch = useDispatch();
  const [showConditionalVarEditor, setShowConditionalVarEditor] = useState(false);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const [newConditionalVariable, setNewConditionalVariable] = useState('');
  const [conditionalVariableChildren, setConditionalVariableChildren] = useState([]);
  const [newConditionalVariableId, setNewConditionalVariableId] = useState('');

  const updateVariableName = (name) => {
    setNewConditionalVariable({ ...newConditionalVariable, name });
  };

  const updateParentCondition = (rule) => {
    const localCondVar = cloneDeep(newConditionalVariable);
    setNewConditionalVariable({ ...localCondVar, rule });
  };

  const updateParentConditionResult = (value, type) => {
    const localCondVar = cloneDeep(newConditionalVariable);
    localCondVar[type] = value;
    setNewConditionalVariable(localCondVar);
  };

  const updateChildrenConditionalVar = (childern) => {
    setConditionalVariableChildren(childern);
  };

  const handleCreate = () => {
    let localConditionalVar = {};

    const editedWorkflow = cloneDeep(selectedWorkflow);
    if (editedWorkflow.conditionalVariables) {
      localConditionalVar = {
        ...editedWorkflow.conditionalVariables,
        [`${newConditionalVariableId}`]: { ...newConditionalVariable },
      };
    }
    // generate children ids
    conditionalVariableChildren.forEach((conVar, index) => {
      const id = generateUniqueID();
      localConditionalVar[`conVar_${id}`] = conVar;

      if (index === conditionalVariableChildren.length - 1) {
        const updatedConVar = conVar;
        updatedConVar.if_false = newConditionalVariable.if_false;
        localConditionalVar[`conVar_${id}`] = updatedConVar;
      }
      if (index === 0) {
        localConditionalVar[newConditionalVariableId].if_false = `conditionalVariables.conVar_${id}`;
      }
    });

    // update the false value of the parent and the last child

    editedWorkflow.conditionalVariables = { ...localConditionalVar };
    logCreateConditionalVariable({
      id: newConditionalVariableId,
      conditionalVariable: newConditionalVariable,
    });
    updateWorkflowInState(editedWorkflow);

    setShowConditionalVarEditor(false);
    setShowDrawer(false);
  };

  const handleDelete = () => {
    const { conditionalVariables } = selectedWorkflow;
    const localCondVar = cloneDeep(conditionalVariables);
    const editedWorkflow = cloneDeep(selectedWorkflow);

    if (localCondVar) {
      delete localCondVar[newConditionalVariableId];
    }

    editedWorkflow.conditionalVariables = { ...localCondVar };

    updateWorkflowInState(editedWorkflow);

    setShowConditionalVarEditor(false);
    setShowDrawer(false);
  };

  const handleBackClick = () => {
    setShowConditionalVarEditor(false);
    setShowDrawer(false);
  };

  useEffect(() => {
    const id = generateUniqueID();
    setNewConditionalVariableId(`condVar_${id}`);

    setNewConditionalVariable({
      name: '',
      parent: `condVar_${id}`,
      rule: '',
      if_true: '',
      if_false: '',
    });
    setShowConditionalVarEditor(true);
  }, [uniqueId]);

  return (
    <>
      {showConditionalVarEditor && (
      <div className={from === 'properties' ? 'conditionalVar__properties_tab' : 'conditionalVar'}>
        <div className={from === 'properties' ? 'conditionalVar__properties_tab__content' : 'conditionalVar__content'}>
          <ConditionalVariableTitleRibbon
            handleCreate={handleCreate}
            handleDelete={handleDelete}
            handleBackClick={handleBackClick}
          />
          <ConditionalVariableName updateConditionalVariableName={updateVariableName} />
          <div className="conditionalVar__conditions_title">
            Conditions
          </div>
          <IndividualConditionalVariable
            updateVariableName={updateVariableName}
            parentConditionalVar={newConditionalVariable}
            parentId={newConditionalVariableId}
            updateChildrenConditionalVar={updateChildrenConditionalVar}
            updateParentConditionResult={updateParentConditionResult}
            updateParentCondition={updateParentCondition}
          />
        </div>
      </div>
      )}
    </>
  );
}

export default ConditionalVariableDrawerOld;

ConditionalVariableDrawerOld.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  from: PropTypes.string,
};
