/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import deleteIcon from '../assests/icons/deleteIcon.svg';
import genericModuleIcon from '../assests/icons/genericModuleIcon.svg';
import { selectSelectedNode, selectSelectedWorkflow } from '../reducers/workflow';

function ModuleNode({ data, type }) {
  const {
    name, nodeType, showDeleteNodeModal, id, parentId, icon,
  } = data;
  const workflow = useSelector(selectSelectedWorkflow);
  const selectedNode = useSelector(selectSelectedNode);
  const deleteNode = (event) => {
    event.stopPropagation();
    showDeleteNodeModal(id, parentId, workflow);
  };
  const getIcon = () => {
    if (nodeType === 'countries') {
      return (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      );
    }
    if (nodeType === 'form') {
      return (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      );
    }
    if (['id_card_verification', 'idcard_validation', 'id_card_validation'].includes(nodeType)) {
      return (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      );
    }
    if (nodeType === 'selfie_verification' || nodeType === 'selfie_validation') {
      return (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      );
    }
    if (['selfie_id_match_api', 'selfie_id_match_base64_api', 'selfie_selfie_match_api', 'faceMatch'].includes(nodeType)) {
      return (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      );
    }
    return (
      icon ? (
        <img
          src={icon}
          alt={nodeType}
          className="custom_edge_button__img"
        />
      )
        : (
          <img
            src={genericModuleIcon}
            alt={nodeType}
            className="custom_edge_button__img"
          />
        )
    );
  };
  return (
    <div className={`custom_node ${selectedNode.id === id && 'custom_node_selected'}`}>
      {
          nodeType !== 'countries'
            ? (
              <button type="button" className="custom_node__sideButton" onClick={deleteNode}>
                <img
                  src={deleteIcon}
                  alt="delete"
                />
              </button>
            )
            : null
        }
      {
        (() => {
          if (type === 'input') { return <Handle type="source" position={Position.Bottom} id="moduleBottom" isConnectable />; }
          if (type === 'output') { return <Handle type="target" position={Position.Top} isConnectable />; }
          return (
            <>
              <Handle type="source" position={Position.Bottom} id="moduleBottom" isConnectable />
              <Handle type="target" position={Position.Top} isConnectable />
            </>
          );
        })()
        }

      <div className="custom_node__icon">
        {getIcon()}
      </div>
      <div className="custom_node__body">
        <p className="custom_node__heading">
          {name}
        </p>
      </div>
    </div>
  );
}

ModuleNode.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ModuleNode;
