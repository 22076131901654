/* eslint-disable no-restricted-syntax */
// const areParenthesesBalanced = (str) => {
//   try {
//     const stack = [];

//     for (let i = 0; i < str.length; i += 1) {
//       const char = str[i];
//       if (char === '(') {
//         stack.push(char);
//       } else if (char === ')') {
//         if (stack.length === 0) {
//           return false;
//         }
//         stack.pop();
//       }
//     }

//     return stack.length === 0;
//   } catch (err) {
//     console.log(err);
//     return false;
//   }
// };

const areParenthesesBalanced = (expression) => {
  const isOperator = (token) => ['&&', '||', '!'].includes(token);
  const isValidVariable = (token) => /^[A-Za-z_][A-Za-z0-9_]*$/.test(token);
  const exp = `(${expression})`;
  const tokens = exp
    .split(/(\(|\)|&&|\|\||!)/)
    .map((token) => token.trim())
    .filter((token) => token !== '');

  let noOfOperands = 0;
  const stack = [];
  let prevToken = null;

  for (const token of tokens) {
    if (token === '(') {
      if (prevToken === ')') return false;
      stack.push(token);
    } else if (token === ')') {
      if (prevToken === '(') return false;
      if (stack.length === 0) return false;

      while (stack.length > 0 && stack[stack.length - 1] !== '(') {
        const popped = stack.pop();
        if (popped === undefined) return false;

        if (isOperator(popped)) {
          if (popped !== '!') noOfOperands -= 1;
        } else {
          noOfOperands += 1;
        }
      }

      stack.pop(); // Remove '('
    } else if (isOperator(token)) {
      stack.push(token);
    } else {
      if (isValidVariable(prevToken)) return false;
      noOfOperands += 1;
    }

    if (noOfOperands < 0) return false;
    prevToken = token;
  }

  if (stack.length !== 0 || noOfOperands !== 1) return false;
  return true;
};

export default areParenthesesBalanced;
