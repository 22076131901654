/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes, { object } from 'prop-types';
import sideIcon from '../../../../assests/icons/sideIcon.svg';

function ModuleInputForRule({
  side, handleShowOptionClick, ruleKey,
  textRule, showOption, selectedRuleKey, selectedSide,
}) {
  return (
    <div
      onClick={(e) => handleShowOptionClick(e, side, ruleKey)}
      className="condition__content__var__dropDown "
    >
      <div className="condition__content__var__dropDown__module">
        {
          (!(ruleKey === selectedRuleKey && selectedSide === side && showOption) && (textRule[side] !== 'NONE_NONE'))
            ? textRule[side].split('.')[0] : 'Choose module & its output variable'
          }
      </div>
      {!(ruleKey === selectedRuleKey && selectedSide === side && showOption) && (
        <div className="condition__content__var__dropDown__var-div">
          {(textRule[side].split('.').length <= 1) ? '' : (
            <img
              className="condition__content__var__dropDown__side-icon"
              src={sideIcon}
              alt="side icon"
            />
          )}
          <div className="condition__content__var__dropDown__module-var">
            {
            (textRule[side].split('.').length <= 1) || textRule[side] === 'NONE_NONE'
              ? ''
              : textRule[side].split('.')[1]
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default ModuleInputForRule;

ModuleInputForRule.propTypes = {
  side: PropTypes.string.isRequired,
  handleShowOptionClick: PropTypes.func.isRequired,
  ruleKey: PropTypes.instanceOf(object).isRequired,
  textRule: PropTypes.instanceOf(object).isRequired,
  showOption: PropTypes.bool.isRequired,
  selectedRuleKey: PropTypes.string.isRequired,
  selectedSide: PropTypes.string.isRequired,
};
