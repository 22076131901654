/* eslint-disable no-unused-vars */
import { useState } from 'react';
import kebabMenuIcon from '../../../assests/icons/kebabMenuIcon.svg';
import '../../../containers/Container.scss';
import DownloadWorkflow from './DownloadWorkflow';
import ConditionalVariables from './ConditionalVariablesButton';
import PublishToStaging from './PublishToStaging';

function MoreWorkflowOptions() {
  const [showOptions, setShowOptions] = useState(false);

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <>
      <div className="more-options">
        <button type="button" className="more-options__button" onClick={handleClick}>
          <img src={kebabMenuIcon} alt="Options" />
        </button>
      </div>
      {showOptions && (
        <>
          <div className="more-options__div">
            <DownloadWorkflow />
            <ConditionalVariables />
            <PublishToStaging />
          </div>
          <button type="button" className="more-options__background" onClick={handleClick}>
            {' '}
          </button>
        </>
      )}
    </>
  );
}

export default MoreWorkflowOptions;
