import {
  InputLabel, FormControl, MenuItem, Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import StringInput from './StringInput';

function TypedInput({
  value: currentValue,
  onChange,
  inputLabel,
  typeLabel,
}) {
  const getDataType = (value) => {
    const type = typeof value;
    if (['string', 'number', 'boolean'].includes(type)) return type;
    return 'string';
  };
  const getAllowedDataTypes = (value) => {
    const possibleDataTypes = ['string'];
    if (!Number.isNaN(parseFloat(value, 10))) possibleDataTypes.push('number');
    if (['true', 'false', true, false].includes(value)) possibleDataTypes.push('boolean');
    return possibleDataTypes;
  };

  const [dataType, setDataType] = useState(getDataType(currentValue));
  const [allowedDataTypes, setAllowedDataTypes] = useState(getAllowedDataTypes(currentValue));

  useEffect(() => {
    const options = getAllowedDataTypes(currentValue);
    setAllowedDataTypes(options);
  }, [currentValue]);

  const convertToType = (value, type) => {
    let typedData = value;
    if (type === 'number') {
      const floatVal = parseFloat(value, 10);
      typedData = Number.isNaN(floatVal) ? 0 : floatVal;
    } else if (type === 'boolean') typedData = value === 'true';
    else typedData = `${value}`;
    return typedData;
  };

  const updateDataType = (event) => {
    const { value: finalDataType } = event.target;
    setDataType(finalDataType);
    const typedData = convertToType(currentValue, finalDataType);
    onChange(typedData);
  };

  const handleTextChange = (updatedValue) => {
    const value = typeof updatedValue !== 'string' ? '' : updatedValue;
    const options = getAllowedDataTypes(value);
    const finalDataType = options.includes(dataType) ? dataType : 'string';
    const typedData = convertToType(value, finalDataType);
    onChange(typedData);
    setAllowedDataTypes(options);
    setDataType(finalDataType);
  };

  return (
    <>
      <StringInput
        value={`${currentValue}`}
        onChange={handleTextChange}
        label={inputLabel}
      />
      <div className="workflow_output_field_typeSelectionDiv">
        <FormControl className="workflow_output_field_typeSelection">
          <InputLabel id="demo-simple-select-label">{typeLabel}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dataType}
            label="Type"
            onChange={updateDataType}
            size="small"
          >
            <MenuItem value="string">String</MenuItem>
            { allowedDataTypes.includes('number') ? <MenuItem value="number">Number</MenuItem> : null }
            { allowedDataTypes.includes('boolean') ? <MenuItem value="boolean">Bool</MenuItem> : null }
          </Select>
        </FormControl>
      </div>
    </>
  );
}

TypedInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
};

export default TypedInput;
