/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import search from '../../../../assests/icons/search.svg';
import downIcon from '../../../../assests/icons/downIcon.svg';
import filterDocumentsForCountries from './utils/FilterDocumentsForCountries';

function DocumentsPopup({
  options,
  documentList,
  selectedCountries,
  handleOptionChange,
  shouldSelectCheckBox,
  togglePopup,
  handleConfirmClick,
  handleCountrySelection,
  documentOverrideOptions,
  shouldSelectConfigCheckBox,
  shouldDisableOption,
  handleConfigurationChange,
  shouldCheckCountry,
}) {
  const {
    popupCancelButtonText,
    popupTitle, popupSelectAllButtonText, popupDeselectButtonText,
    popupSearchText,
    popupConfirmButtonText,
  } = options;

  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(null);
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  const onDownButtonClick = (documentId) => {
    setShowDropdown((currentShowDropdown) => {
      if (currentShowDropdown === documentId) return null;
      return documentId;
    });
  };

  useEffect(() => {
    setFilteredOptions(filterDocumentsForCountries(documentList, query, selectedCountries));
  }, [documentList, query, selectedCountries]);

  return (
    <div>
      <div className="popup">
        <div className="popup__title">{popupTitle}</div>
        <div className="popup__searchdiv">
          <div className="popup__searchdiv__searchbardiv">
            <img alt="" src={search} />
            <input
              className="popup__searchdiv__searchbar"
              type="search"
              placeholder={popupSearchText}
              onChange={handleQueryChange}
            />
          </div>
        </div>
        <div className="popup__documents_select-checklist-div">
          {filteredOptions.map((country, index) => (
            <div key={index}>
              <div className="popup__documents_select-checklist-div__country">
                <input
                  className="popup__checklist"
                  type="checkbox"
                  id={country.name}
                  onChange={(e) => handleCountrySelection(e.target.checked, {
                    country: country.id,
                    countryName: country.name,
                  })}
                  checked={shouldCheckCountry({
                    country: country.id,
                    countryName: country.name,
                  })}
                />
                <div className="popup__countries-and-flag">
                  <label className="popup__documents_select-checklist-div__country__label" htmlFor={country.name}>
                    {country.name}
                  </label>
                  <img
                    className="popup__countries-flag"
                    src={`https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/flags/${country.id}.png`}
                    alt={country.id}
                  />

                </div>
              </div>
              {
              country.documents.map((document, idx) => (
                <div
                  key={`${document.id}_${idx}`}
                  className="popup__documents_select-checklist-div__documents_container"
                >
                  <div className="popup__documents_select-checklist-div__documents">
                    <button
                      type="button"
                      className="popup__documents_select-checklist-div__documents_button"
                      onClick={() => onDownButtonClick(document.id)}
                    >
                      <img src={downIcon} alt="open" />
                    </button>
                    <div className="popup__documents_select-checklist-div__documents_item">
                      <label
                        className="popup__documents_select-checklist-div__documents_label"
                        htmlFor={`${document.id}_${country.id}`}
                      >
                        {document.name}
                      </label>
                      <input
                        className="popup__checklist"
                        type="checkbox"
                        id={`${document.id}_${country.id}`}
                        onChange={() => handleOptionChange({
                          country: country.id,
                          countryName: country.name,
                          document: document.id,
                          documentName: document.name,
                        })}
                        checked={shouldSelectCheckBox({
                          country: country.id,
                          countryName: country.name,
                          document: document.id,
                          documentName: document.name,
                        })}
                      />
                    </div>
                  </div>
                  {
                  showDropdown === document.id
                    && (
                    <div className="popup__documents_select-checklist-div__documents_dropdown">
                      {
                        Object.entries(documentOverrideOptions).map(([side, overrideOptions]) => (
                          <div
                            className="popup__documents_select-checklist-div__documents_dropdown__side"
                            key={side}
                          >
                            <div className="popup__documents_select-checklist-div__documents_dropdown__side_heading">
                              {`${side} SIDE`}
                            </div>
                            {
                              overrideOptions.map(({ value, action }) => (

                                <div
                                  className="popup__documents_select-checklist-div__documents_dropdown__side_options"
                                  key={action}
                                >
                                  <label
                                    htmlFor={`${document.id}_${country.id}_${action}`}
                                    className="popup__documents_select-checklist-div__documents_dropdown__side_options_label"
                                  >
                                    <div className="popup__documents_select-checklist-div__documents_dropdown__side_options_label__name">

                                      {value}
                                    </div>
                                    <input
                                      className="popup__checklist"
                                      type="checkbox"
                                      id={`${document.id}_${country.id}_${action}`}
                                      checked={shouldSelectConfigCheckBox({
                                        country: country.id,
                                        countryName: country.name,
                                        document: document.id,
                                        documentName: document.name,
                                        config: {
                                          side,
                                          action,
                                        },
                                      })}
                                      disabled={shouldDisableOption({
                                        country: country.id,
                                        countryName: country.name,
                                        document: document.id,
                                        documentName: document.name,
                                        config: {
                                          side,
                                          action,
                                        },
                                      })}
                                      onChange={() => handleConfigurationChange({
                                        country: country.id,
                                        countryName: country.name,
                                        document: document.id,
                                        documentName: document.name,
                                        config: {
                                          side,
                                          action,
                                        },
                                      })}
                                    />
                                  </label>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                    )
                  }
                </div>
              ))
}
            </div>
          ))}
        </div>

        <div className="popup__footer">
          <button
            className="popup__cancel-button"
            type="button"
            onClick={togglePopup}
          >
            {popupCancelButtonText}
          </button>
          <button
            className="popup__confirm-button"
            type="button"
            onClick={handleConfirmClick}
          >
            {popupConfirmButtonText}

          </button>
        </div>

      </div>
      <div className="modal" onClick={togglePopup} />
    </div>
  );
}

export default DocumentsPopup;

DocumentsPopup.propTypes = {
  options: PropTypes.instanceOf(object).isRequired,
  documentList: PropTypes.instanceOf(object).isRequired,
  selectedCountries: PropTypes.array.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  shouldSelectCheckBox: PropTypes.func.isRequired,
  togglePopup: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCountrySelection: PropTypes.func.isRequired,
  documentOverrideOptions: PropTypes.array.isRequired,
  shouldSelectConfigCheckBox: PropTypes.func.isRequired,
  shouldDisableOption: PropTypes.func.isRequired,
  handleConfigurationChange: PropTypes.func.isRequired,
  shouldCheckCountry: PropTypes.func.isRequired,
};
