import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function DrawerTable({ tableElements, headings }) {
  return (
    <Grid item xs={12}>
      <Paper elevation={0} className="drawer_table__container">
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headings.map((heading) => (
                  <TableCell
                    key={heading}
                    className="drawer_table__heading"
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tableElements).map(([key, value]) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                  <TableCell key={key} className="drawer_content__text">
                    {key}
                  </TableCell>
                  <TableCell key={value} className="drawer_content__text">
                    {value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}

DrawerTable.propTypes = {
  tableElements: PropTypes.objectOf(PropTypes.any).isRequired,
  headings: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DrawerTable;
