/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { cloneDeep, set } from 'lodash';
import { fetchDependencyList } from '../utils/fetchDependencyList';
import findAllDocumentsInSelectedCountries from '../utils/findAllDocumentsInSelectedCountries';
import {
  getFormPropertyFromWorkflow,
  updateFormPropertyInComponents,
  updateFormPropertyInWorkflow,
} from '../components/FormModule/utils';
import { setModulePropertyInWorkflow } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { getAllNextSteps } from './FormModule/helper';
import generateUniqueID from '../utils/generateUniqueId';
import { getHighLevelUiConfig } from './uiConfigOperations';
import {
  logAddCondition,
  logAddNode,
  logDeleteCondition,
  logDeleteNode,
  logSwapBranchesInCondition,
  logUpdateGoto,
} from '../logger/logHighLevelWorkflowUpdates';

const getParentPath = (id, localOrderOrNodes) => {
  const { parentPath } = localOrderOrNodes.filter((node) => node.id === id)?.[0] || {};
  return parentPath;
};

const getPathArray = (path) => path.split(':').map((n) => parseInt(n, 10));

const getFormComponents = (module) => module?.properties?.sections?.[0]?.components || [];

export const endStates = ['approve', 'decline', 'manualReview'];

export const deleteConditionFromWorkflow = (id, parentId, selectedWorkflow, localOrderOfNodes) => {
  // set drawer type
  // find condition
  const workflow = cloneDeep(selectedWorkflow);
  const { conditions } = workflow;
  let condition = null;
  Object.entries(conditions).forEach(([conditionId, value]) => {
    if (conditionId === id) {
      condition = value;
    }
  });
  const { moduleDependencies, conditionDependencies } = fetchDependencyList(id, workflow);
  if (
    conditionDependencies.length ||
    moduleDependencies.length ||
    (!endStates.includes(condition.if_false) && !endStates.includes(condition.if_true))
  ) {
    // eslint-disable-next-line no-alert
    alert('remove the dependencies');
  } else {
    const newConditions = {};
    const { next_node_type } = condition;
    if (endStates.includes(condition.if_true)) {
      // preserve the false branch
      workflow.modules.forEach((module) => {
        // core module changes
        if (module.id === parentId) {
          const newNextStep = condition.if_false;
          if (module.type === 'dynamicForm') {
            // Parent is dynamic form
            // const clonnedModule = cloneDeep(module);
            // dynamic form is the parent
            const path = getParentPath(id, localOrderOfNodes);
            if (path) {
              const pathArray = getPathArray(path);
              const currentComponents = getFormComponents(module);
              const updatedComponents = updateFormPropertyInComponents(
                { nextStep: newNextStep },
                'onClick',
                pathArray,
                currentComponents,
              );
              module.properties.sections[0].components = updatedComponents;
            } else {
              alert(
                'Something went wrong\nparentPath not present in the child node, expecting because parent is dynamic form',
              );
            }
            const existingNextNodeType = module.next_node_type || {};
            module.next_node_type = { ...existingNextNodeType, [path]: undefined };
            // return { ...clonnedModule, nextStep: '' };
          } else {
            module.nextStep = newNextStep;
            module.next_node_type = next_node_type?.if_false || '';
          }
        }
      });
      Object.entries(conditions).forEach(([conditionId, value]) => {
        if (conditionId !== id) {
          newConditions[conditionId] = { ...value };
          if (conditionId === parentId && value.if_true === id) {
            newConditions[conditionId].if_true = condition.if_false;
            const existing_next_node_type = value.next_node_type || {};
            newConditions[conditionId].next_node_type = {
              ...existing_next_node_type,
              if_true: condition.next_node_type?.if_false || '',
            };
          }
          if (conditionId === parentId && value.if_false === id) {
            newConditions[conditionId].if_false = condition.if_false;
            const existing_next_node_type = value.next_node_type || {};
            newConditions[conditionId].next_node_type = {
              ...existing_next_node_type,
              if_false: condition.next_node_type?.if_false || '',
            };
          }
        }
      });
    } else {
      // preserve the true branch
      workflow.modules.forEach((module) => {
        // core module changes
        if (module.id === parentId) {
          const newNextStep = condition.if_true;
          if (module.type === 'dynamicForm') {
            // Parent is dynamic form
            // const clonnedModule = cloneDeep(module);
            // dynamic form is the parent
            const path = getParentPath(id, localOrderOfNodes);
            if (path) {
              const pathArray = getPathArray(path);
              const currentComponents = getFormComponents(module);
              const updatedComponents = updateFormPropertyInComponents(
                { nextStep: newNextStep },
                'onClick',
                pathArray,
                currentComponents,
              );
              module.properties.sections[0].components = updatedComponents;
            } else {
              alert(
                'Something went wrong\nparentPath not present in the child node, expecting because parent is dynamic form',
              );
            }
            const existingNextNodeType = module.next_node_type || {};
            module.next_node_type = { ...existingNextNodeType, [path]: undefined };
            // return { ...clonnedModule, nextStep: '' };
          } else {
            module.nextStep = newNextStep;
            module.next_node_type = next_node_type?.if_true || '';
          }
        }
      });
      Object.entries(conditions).forEach(([conditionId, value]) => {
        if (conditionId !== id) {
          newConditions[conditionId] = { ...value };
          if (conditionId === parentId && value.if_true === id) {
            newConditions[conditionId].if_true = condition.if_true;
            const existing_next_node_type = value.next_node_type || {};
            newConditions[conditionId].next_node_type = {
              ...existing_next_node_type,
              if_true: condition.next_node_type?.if_true || '',
            };
          }
          if (conditionId === parentId && value.if_false === id) {
            newConditions[conditionId].if_false = condition.if_true;
            const existing_next_node_type = value.next_node_type || {};
            newConditions[conditionId].next_node_type = {
              ...existing_next_node_type,
              if_false: condition.next_node_type?.if_true || '',
            };
          }
        }
      });
    }
    workflow.conditions = newConditions;
    logDeleteCondition({
      id,
      parentId,
    });
    return workflow;
  }
  return workflow;
};

export const deleteNodeFromWorkflow = (id, parentId, selectedWorkflow, localOrderOfNodes) => {
  const workflow = cloneDeep(selectedWorkflow);
  const [moduleToDelete] = workflow.modules.filter((module) => module.id === id);
  let newNextStep = moduleToDelete.nextStep;
  if (moduleToDelete.type === 'dynamicForm') {
    if (moduleToDelete.properties?.sections?.length) {
      const section = moduleToDelete.properties.sections[0];
      const components = section.components || [];
      const allNextSteps = getAllNextSteps(components);
      if (allNextSteps.length !== 1) {
        alert('Can not delete form module\nAs it has more than one branches');
        return workflow;
      }
      const [onlyNextStep] = allNextSteps;
      newNextStep = onlyNextStep;
    } else {
      alert('Can not delete form module\nAs it has no next step');
      return workflow;
    }
  }
  // set the nextstep of the parent
  workflow.modules.forEach((module) => {
    if (module.id === parentId) {
      if (module.type === 'dynamicForm') {
        // Parent is dynamic form
        // const clonnedModule = cloneDeep(module);
        // dynamic form is the parent
        const path = getParentPath(id, localOrderOfNodes);
        if (path) {
          const pathArray = getPathArray(path);
          const currentComponents = getFormComponents(module);
          const updatedComponents = updateFormPropertyInComponents(
            { nextStep: newNextStep },
            'onClick',
            pathArray,
            currentComponents,
          );
          module.properties.sections[0].components = updatedComponents;
        } else {
          alert(
            'Something went wrong\nparentPath not present in the child node, expecting because parent is dynamic form',
          );
        }
        const existingNextNodeType = module.next_node_type || {};
        module.next_node_type = { ...existingNextNodeType, [path]: undefined };
        // return { ...clonnedModule, nextStep: '' };
      } else {
        module.nextStep = newNextStep;
        module.next_node_type = moduleToDelete.next_node_type || '';
      }
    }
  });
  const { conditions } = workflow;
  const newConditions = {};
  Object.entries(conditions).forEach(([conditionId, value]) => {
    newConditions[conditionId] = { ...value };
    if (conditionId === parentId && value.if_true === id) {
      newConditions[conditionId].if_true = newNextStep;
      const existing_next_node_type = value.next_node_type || {};
      newConditions[conditionId].next_node_type = {
        ...existing_next_node_type,
        if_true: moduleToDelete.next_node_type || '',
      };
    }
    if (conditionId === parentId && value.if_false === id) {
      newConditions[conditionId].if_false = newNextStep;
      const existing_next_node_type = value.next_node_type || {};
      newConditions[conditionId].next_node_type = {
        ...existing_next_node_type,
        if_false: moduleToDelete.next_node_type || '',
      };
    }
  });
  const newModules = workflow.modules.filter((module) => module.id !== id);
  workflow.modules = newModules;
  workflow.conditions = newConditions;
  logDeleteNode({
    id,
    parentId,
  });
  return workflow;
};

export const swapBranchesInCondition = (conditionId, workflowConfig) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  // Find condition
  const selectedCondition = editedWorkflow.conditions[conditionId];
  if (selectedCondition) {
    const currentTrueBranch = selectedCondition.if_true;
    const currentFalseBranch = selectedCondition.if_false;
    const newCondition = cloneDeep(selectedCondition);
    newCondition.if_true = currentFalseBranch;
    newCondition.if_false = currentTrueBranch;
    const trueNextNodeType = newCondition?.next_node_type?.if_true || '';
    const falseNextNodeType = newCondition?.next_node_type?.if_false || '';
    newCondition.next_node_type = {
      if_true: falseNextNodeType,
      if_false: trueNextNodeType,
    };
    editedWorkflow.conditions[conditionId] = newCondition;
    logSwapBranchesInCondition({
      id: conditionId,
      newIfTrueBranch: newCondition.if_true,
      newIfFalseBranch: newCondition.if_false,
    });
  }
  return editedWorkflow;
};

export const addNewConditionInWorkflow = (
  addNodeBetween,
  nodes,
  workflowConfig,
  localOrderOfNodes,
) => {
  const { parent, child } = addNodeBetween;
  // create the condition object
  let nextStep = child;
  const next_node_type = {
    if_true: '',
    if_false: '',
  };
  if (child.startsWith('approve')) {
    nextStep = 'approve';
  } else if (child.startsWith('decline')) {
    nextStep = 'decline';
  } else if (child.startsWith('manualReview')) {
    nextStep = 'manualReview';
  } else if (child.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === child);
    nextStep = childNode.actualNode;
    next_node_type.if_true = 'goto';
  }
  const newCondition = {
    rule: 'NONE_NONE == NONE_NONE',
    if_true: nextStep,
    if_false: 'approve',
    next_node_type,
  };
  const uid = generateUniqueID();
  const id = `condition_${uid}`;

  let workflow = {};
  // modify the parent
  if (parent.startsWith('condition')) {
    // Parent is a condition node
    const { conditions } = workflowConfig;
    const newWorkflowConditions = {};
    // can use object.entries
    Object.keys(conditions).forEach((conditionName) => {
      if (conditionName === addNodeBetween.parent) {
        const existing_next_node_type = conditions[conditionName].next_node_type || {};
        // check which branch to attach
        if (conditions[conditionName].if_true === child || child.includes('if_true')) {
          newWorkflowConditions[conditionName] = {
            ...conditions[conditionName],
            if_true: id,
            next_node_type: {
              ...existing_next_node_type,
              if_true: '',
            },
          };
        } else {
          newWorkflowConditions[conditionName] = {
            ...conditions[conditionName],
            if_false: id,
            next_node_type: {
              ...existing_next_node_type,
              if_false: '',
            },
          };
        }
      } else {
        newWorkflowConditions[conditionName] = conditions[conditionName];
      }
    });
    workflow = {
      ...workflowConfig,
      conditions: { ...newWorkflowConditions, [id]: newCondition },
    };
  } else {
    // parent is a module node
    const newWorkflowModules = workflowConfig.modules.map((module) => {
      if (module.id === addNodeBetween.parent) {
        if (module.type === 'dynamicForm') {
          const clonnedModule = cloneDeep(module);
          // dynamic form is the parent
          const path = getParentPath(child, localOrderOfNodes);
          if (path) {
            const pathArray = getPathArray(path);
            const currentComponents = getFormComponents(clonnedModule);
            const updatedComponents = updateFormPropertyInComponents(
              { nextStep: id },
              'onClick',
              pathArray,
              currentComponents,
            );
            clonnedModule.properties.sections[0].components = updatedComponents;
          } else {
            alert(
              'Something went wrong\nparentPath not present in the child node, expecting because parent is dynamic form',
            );
          }
          const existingNextNodeType = clonnedModule.next_node_type || {};
          clonnedModule.next_node_type = { ...existingNextNodeType, [path]: undefined };
          return { ...clonnedModule, nextStep: '' };
        }
        return { ...module, nextStep: id, next_node_type: '' };
      }
      return module;
    });
    // eslint-disable-next-line max-len
    workflow = {
      ...workflowConfig,
      modules: newWorkflowModules,
      conditions: { ...workflowConfig.conditions, [id]: newCondition },
    };
  }
  // setWorkflowConfig(workflow);
  logAddCondition({
    id,
    parentId: addNodeBetween?.parent,
    childId: addNodeBetween?.child,
  });
  return { workflow, newConditionId: id };
};

// TODO: Refactor the below code to create modules, default values should be preset
export const getNewNode = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  defaultFormSections,
) => {
  const { child } = addNodeBetween;

  // generate the id
  const uid = generateUniqueID();
  const {
    type, subType, properties, variables, builderProperties = {},
  } = node;
  const id = `module_${uid}_${node.subType}`;
  // add the next step
  let nextStep = child;
  let next_node_type = '';
  if (child.startsWith('approve')) {
    nextStep = 'approve';
  } else if (child.startsWith('decline')) {
    nextStep = 'decline';
  } else if (child.startsWith('manualReview')) {
    nextStep = 'manualReview';
  } else if (child.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === child);
    nextStep = childNode.actualNode;
    next_node_type = 'goto';
  }

  const createdNode = {
    type,
    subType,
    id,
    nextStep,
    next_node_type,
  };
  const variablesWithPath =
    variables && Array.isArray(variables) ? variables.filter((variable) => variable.path) : [];
  if (node.type === 'countries') {
    return {
      ...createdNode,
      properties: {
        countriesSupported: ['ind'],
      },
    };
  }
  if (node.type === 'document') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}/${properties.endpoint}`;
    const { apiType } = properties;
    // compute documents for all selected countries
    const selectedCountries = workflowConfig.modules[0].properties.countriesSupported;
    const documentsSupported = findAllDocumentsInSelectedCountries(
      selectedCountries,
      countryDocMapping,
    );

    const newProperties = {
      url,
      apiType,
      documentsSupported,
      countryId: '{module_countryPicker.countryId}',
    };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'face') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}${properties.endpoint}`;
    const { apiType } = properties;
    const newProperties = { url, apiType };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'api') {
    // create url
    // what if multiple country modules are there
    // TODO: Refactor later
    const baseUrl = typeof properties?.baseUrl === 'string' ? properties?.baseUrl : '';
    const endpoint = typeof properties?.endpoint === 'string' ? properties?.endpoint : '';

    let url = `${baseUrl}${endpoint}`;
    url = url.replace('<countries>', workflowConfig.modules[0].id);
    const { apiType, allowedStatusCodes } = properties;
    const newProperties = { url, apiType };
    if (Array.isArray(allowedStatusCodes) && (allowedStatusCodes || []).length > 0) {
      newProperties.allowedStatusCodes = allowedStatusCodes;
    }
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    if (properties.requestParametersCombinations) {
      const arr = properties.requestParametersCombinations[1];
      const newReqParams = properties.requestParameters.filter((val) => arr.includes(val.name));
      newProperties.requestParameters = newReqParams;
    } else if (properties.requestParameters) {
      newProperties.requestParameters = properties.requestParameters.map((param) => ({
        type: param.type,
        name: param.name,
        value: param.value.replace('<countries>', workflowConfig.modules[0].id),
      }));
    }
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'dynamicForm') {
    const defaultSections = cloneDeep(defaultFormSections);
    const defaultButton = defaultSections[0].components.find((comp) => comp.type === 'button');
    set(defaultButton, 'onClick.nextStep', nextStep);

    (defaultSections[0].components || []).forEach((_, i) => {
      const currentCompId = defaultSections[0].components[i].id;
      defaultSections[0].components[i].id = `${currentCompId}_${generateUniqueID()}`;
    });

    return {
      ...createdNode,
      nextStep: '',
      properties: {
        sections: defaultSections,
      },
    };
  }
  if (node.type === 'superModule') {
    const newProperties = {};
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key]?.values?.[0];
        } else if (properties[key]?.type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });

    const newBuilderProperties = Object.entries(builderProperties).reduce(
      (currProperties, [propKey, propValue]) => {
        if (propValue.default) {
          return { ...currProperties, [propKey]: propValue.default };
        }
        if (propValue.type === 'boolean' || propValue.type === 'string') {
          return { ...currProperties, [propKey]: propValue?.values?.[0] };
        }
        if (propValue.type === 'array') {
          return { ...currProperties, [propKey]: propValue?.values };
        }
        return { ...currProperties, [propKey]: '' };
      },
      {},
    );

    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
      builderProperties: newBuilderProperties,
    };
  }
  return {
    ...createdNode,
    variables,
  };
};

const addDefaultPropertiesToModule = (moduleId, workflow, node) => {
  let editedWorkflow = cloneDeep(workflow);
  const propertiesToSet = [
    ...(node.sections?.configurations || []),
    ...(node.sections?.inputs || []),
  ];
  propertiesToSet.forEach((property) => {
    const { workflowKey, default: defaultValue, type: inputType } = property;
    let valueToSet = defaultValue;
    if (typeof defaultValue === 'undefined' && inputType === 'duration') valueToSet = 0;
    if (typeof valueToSet !== 'undefined') {
      editedWorkflow = setModulePropertyInWorkflow(
        editedWorkflow,
        moduleId,
        workflowKey,
        valueToSet,
        node,
      );
    }
  });
  return editedWorkflow;
};

export const addNewNodeInWorkflow = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  localOrderOfNodes,
  defaultFormSections,
) => {
  const newModule = getNewNode(
    node,
    addNodeBetween,
    nodes,
    workflowConfig,
    countryDocMapping,
    defaultFormSections,
  );
  const { id } = newModule;
  let workflow = {};
  const { parent, child } = addNodeBetween;
  // modify the parent node
  if (parent.startsWith('condition')) {
    const { conditions } = workflowConfig;
    const newWorkflowConditions = {};
    // can use object.entries
    Object.keys(conditions).forEach((conditionName) => {
      if (conditionName === addNodeBetween.parent) {
        // check which branch to attach
        if (conditions[conditionName].if_true === child || child.includes('if_true')) {
          const existing_next_node_type = conditions[conditionName].next_node_type || {};
          newWorkflowConditions[conditionName] = {
            ...conditions[conditionName],
            if_true: id,
            next_node_type: {
              ...existing_next_node_type,
              if_true: '',
            },
          };
        } else {
          const existing_next_node_type = conditions[conditionName].next_node_type || {};
          newWorkflowConditions[conditionName] = {
            ...conditions[conditionName],
            if_false: id,
            next_node_type: {
              ...existing_next_node_type,
              if_false: '',
            },
          };
        }
      } else {
        newWorkflowConditions[conditionName] = conditions[conditionName];
      }
    });
    workflow = {
      ...workflowConfig,
      conditions: newWorkflowConditions,
      modules: [...workflowConfig.modules, newModule],
    };
  } else {
    // for module edges
    const newWorkflowModules = workflowConfig.modules.map((module) => {
      if (module.id === addNodeBetween.parent) {
        if (module.type === 'dynamicForm') {
          const clonnedModule = cloneDeep(module);
          // dynamic form is the parent
          const path = getParentPath(child, localOrderOfNodes);
          if (path) {
            const pathArray = getPathArray(path);
            const currentComponents = getFormComponents(clonnedModule);
            const updatedComponents = updateFormPropertyInComponents(
              { nextStep: id },
              'onClick',
              pathArray,
              currentComponents,
            );
            clonnedModule.properties.sections[0].components = updatedComponents;
          } else {
            alert(
              'Something went wrong\nparentPath not present in the child node, expecting because parent is dynamic form',
            );
          }
          const existingNextNodeType = clonnedModule.next_node_type || {};
          clonnedModule.next_node_type = { ...existingNextNodeType, [path]: undefined };
          return { ...clonnedModule, nextStep: '' };
        }
        // normal parent
        return { ...module, nextStep: id, next_node_type: '' };
      }
      return module;
    });
    // eslint-disable-next-line max-len
    workflow = { ...workflowConfig, modules: [...newWorkflowModules, newModule] };
  }
  const editedWorkflow = addDefaultPropertiesToModule(id, workflow, node);
  let highLevelUiConfig = {};
  if (newModule?.type === 'superModule') {
    const defaultSuperModuleUIConfig = node?.library?.uiFormatting || {};
    highLevelUiConfig = getHighLevelUiConfig(id, defaultSuperModuleUIConfig);
  }
  logAddNode({
    id,
    parentId: addNodeBetween?.parent,
    childId: addNodeBetween?.child,
    subType: node?.subType,
  });
  return { editedWorkflow, highLevelUiConfig, newModule };
};

export const updateGotoInWorkflow = (parentNodeId, parentBranch, updatedGotoModule, workflow) => {
  // Check if parent node Id is a condition
  const isParentCondition = Object.keys(workflow.conditions).includes(parentNodeId);
  const isParentForm =
    workflow.modules.find((workflowModule) => workflowModule.id === parentNodeId)?.type ===
    'dynamicForm';
  let editedWorkflow = cloneDeep(workflow);
  let oldPath = null;
  if (isParentCondition) {
    oldPath = editedWorkflow.conditions[parentNodeId][parentBranch];
    editedWorkflow.conditions[parentNodeId] = {
      ...editedWorkflow.conditions[parentNodeId],
      next_node_type: {
        ...editedWorkflow.conditions[parentNodeId].next_node_type,
        [parentBranch]: 'goto',
      },
      [parentBranch]: updatedGotoModule,
    };
  } else if (isParentForm) {
    const pathArray = getPathArray(parentBranch);
    oldPath = getFormPropertyFromWorkflow('onClick', pathArray, workflow, parentNodeId);
    editedWorkflow = updateFormPropertyInWorkflow(
      { nextStep: updatedGotoModule },
      'onClick',
      pathArray,
      workflow,
      parentNodeId,
    );
  } else {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    oldPath = editedWorkflow.modules[parentModuleIndex].nextStep;
    editedWorkflow.modules[parentModuleIndex] = {
      ...editedWorkflow.modules[parentModuleIndex],
      next_node_type: { default: 'goto' },
      nextStep: updatedGotoModule,
    };
  }
  logUpdateGoto({
    id: parentNodeId,
    parentBranch,
    oldGotoStep: oldPath,
    newGotoStep: updatedGotoModule,
  });
  return editedWorkflow;
};
