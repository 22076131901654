/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';

import removeUnvisitedNodesAndConditions from '../workflowOperations/utils';
import { setModuleProperty } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { replaceAll } from '../utils/helper';
import replaceNextStepId from './utils/replaceNextStepId';

const replaceVariables = (workflow, replacements) => {
  const workflowString = JSON.stringify(workflow);
  let finalWorkflowString = workflowString;
  replacements.forEach((replacement) => {
    finalWorkflowString = replaceAll(
      finalWorkflowString,
      replacement.key,
      replacement.value,
    );
  });
  const finalWorkflowObj = JSON.parse(finalWorkflowString);
  return finalWorkflowObj;
};

const compileSuperModule = (superModule, moduleConfigs) => {
  // Use the library to create a template
  const superModuleId = superModule?.id;
  const superModuleType = superModule?.subType;
  const moduleConfig = moduleConfigs[superModuleType];
  const { library, initialStep } = moduleConfig;
  const clonnedLibrary = cloneDeep(library);
  // replacing the builder properties with the value
  let replacementArrForBuilderProperties = [];
  if (Object.keys(superModule.builderProperties || {}).length) {
    clonnedLibrary.builderProperties = superModule.builderProperties;

    replacementArrForBuilderProperties = Object.entries(superModule.builderProperties || {})
      .reduce((arrSoFar, [propName, propValue]) => [...arrSoFar, {
        key: `builderProperties[-]${propName}`,
        value: typeof propValue === 'string' ? `'${propValue}'` : propValue,
      }], []);
  }
  // Creating modules
  // create modulesMap
  const modulesMap = {};
  clonnedLibrary?.modules.forEach((module) => {
    modulesMap[module.id] = module;
  });
  // Distribute properties

  const propertiesArr = Object.keys(superModule.properties);
  propertiesArr.forEach((property) => {
    const [mappingId, actualProperty] = property.split('[+]');
    const currentModuleSubType = modulesMap[mappingId].subType;
    const currentModuleConfig = moduleConfigs[currentModuleSubType];
    modulesMap[mappingId].properties = setModuleProperty(
      actualProperty,
      superModule.properties[property],
      modulesMap[mappingId].properties,
      currentModuleConfig,
    );
  });
  // Updation of internal module ids
  // uuid -> some 5 digit alfa numeric id
  // module_aadhaar -> module_uuid_<subType of the individual module>
  // JSON.stringify and replace
  const replacementArrForModuleIds = [];
  const mappingIdMap = {}; // acutalModuleId: mappingId
  const mappingIdtoActualIdMap = {};
  clonnedLibrary.modules.forEach((module) => {
    const finalModuleId = `${superModuleId}_${module.id}_${module.subType}`;
    replacementArrForModuleIds.push({
      key: module.id,
      value: finalModuleId,
    });
    mappingIdMap[finalModuleId] = module.id;
    mappingIdtoActualIdMap[module.id] = finalModuleId;
  });
  const replacementArrForConditionalVariables = [];
  const conditionalVariablesArr = Object.keys(
    clonnedLibrary.conditionalVariables,
  );
  conditionalVariablesArr.forEach((conditionalVariable) => {
    replacementArrForConditionalVariables.push({
      key: conditionalVariable,
      value: `${superModuleId}_${conditionalVariable}`,
    });
  });
  const repalcementForConditionIds = [];
  const conditionaIdsArr = Object.keys(clonnedLibrary.conditions);
  conditionaIdsArr.forEach((conditionalId) => {
    const finalConditionId = `condition_${superModuleId}_${conditionalId}`;
    repalcementForConditionIds.push({
      key: conditionalId,
      value: finalConditionId,
    });
    mappingIdMap[finalConditionId] = conditionalId;
    mappingIdtoActualIdMap[conditionalId] = finalConditionId;
  });
  const replacementArr = [
    ...replacementArrForModuleIds,
    ...repalcementForConditionIds,
    ...replacementArrForConditionalVariables,
    ...replacementArrForBuilderProperties,
    {
      key: 'EXIT_POINT',
      value: `${superModuleId}_EXIT_POINT`,
    },
  ];
  const compiledModule = replaceVariables(clonnedLibrary, replacementArr);
  compiledModule.modules.forEach((module) => {
    module.mappingId = mappingIdMap[module.id];
    // eslint-disable-next-line no-param-reassign
    module.superModuleType = superModuleType;
    module.superModuleId = superModuleId;
  });
  const variableReplacementsList = [];
  moduleConfig.variables.forEach((variable) => {
    const { name, path } = variable;
    const [mappingModuleId, ...keyNames] = path.split('.');
    const key = keyNames.join('.');
    if (mappingModuleId === 'conditionalVariables') {
      variableReplacementsList.push({
        key: `${superModuleId}.${name}`,
        value: `conditionalVariables.${superModuleId}_${key}`,
      });
    } else {
      variableReplacementsList.push({
        key: `${superModuleId}.${name}`,
        value: `${mappingIdtoActualIdMap[mappingModuleId]}.${key}`,
      });
    }
  });
  const mappingIdOfTheFirstStep = initialStep || library.modules[0].id;
  const startNodeOfTheLibrary = mappingIdtoActualIdMap[mappingIdOfTheFirstStep];
  const exitPointReplacement = {
    key: `${superModuleId}_EXIT_POINT`,
    value: superModule.nextStep,
  };
  return {
    compiledModule,
    variableReplacementsList,
    startNodeOfTheLibrary,
    exitPointReplacement,
  };
};

const compile = (highLevelWorkflow, moduleConfigs) => {
  let lowLevelWorkflow = {};
  const { modules: originalModules, ...rest } = highLevelWorkflow;
  lowLevelWorkflow = { ...rest };
  const modules = [];
  const superModuleMap = {};
  let metaData = {};
  originalModules.forEach((module) => {
    if (module?.type === 'superModule') {
      // complex super module
      const { id: superModuleId } = module;
      superModuleMap[superModuleId] = module;
    } else {
      // simple module
      modules.push(module);
    }
  });
  let replacements = [];
  const compiledModules = [];
  const firstStepReplacementArr = [];
  const firstStepReplacementMap = {};
  const exitPointReplacements = [];
  Object.keys(superModuleMap).forEach((superModuleId) => {
    const {
      compiledModule, variableReplacementsList, startNodeOfTheLibrary, exitPointReplacement,
    } = compileSuperModule(
      superModuleMap[superModuleId],
      moduleConfigs,
    );
    metaData = {
      ...metaData,
      [superModuleId]: {
        startNodeId: startNodeOfTheLibrary,
        exitNodeId: exitPointReplacement?.value,
        moduleName: superModuleMap[superModuleId]?.name || null,
      },
    };
    replacements = [...replacements, ...variableReplacementsList];
    compiledModules.push(compiledModule);
    firstStepReplacementArr.push({
      key: superModuleId,
      value: startNodeOfTheLibrary,
    });
    firstStepReplacementMap[superModuleId] = startNodeOfTheLibrary;
    exitPointReplacements.push(exitPointReplacement);
    // TODO: Why are we doing this ?
    lowLevelWorkflow.properties = {
      ...lowLevelWorkflow.properties,
    };

    if (compiledModule.builderProperties) {
      const newBuilderProperties = {
        ...(lowLevelWorkflow?.properties?.builderProperties || {}),
        [superModuleId]: compiledModule.builderProperties,
      };
      lowLevelWorkflow.properties.builderProperties = newBuilderProperties;
    }
  });
  Object.keys(metaData).forEach((superModuleId) => {
    const { exitNodeId } = metaData[superModuleId];
    if (metaData[exitNodeId]) {
      // its pointing to a super module id, should be converted to the actual id
      const actualId = metaData[exitNodeId].startNodeId;
      metaData[superModuleId].exitNodeId = actualId;
    }
  });
  lowLevelWorkflow.properties = {
    ...lowLevelWorkflow.properties,
    builder: {
      superModuleMetaData: metaData,
    },
    builtOnBuilder: true,
  };
  lowLevelWorkflow.modules = modules;
  compiledModules.forEach((compiledModule) => {
    lowLevelWorkflow.modules = [
      ...lowLevelWorkflow.modules,
      ...compiledModule.modules,
    ];
    lowLevelWorkflow.conditions = {
      ...lowLevelWorkflow.conditions,
      ...compiledModule.conditions,
    };
    lowLevelWorkflow.conditionalVariables = {
      ...lowLevelWorkflow.conditionalVariables,
      ...compiledModule.conditionalVariables,
    };
  });
  lowLevelWorkflow = replaceVariables(lowLevelWorkflow, replacements);
  // Update the nextSteps of the parent nodes
  lowLevelWorkflow = replaceNextStepId(lowLevelWorkflow, firstStepReplacementArr);

  // Updated EXIT POINT
  const exitPointsReplacement = exitPointReplacements.map((exitPoint) => {
    const { key, value } = exitPoint;
    if (superModuleMap[value]) {
      // super module id
      return {
        key,
        value: firstStepReplacementMap[value],
      };
    }
    // simple module
    return { key, value };
  });

  lowLevelWorkflow = replaceVariables(lowLevelWorkflow, exitPointsReplacement);
  lowLevelWorkflow = removeUnvisitedNodesAndConditions(lowLevelWorkflow);
  return lowLevelWorkflow;
};

export default compile;
