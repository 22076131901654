/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes, { object } from 'prop-types';

import '../../NewConditionDrawer/NewConditionDrawer.scss';
import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-unused-vars
function ShowConditionalVarInputOptions({
  handleModuleSelect,
  showOptionStyle,
  showOption, setShowOption, handleInputSelect, closeVariableDrawer,
}) {
  const divRef = useRef(null);
  const [closeOptionsModal, setCloseOptionsModal] = useState(!showOption);
  const [moduleOutputSelected, setModuleOutputSelected] = useState(false);

  const handleCloseOptionsModal = () => {
    setCloseOptionsModal(true);
    closeVariableDrawer();
  };

  useEffect(() => {
    setShowOption(!closeOptionsModal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOptionsModal]);

  const handleCustomInputSelection = () => {
    handleInputSelect();
  };

  const handleClickModuleSelect = (e) => {
    handleModuleSelect(e, divRef);
    setCloseOptionsModal(false);
  };

  useEffect(() => {
    if (!closeOptionsModal) {
      setModuleOutputSelected(true);
    }
  }, [closeOptionsModal]);

  return (
    <div>
      {!closeOptionsModal && (
        <div>
          <div
            className="choose-input-options-modal"
            style={{ top: showOptionStyle.top, left: showOptionStyle.left }}
            ref={divRef}
          >
            <div>
              <div
                className="choose-input-options-modal__options"
                onClick={handleCustomInputSelection}
              >
                Set Custom Input
              </div>
              <div
                className={`choose-input-options-modal__options ${
                  moduleOutputSelected ? 'active_module' : ''
                }`}
                onClick={(e) => {
                  handleClickModuleSelect(e);
                }}
              >
                Select Module Output
              </div>
            </div>

          </div>
          <div className="choose-input-options-modal__background" onClick={handleCloseOptionsModal} />
        </div>
      )}
    </div>
  );
}

export default ShowConditionalVarInputOptions;

ShowConditionalVarInputOptions.propTypes = {
  handleModuleSelect: PropTypes.func.isRequired,
  showOptionStyle: PropTypes.instanceOf(object).isRequired,
  showOption: PropTypes.bool.isRequired,
  setShowOption: PropTypes.func.isRequired,
  handleInputSelect: PropTypes.func.isRequired,
  closeVariableDrawer: PropTypes.func.isRequired,
};
