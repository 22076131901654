import PropTypes from 'prop-types';
import {
  InputLabel, TextField, FormControl, MenuItem, Select,
} from '@mui/material';
import conditionDeleteIcon from '../../../../assests/icons/conditionDeleteIcon.svg';
import StringInput from '../AnyInput/StringInput';

function RequestParameter({
  name: defaultName,
  value: defaultValue,
  type: defaultType,
  onNameChange, onValueChange, onTypeChange, onDelete,
  supportedTypes,
  nameLabel,
  pathLabel,
  typeLabel,
}) {
  const handleNameChange = (event) => {
    const { value } = event.target;
    onNameChange(value);
  };

  const handleTypeChange = (event) => {
    const { value } = event.target;
    onTypeChange(value);
  };

  return (
    <div className="workflow_output_field">
      <div className="workflow_output_field_key">
        <TextField
          label={nameLabel}
          variant="outlined"
          value={defaultName}
          onChange={handleNameChange}
          error={false}
          helperText=""
          size="small"
          className="workflow_output_field_textfield"
        />
      </div>
      <StringInput
        value={`${defaultValue}`}
        onChange={onValueChange}
        label={pathLabel}
      />
      <div className="workflow_output_field_typeSelectionDiv">
        <FormControl className="workflow_output_field_typeSelection">
          <InputLabel id="demo-simple-select-label">{typeLabel}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={defaultType}
            label="Type"
            onChange={handleTypeChange}
          >
            {' '}
            {
            supportedTypes?.length ?
              (
                supportedTypes.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>{label}</MenuItem>
                ))
              )
              : null
        }
          </Select>
        </FormControl>
      </div>
      <div className="workflow_output_field_deleteButtonDiv">
        <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
          <img
            src={conditionDeleteIcon}
            alt="delete"
          />
        </button>
      </div>
    </div>
  );
}

RequestParameter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  supportedTypes: PropTypes.array.isRequired,
};

export default RequestParameter;
