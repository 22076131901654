import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GenericConfirmationModal from './GenericConfirmationModal/GenericConfirmationModal';
import useApiHooks from '../../../utils/useApiHooks';
import useShowErrorAlert, { useShowCustomAlert } from '../../../utils/customHooks';
import AlertCode from '../../../constants/alertCodes';
import CustomTooltip from '../../Common/CustomTooltip';

function PublishToStaging() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const appIds = useSelector((state) => state.user.appIds);
  const isWorkflowUnsaved = useSelector((state) => state.workflow.isEdited);
  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  const showCustomAlert = useShowCustomAlert();
  const showErrorAlert = useShowErrorAlert();
  const { pushToStaging } = useApiHooks();

  const stagingAppIds = Object.keys(appIds).filter((appId) => appIds[appId].type === 'STAGING');

  const publishWorkflowToStaging = async (allAppIds, selectedWorkflowId) => {
    let response = null;
    try {
      response = await pushToStaging(allAppIds, selectedWorkflowId);
      if (response && !response?.isSuccessful) {
        showErrorAlert({
          error: response.error,
          message: AlertCode.error.errorPublishingWorkflow,
        });
      } else {
        showCustomAlert({
          message: workflowId,
          alertSeverity: 'success',
        });
      }
    } catch (error) {
      showErrorAlert({
        error: response.error,
        message: AlertCode.error.errorPublishingWorkflow,
      });
    }
  };

  const onModalCancel = () => {
    setShowConfirmationModal(false);
  };

  const onModalConfirm = () => {
    setShowConfirmationModal(false);
    setIsPublishing(true);
    publishWorkflowToStaging(stagingAppIds, workflowId)
      .then(() => {
        setIsPublishing(false);
      })
      .catch(() => {
        setIsPublishing(false);
      });
  };

  const showModal = () => {
    setShowConfirmationModal(true);
  };

  const descriptionTextArr = [
    {
      id: 'p1',
      text: `This action will push the workflow to ${stagingAppIds.join(',')}`,
    },
    {
      id: 'p2',
      text: 'If the workflow already exists, it will get overwritten.',
    },
    {
      id: 'p3',
      text: 'This is a highly sensitive operation and should not be used accidentally. Only proceed if you know exactly what you are doing.',
    },
  ];

  return (
    <>
      <CustomTooltip
        title={(
          <>
            {isWorkflowUnsaved ? 'The workflow should be saved to enable ' : 'Use this for '}
            <strong>Publish To Staging</strong>
          </>
        )}
        placement="left"
        arrow
      >
        <span>
          <Button
            variant="outlined"
            className="more-options-workflow"
            onClick={showModal}
            disabled={isWorkflowUnsaved || isPublishing || stagingAppIds.length === 0}
          >
            {isPublishing ? (
              <>
                <CircularProgress size="1rem" />
                Saving
              </>
            ) : (
              'Publish to staging'
            )}
          </Button>
        </span>
      </CustomTooltip>
      {showConfirmationModal ? (
        <GenericConfirmationModal
          titleText="Push to staging?"
          descriptionTextArr={descriptionTextArr}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          onCancelClick={onModalCancel}
          onConfirmClick={onModalConfirm}
        />
      ) : null}
    </>
  );
}

export default PublishToStaging;
