import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import '../../containers/Container.scss';
import { closeExistingSDK } from '../utils';
import sampleImages from '../../constants/sampleImages';
import { replaceSuperModuleIdsWithActualIds } from '../../containers/uiConfigOperations';

function DisplaySDKScreen(props) {
  const {
    workflowModules,
    selectedWorkflow,
    screenToTeleport,
    uiConfig,
    textConfig,
    moduleToTeleport,
    jwtToken,
    customStyleSheets,
    reloadSdk,
    closeSdk,
    selectedLanguage,
    defaultTextConfig,
    selectedElement,
    workflowId,
  } = props;

  const callback = () => {
    // console.log(HVResponse);
  };

  // eslint-disable-next-line no-undef
  const hyperKycConfig = useMemo(() => new HyperKycConfig(
    jwtToken,
    workflowId,
    'transactionId',
    false,
    null,
    true,
    true,
  ), [jwtToken, workflowId]);

  useEffect(() => {
    if (!closeSdk && Object.keys(selectedWorkflow || {}).length > 0) {
      hyperKycConfig.setInputs({
        builderSampleFace: sampleImages.face,
        builderSampledoc: sampleImages.doc,
      });
      const styles = {
        left: '250px',
        top: '48px',
        width: '32.4%',
        height: '100%',
        backgroundColor: 'rgba(85, 78, 241, 0.03)',
      };
      hyperKycConfig.setBuilderBrandingStyles(styles);
      // This is the compiled workflow
      hyperKycConfig.setUserWorkflow(selectedWorkflow);

      if (uiConfig && Object.keys(uiConfig).length > 0) {
        const compiledModuleUiConfig =
      replaceSuperModuleIdsWithActualIds(selectedWorkflow?.modules || [], uiConfig);
        hyperKycConfig.setUiConfig(compiledModuleUiConfig);
      }

      if (textConfig
        && Object.keys(textConfig).length > 0) hyperKycConfig.setTextConfig(textConfig);
      else hyperKycConfig.setTextConfig(defaultTextConfig);

      hyperKycConfig.enableEditBranding(true);
      hyperKycConfig.setLanguageUsed(selectedLanguage);
      if (screenToTeleport === 'retake' && selectedElement?.type === 'errorDropdown') hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport, selectedElement?.textConfig);
      else hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport);

      if (customStyleSheets?.length) {
        customStyleSheets.forEach((customStyleSheet) => {
          hyperKycConfig.setCustomFontStylesheet(customStyleSheet);
        });
      }
      // eslint-disable-next-line no-undef
      HyperKYCModule.launch(hyperKycConfig, callback);
    }

    return () => {
      closeExistingSDK();
    };
  }, [screenToTeleport, moduleToTeleport, textConfig,
    reloadSdk, closeSdk, hyperKycConfig, selectedWorkflow,
    workflowModules, uiConfig, customStyleSheets, selectedLanguage,
    defaultTextConfig, selectedElement]);

  // Understand from @Pawan, why are we having two useEffects here ?
  useEffect(() => {
    if (customStyleSheets?.length) {
      customStyleSheets.forEach((customStyleSheet) => {
        hyperKycConfig.setCustomFontStylesheet(customStyleSheet);
      });
    }
    const compiledModuleUiConfig =
      replaceSuperModuleIdsWithActualIds(selectedWorkflow?.modules || [], uiConfig);
    window.loadCustomUICss({ uiConfig: compiledModuleUiConfig });
  }, [customStyleSheets, hyperKycConfig, uiConfig, selectedWorkflow]);

  return null;
}

DisplaySDKScreen.propTypes = {
  workflowModules: PropTypes.array.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  screenToTeleport: PropTypes.string.isRequired,
  uiConfig: PropTypes.object.isRequired,
  textConfig: PropTypes.object.isRequired,
  moduleToTeleport: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
  customStyleSheets: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadSdk: PropTypes.bool.isRequired,
  closeSdk: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  defaultTextConfig: PropTypes.object.isRequired,
  selectedElement: PropTypes.object.isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default DisplaySDKScreen;
