import PropTypes from 'prop-types';

function SelectedCountriesTable({ options, countriesSelected, togglePopup }) {
  const {
    titleText, selectButtonText,
  } = options;

  return (
    <div>
      <div className="document-select-title">{titleText}</div>
      {countriesSelected.length > 0 ? (
        <div className="countries-table">
          <div className="configuration-heading">
            {' '}
          </div>
          <table>
            <tbody>
              {countriesSelected.map((item) => (
                <tr key={`${item.name}_table`}>
                  <td className="flag-column">
                    {' '}
                    <img className="countries-table__countries-flag" src={`https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/flags/${item.id}.png`} alt="" />
                  </td>
                  <td>
                    {item.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : ''}
      <button type="button" className="document-select-selectButton" onClick={togglePopup}>{selectButtonText}</button>
    </div>
  );
}

SelectedCountriesTable.propTypes = {
  options: PropTypes.object.isRequired,
  countriesSelected: PropTypes.array.isRequired,
  togglePopup: PropTypes.func.isRequired,
};

export default SelectedCountriesTable;
