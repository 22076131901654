import Joi from 'joi-browser';

const isValidData = (data) => {
  const schema = Joi.array().items(
    Joi.object({
      name: Joi.string().required().allow(''),
      path: Joi.string().required().allow(''),
    }),
  );
  const { error } = schema.validate(data);
  return error === null;
};

export default isValidData;
