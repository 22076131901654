import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../../utils/useDebounce';

function ShowValidationDrawer(props) {
  const { defaultValue, onChange, hideRegexTab = false } = props;

  const [validationType, setValidationType] = useState(defaultValue.type);
  const [ruleValue, setRuleValue] = useState(defaultValue.type === 'rule' ? defaultValue
    : { type: 'rule', value: '', errorMsg: '' });
  const [regexValue, setRegexValue] = useState(defaultValue.type === 'regex' ? defaultValue
    : { type: 'regex', value: '', errorMsg: '' });

  const debouncedRuleValue = useDebounce(ruleValue);
  const debouncedRegexValue = useDebounce(regexValue);

  useEffect(() => {
    if (validationType === 'rule') {
      onChange(debouncedRuleValue);
    } else {
      onChange(debouncedRegexValue);
    }
  }, [validationType, debouncedRegexValue, debouncedRuleValue]);

  const handleBtnClick = (type) => {
    setValidationType(type);
  };

  const handleTextChange = (type, e, isErrorMsg = false) => {
    const currentText = e.target.value;
    if (type === 'regex') {
      setRegexValue((currentRegexValue) => {
        const localObject = { ...currentRegexValue };
        if (isErrorMsg) localObject.errorMsg = currentText;
        else localObject.value = currentText;
        return localObject;
      });
    } else {
      setRuleValue((currentRuleValue) => {
        const localObject = { ...currentRuleValue };
        if (isErrorMsg) localObject.errorMsg = currentText;
        else localObject.value = currentText;
        return localObject;
      });
    }
  };

  const getInputContents = (type, rule, regex) => {
    const displayValue = type === 'rule' ? rule : regex;
    return (
      <>
        <div>
          <input type="text" value={displayValue.value} className="validation-value" onChange={(e) => handleTextChange(type, e)} placeholder={`Write your ${type} here`} />
        </div>
        <div className="validation-drawer-div__errormsg">
          <label htmlFor={`${type}_error`}>
            Error Message:
            <input
              type="text"
              id={`${type}_error`}
              value={displayValue.errorMsg}
              className="validation-value"
              onChange={(e) => handleTextChange(type, e, true)}
              placeholder={`Write error message for your ${type} here`}
            />
          </label>
        </div>
      </>
    );
  };

  return (
    <div className="validation-drawer-div">
      <div>
        <button type="button" onClick={() => { handleBtnClick('rule'); }} className={`validation-type-btn${validationType === 'rule' ? '__active' : ''}`} id="rule">Rule</button>
        {!hideRegexTab ?
          <button type="button" onClick={() => { handleBtnClick('regex'); }} className={`validation-type-btn${validationType === 'regex' ? '__active' : ''}`} id="regex">Regex</button> : null}
      </div>
      {
        getInputContents(validationType, ruleValue, regexValue)
      }
    </div>
  );
}

export default ShowValidationDrawer;

ShowValidationDrawer.propTypes = {
  defaultValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hideRegexTab: PropTypes.bool,
};

ShowValidationDrawer.defaultProps = {
  hideRegexTab: false,
};
