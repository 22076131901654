/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { selectSelectedWorkflow, selectSelectedNode, selectOrderOfNodes } from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import TextInput from '../../../ImportWorkflow/TextInput';

function SetNodeName() {
  const selectedNode = useSelector(selectSelectedNode);
  const workflow = useSelector(selectSelectedWorkflow);
  const orderOfNodes = useSelector(selectOrderOfNodes);

  const [isNameValid, setIsValid] = useState(true);
  const [defaultName, setDefaultName] = useState('');

  const defaultNodeName = (workflow, selectedNode) => {
    if (selectedNode.nodeType === 'condition') return workflow.conditions[selectedNode.id].name || selectedNode.id;
    return workflow?.modules?.filter((module) => module.id === selectedNode.id)[0]?.name || '';
  };

  const updateNodeName = (name) => {
    const local = cloneDeep(workflow);
    if (selectedNode.nodeType === 'condition') {
      local.conditions[selectedNode.id].name = name;
    } else {
      const filteredModule = local.modules.filter((node) => node.id === selectedNode.id)[0];
      filteredModule.name = name;
    }
    updateWorkflowInState(local);
  };

  const isValidUniqueName = (name) => {
    if (name.length === 0) return false;
    const usedNames = orderOfNodes.map((node) => {
      if (node.id !== selectedNode?.id) return node?.name;
      return null;
    });
    if (usedNames.includes(name)) return false;
    return true;
  };

  useEffect(() => {
    const defaultPlaceholder = defaultNodeName(workflow, selectedNode);
    setDefaultName(defaultPlaceholder);
    const validity = isValidUniqueName(defaultPlaceholder);
    setIsValid(validity);
  }, [selectedNode]);

  const handleNodeNameChange = (name) => {
    const valid = isValidUniqueName(name);
    setIsValid(valid);
    if (valid) updateNodeName(name);
  };

  return (
    <TextInput
      label="Name of node"
      onChange={handleNodeNameChange}
      placeholder={defaultName}
      isInvalid={!isNameValid}
      setDefaultValue
    />
  );
}

export default SetNodeName;
