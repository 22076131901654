import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { selectModules, selectSelectedNode, selectSelectedWorkflow } from '../../../../reducers/workflow';
import { reshapeArrayStateToObjectData, reshapeObjectDataToArrayState } from '../../../../utils/helper';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import PanelItem from './panelItem';
import TabItem from './tabItem';
import EditConfiguration from './EditConfiguration';
import BodyParamToggle from './BodyParamToggle';
import Header from './Header';
import {
  getWorkflowValueForRootKey,
  ifOnlyReqParamsAndBodyInPanel,
  setValueInWorkflow,
  unSetValueInWorkflow,
} from './helper';
import '../NewConditionDrawer/NewConditionDrawer.scss';
import withDeletionDependencyCheck from '../../../../utils/withDeletionDependencyCheck';

function ConfigurationModal({ config, toggleModal, checkDependencies: checkDeletionDependencies }) {
  const { texts, panels } = config;
  const { title: titleText, save: saveText } = texts;
  const [panelIndex, setPanelIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [toggleState, setToggleState] = useState(null);

  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];
  const selectedWorkflowModules = selectedWorkflow.modules;
  const selectedModule = selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  );

  const loadData = (currentPanels, module, moduleConfig) => {
    // Assumption: No config has interfering targets
    // i.e, We have no two configs that are settig a.b and a.b.c separately
    const initialData = {};
    currentPanels.forEach(({ tabs }, panelI) => {
      tabs.forEach(({ rootWorkflowKey, type }, tabI) => {
        const rawData = getWorkflowValueForRootKey(rootWorkflowKey, module, moduleConfig);
        initialData[`${panelI}_${tabI}`] = (type === 'typedSimpleLinearObject' || type === 'simpleLinearObject') ? reshapeObjectDataToArrayState(rawData, type === 'typedSimpleLinearObject') : rawData;
      });
    });
    return initialData;
  };

  const loadedData = loadData(panels, selectedModule, selectedModuleConfig);
  const [tempStore, setTempStore] = useState(loadedData);

  const updateTempStore = (data, pIndex, tIndex, store) => {
    const newState = cloneDeep(store);
    newState[`${pIndex}_${tIndex}`] = data;
    setTempStore(newState);
  };

  const handleToggle = (value) => {
    setToggleState(value === 'param' || value === 'body' ? value : null);
  };

  const saveData = (currentWorkflow, currentPanels, store, moduleId, moduleConfig) => {
    let workflow = cloneDeep(currentWorkflow);
    currentPanels.forEach(({ tabs }, panelI) => {
      tabs.forEach(({ rootWorkflowKey, type }, tabI) => {
        const rawData = store[`${panelI}_${tabI}`];
        const newValue = (type === 'typedSimpleLinearObject' || type === 'simpleLinearObject') ? reshapeArrayStateToObjectData(rawData, type === 'typedSimpleLinearObject') : rawData;
        if (newValue !== undefined && newValue !== null) {
          workflow = setValueInWorkflow(
            workflow,
            newValue,
            rootWorkflowKey,
            moduleId,
            moduleConfig,
          );
        } else {
          workflow = unSetValueInWorkflow(
            workflow,
            rootWorkflowKey,
            moduleId,
            moduleConfig,
          );
        }
      });
    });
    updateWorkflowInState(workflow);
    toggleModal();
  };

  const onPanelSelect = (currentPanelIndex, selectedIndex) => {
    if (currentPanelIndex !== selectedIndex) setTabIndex(0);
    setPanelIndex(selectedIndex);
  };

  const onTabSelect = (tabNumber) => {
    setTabIndex(tabNumber);
  };

  const isTabHidden = (state, rootKey) => {
    if (state !== null) {
      if (state === 'param' && rootKey === 'properties.requestBody') return true;
      if (state === 'body' && rootKey === 'properties.requestParameters') return true;
    }
    return false;
  };

  useEffect(() => {
    const showToggle = ifOnlyReqParamsAndBodyInPanel(panels[panelIndex]);
    if (showToggle) {
      // show the one whose value is not null
      const paramValue = tempStore[`${panelIndex}_${0}`];
      if (paramValue !== null) {
        setToggleState('param');
        setTabIndex(0);
      } else {
        setToggleState('body');
        setTabIndex(1);
      }
    } else setToggleState(null);
  }, [panelIndex]);

  useEffect(() => {
    if (toggleState === 'param' || toggleState === 'body') {
      const tabToSetNull = toggleState === 'param' ? 1 : 0;
      const tabToActive = toggleState === 'param' ? 0 : 1;
      updateTempStore(null, panelIndex, tabToSetNull, tempStore);
      setTabIndex(tabToActive);
    }
  }, [toggleState]);

  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <Header
          titleText={titleText}
          saveBtnText={saveText}
          onSave={() => {
            saveData(selectedWorkflow, panels, tempStore, selectedNodeId, selectedModuleConfig);
          }}
          onClose={toggleModal}
        />

        <div className="workflow_configuration_modal__content">
          <div className="workflow_configuration_modal__sidebar">
            {
              panels.map(({ texts: panelTexts }, index) => (
                <div key={`${panelTexts.name}`}>
                  <PanelItem
                    text={panelTexts.name}
                    selected={panelIndex === index}
                    onSelect={() => { onPanelSelect(panelIndex, index); }}
                  />
                </div>
              ))
            }
          </div>
          <div className="workflow_configuration_modal__maincontent">
            <div className="workflowInput__topContent">
              <div className="workflowInput__topContent_heading">{panels[panelIndex].texts.title}</div>
              <div className="workflowInput__topContent_subheading">{panels[panelIndex].texts.description}</div>
            </div>
            <div className="workflowInput__bottomContent">
              {
              toggleState !== null ? (
                <BodyParamToggle
                  value={toggleState}
                  onChange={handleToggle}
                />
              )
                : null
            }
              <div className="options-ribbon__configurations">
                {
                  panels[panelIndex].tabs.map(({ title, rootWorkflowKey }, index) => (
                    !isTabHidden(toggleState, rootWorkflowKey) ? (
                      <TabItem
                        key={`${title}`}
                        text={title}
                        selected={tabIndex === index}
                        onSelect={() => { onTabSelect(index); }}
                      />
                    )
                      : null
                  ))
                }
              </div>
              {
              config ? (
                <EditConfiguration
                  config={panels[panelIndex].tabs[tabIndex]}
                  onChange={(data) => { updateTempStore(data, panelIndex, tabIndex, tempStore); }}
                  data={tempStore[`${panelIndex}_${tabIndex}`]}
                  canDeleteOutputVariable={
                    (variableId) => !checkDeletionDependencies({
                      variableId,
                      nodeId: selectedNodeId,
                      workflow: selectedWorkflow,
                    })
                  }
                />
              )
                : null
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfigurationModal.propTypes = {
  config: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(ConfigurationModal);
