/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { useEffect, useRef, useState } from 'react';
import kebabMenuIcon from '../../../../../assests/icons/kebabMenuIcon.svg';

function CustomInputForConditionalVar(
  { ConditionalVar, setShowFieldTypesOption, handleTextInput },
) {
  const fieldRef = useRef(null);
  const setDefaultValue = () => {
    const ruleValue = ConditionalVar === 'NONE_NONE' ? ''
      : ConditionalVar;
    return ruleValue;
  };

  const [inputValue, setInputValue] = useState(setDefaultValue());
  const [changeFieldType, setChangeFieldType] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleChangeFieldType = (e, ref) => {
    setShowFieldTypesOption(e, ref);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleTextInput(inputValue);
    }, 500);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setDefaultValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (changeFieldType)setShowFieldTypesOption(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFieldType]);

  return (
    <>
      <div
        className="condition__content__var__textBox"
        ref={fieldRef}
      >
        <input
          type="text"
          className="condition__content__var__textBox_input"
          placeholder="Enter Value"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          className="condition__content__var__textBox__more_button"
          type="button"
          onClick={(e) => handleChangeFieldType(e, fieldRef)}
        >
          <img src={kebabMenuIcon} alt="more" />
        </button>
      </div>
    </>
  );
}

export default CustomInputForConditionalVar;

CustomInputForConditionalVar.propTypes = {
  ConditionalVar: PropTypes.string.isRequired,
  setShowFieldTypesOption: PropTypes.func.isRequired,
  handleTextInput: PropTypes.func.isRequired,
};
