import findFormModuleOutputs from './findFormModuleOutputs';
import { convertV1OutputToV2Output } from './updateWorkflow';

const getModuleOutputs = (moduleSubType, moduleConfigs) => {
  const moduleOutputs =
    moduleConfigs[`${moduleSubType}_uiConfig`]?.sections?.outputs ||
    convertV1OutputToV2Output(moduleConfigs[moduleSubType]?.uiConfig?.moduleOutput);
  if (moduleOutputs) return moduleOutputs;
  return [];
};

const getSelectedModuleFromList = (modules, moduleId) => (modules || []).find(
  (module) => module.id === moduleId,
);

const getOutputsOfModule = (source, formComponentList, selectedWorkflowModules, moduleConfigs) => {
  const filteredModule = getSelectedModuleFromList(selectedWorkflowModules, source);
  const moduleSubType = filteredModule?.subType;
  if (moduleSubType === 'form') {
    return findFormModuleOutputs(filteredModule, formComponentList);
  }

  if (moduleSubType) {
    const moduleOutputs = getModuleOutputs(moduleSubType, moduleConfigs);
    // Assumption: Will always have usable variables declared in workflow
    // for every module except for countries
    if (moduleSubType === 'countries') {
      if (moduleOutputs) {
        const outputVariableNames = moduleOutputs.map((output) => ({
          id: output.key,
          name: output.displayName,
        }));
        return outputVariableNames;
      }
      return [];
    }
    // if the ui config has module outputs return it
    if (moduleOutputs && moduleOutputs.length > 0) {
      const outputVariableNames = moduleOutputs.map((output) => ({
        id: output.key,
        name: output.displayName,
      }));
      return outputVariableNames;
    }
    // else return the outputs present in the workflow
    const { variables: originalVariables } = filteredModule;
    const outputVariableNames = (originalVariables || []).map(({ name: variableKey }) => ({
      id: variableKey,
      name: variableKey,
    }));
    return outputVariableNames;
  }
  return [];
};

export default getOutputsOfModule;
