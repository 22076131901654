import { cloneDeep } from 'lodash';

const extractTrailingInt = (inputString) => {
  const pattern = /\d+$/;
  const match = inputString.trim().match(pattern);
  if (match) {
    const trailingNumber = match[0];
    const int = parseInt(trailingNumber, 10);
    return int;
  }
  return 0;
};

export const extractBaseNameAndNumber = (name) => {
  const base = (name || '').trim().replace(/\d+$/, '').trim();
  const no = extractTrailingInt(name);
  return { base, no };
};

/* eslint-disable no-param-reassign */
const updateModuleNamesInWorkflow = (workflow, workflowModules) => {
  const workflowConfig = cloneDeep(workflow);
  const idToNameMap = {};
  if (workflowConfig?.module || workflowConfig?.conditions) {
    const nameCounter = {};
    const conditions = Object.keys(workflowConfig?.conditions || {});
    (workflowConfig?.modules || []).forEach((module) => {
      if (module.name) {
        const { base, no } = extractBaseNameAndNumber(module.name);
        if (nameCounter[base] >= 0) nameCounter[base] = Math.max(nameCounter[base], no);
        else nameCounter[base] = no;
      }
    });
    conditions.forEach((conditionId) => {
      const condition = workflowConfig.conditions[conditionId];
      if (condition.name) {
        const { base, no } = extractBaseNameAndNumber(condition.name);
        if (nameCounter[base] >= 0) nameCounter[base] = Math.max(nameCounter[base], no);
        else nameCounter[base] = [no];
      }
    });
    (workflowConfig?.modules || []).forEach((module, index) => {
      let moduleName = module.id;
      if (module.name) {
        moduleName = module.name;
        idToNameMap[module.id] = moduleName;
      } else {
        const properties = workflowModules[`${module.subType}_uiConfig`];
        if (properties?.moduleName) moduleName = properties.moduleName;
        else {
          const { uiConfig } = workflowModules[module.subType] || {};
          if (uiConfig?.node?.heading) moduleName = uiConfig.node.heading;
        }
        moduleName = moduleName.trim().replace(/\d+$/, '').trim();
        if (nameCounter[moduleName] >= 0) {
          const currentCount = nameCounter[moduleName] + 1;
          idToNameMap[module.id] = `${moduleName} ${currentCount}`;
          nameCounter[moduleName] = currentCount;
        } else {
          nameCounter[moduleName] = 0;
          idToNameMap[module.id] = moduleName;
        }
      }
      workflowConfig.modules[index].name = idToNameMap[module.id];
    });
    conditions.forEach((conditionId) => {
      let conditionName = conditionId;
      const condition = workflowConfig.conditions[conditionId];
      if (condition.name) {
        conditionName = condition.name;
        idToNameMap[conditionId] = conditionName;
      } else if (nameCounter[conditionName] >= 0) {
        const currentCount = nameCounter[conditionName] + 1;
        idToNameMap[conditionId] = `${conditionName} ${currentCount}`;
        nameCounter[conditionName] = currentCount;
      } else {
        nameCounter[conditionName] = 0;
        idToNameMap[conditionId] = conditionName;
      }
      condition.name = idToNameMap[conditionId];
    });
  }
  return workflowConfig;
};

export default updateModuleNamesInWorkflow;
