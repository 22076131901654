/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

function InputsDropDownRow({
  input,
  sourceOptions,
  variableOptions,
  selectedSource,
  selectedVariable,
  setSelectedSource,
  setSelectedVariable,
  onUnSelect,
}) {
  const { sourceTitle, variableTitle } = input;
  const handleDropdownChange = (event) => {
    const { value } = event.target;
    if (value !== '') setSelectedSource(event.target.value);
    else onUnSelect();
  };

  const handleVariableNameChange = (event) => {
    setSelectedVariable(event.target.value);
  };
  return (
    <>
      <div className="inputs-dropdown-row">
        <label className="inputs-dropdown-row__title">{sourceTitle}</label>
        <select
          className="inputs-dropdown-row__options_source"
          value={selectedSource}
          onChange={handleDropdownChange}
        >
          <option value="" selected={!selectedSource}>
            {' '}
            {!selectedSource ? 'Select a Source' : 'Remove selection'}
          </option>
          {sourceOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div className="inputs-dropdown-row">
        <label className="inputs-dropdown-row__title">{variableTitle}</label>
        <select
          className="inputs-dropdown-row__options_variable"
          value={selectedVariable}
          onChange={handleVariableNameChange}
          disabled={!selectedSource}
        >
          <option value="" selected={!selectedSource} disabled={!!selectedVariable}>
            {' '}
            {!selectedSource ? 'Select a source first' : 'Select a Variable'}
          </option>
          {variableOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

InputsDropDownRow.propTypes = {
  input: PropTypes.object.isRequired,
  sourceOptions: PropTypes.array.isRequired,
  variableOptions: PropTypes.array.isRequired,
  selectedSource: PropTypes.string.isRequired,
  selectedVariable: PropTypes.string.isRequired,
  setSelectedSource: PropTypes.func.isRequired,
  setSelectedVariable: PropTypes.func.isRequired,
  onUnSelect: PropTypes.func.isRequired,
};

export default InputsDropDownRow;
