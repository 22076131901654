import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchCurrentValueFromWorkflow, getSelectedModule, setModulePropertyInWorkflow } from '../InputsToModule/utils/updateWorkflow';
import { selectSelectedNode, selectSelectedWorkflow, selectModules } from '../../../../reducers/workflow';
import { convertDurationToMillis, convertDurationInNewUnits } from '../../../../utils/helper';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import DurationInput from './DurationInput';
import './Duration.scss';

function Duration({ element, isDisabled }) {
  const { workflowKey, default: defaultValue, unit } = element;
  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];

  const getDurationValue = (workflow, moduleId, key, moduleConfig, unitOfStorage) => {
    const selectedModule = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      key,
      moduleConfig,
    );
    const valueFetchedFromWorkflow = typeof currentValue === 'number';
    const fallbackValue = typeof defaultValue === 'number' ? defaultValue : 0;
    const value = valueFetchedFromWorkflow ? currentValue : fallbackValue;
    const finalValueInMillis = convertDurationToMillis(value, unitOfStorage);
    return finalValueInMillis;
  };

  const [durationInMillis, setDuration] = useState(
    getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
      unit,
    ),
  );

  const updateValueInWorkflow = (duration) => {
    const editedWorkflow = setModulePropertyInWorkflow(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      duration,
      selectedModuleConfig,
    );
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    const currentDurationValue = getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
      unit,
    );
    setDuration(currentDurationValue);
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  useEffect(() => {
    const durationInUnits = convertDurationInNewUnits(durationInMillis, unit);
    updateValueInWorkflow(durationInUnits);
  }, [durationInMillis]);

  return (
    <DurationInput
      isDisabled={isDisabled}
      title={element.title}
      currentDurationInMillis={durationInMillis}
      onDurationChange={setDuration}
      unitsInWorkflow={unit}
    />
  );
}

Duration.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Duration;
