import { flatten, unflatten } from 'flat';
import { fetchCurrentValueFromWorkflow } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';

export const extractComponentIdsForModule = (ruleString, moduleId) => {
  // TODO: Read in an article where backslash is required if '-' is used within brackets
  // eslint-disable-next-line no-useless-escape
  const pattern = /\b[a-zA-Z_\-][a-zA-Z0-9_\-]*(?:\.[a-zA-Z_\-][a-zA-Z0-9_\-]*)+\b/g;
  const fields = `${ruleString}`.match(pattern) || [];
  const extractedFields = fields.map((field) => {
    const [module, component] = field.split('.');
    return {
      module,
      component,
    };
  });
  const filteredExtractedFileds = extractedFields.filter((field) => (field.module === moduleId));
  const filteredFiled = filteredExtractedFileds.map((field) => field.component);
  return filteredFiled;
};

export const getConditionAndModuleItems = (workflowConfig) => {
  const items = workflowConfig?.modules.filter((module) => module.type !== 'countries').map((module) => (
    {
      id: module.id,
      name: module.name || module.id,
      value: module.id,
    }
  ));
  const conditionItems = Object.entries(workflowConfig?.conditions).map(([id, condition]) => (
    {
      id,
      name: condition.name || id,
      value: id,
    }
  ));
  items.push(...conditionItems);
  return items;
};

export const replaceAll = (baseString, match, replace) => baseString.replaceAll(match, replace);

export const convertDurationToMillis = (duration, currentUnits) => {
  if (currentUnits === 'ms') return duration;
  if (currentUnits === 'secs') return duration * 1000;
  if (currentUnits === 'mins') return duration * 60 * 1000;
  if (currentUnits === 'hours') return duration * 60 * 60 * 1000;
  return duration;
};

export const convertDurationInNewUnits = (durationInMillis, newUnits) => {
  if (newUnits === 'ms') return durationInMillis;
  if (newUnits === 'secs') return durationInMillis / 1000;
  if (newUnits === 'mins') return durationInMillis / 60000;
  if (newUnits === 'hours') return durationInMillis / 3600000;
  return durationInMillis;
};

export const evaluateRuleExpression = (inputObject, ruleString) => {
  try {
    if (typeof ruleString !== 'string') return false;
    if (ruleString.trim() === 'yes') return true;
    if (ruleString.trim() === 'no') return false;
    const keys = Object.keys(inputObject);
    // TODO: Look for other alternate
    // eslint-disable-next-line no-new-func
    const ruleFunction = new Function(`{${keys}}`, `return ${ruleString}`);
    const result = ruleFunction(inputObject);
    return !!result;
  } catch (error) {
    // comes here if rule uses a variable key that is undefined
    return false;
  }
};

export const reshapeObjectDataToArrayState = (objData, shouldFlatten = false) => {
  if (objData === null) return null;
  const parsedObjData = shouldFlatten ? flatten(objData) : objData;
  const arrayState = Object.keys(parsedObjData || {}).map((key) => ({
    key,
    value: parsedObjData[key],
  }));
  return arrayState;
};

export const reshapeArrayStateToObjectData = (arrayState, shouldUnFlatten = false) => {
  if (arrayState === null) return null;
  const objData = {};
  (arrayState || []).forEach(({ key, value }) => {
    objData[key] = value;
  });
  return shouldUnFlatten ? unflatten(objData) : objData;
};

export const evaulateRuleForModule = (rule, module, moduleConfig) => {
  let updatedRule = rule.slice();

  // Replace all the variables in rule.
  const pattern = /\s*(&&|\(|\)|===|!==|!=|>=|<=|==|>|<|!|\|\|)\s*/;
  const constantsAndVariables = updatedRule.split(pattern).filter((str) => !(str.match(pattern) || str === ''));
  const isConstant = (str) => (str === 'true' || str === 'false' || str.match(/^'.*'$/) || str.match(/^\d+$/));
  const variables = constantsAndVariables.filter((str) => !isConstant(str));
  const uniqueVariables = [...new Set(variables || [])];

  const replacements = uniqueVariables.map((key) => {
    const value = fetchCurrentValueFromWorkflow(
      module,
      key,
      moduleConfig,
    );
    if (typeof value === 'string') return { key, value: `'${value}'` };
    return { key, value };
  });

  // Rule without variables
  replacements.forEach((replacement) => {
    updatedRule = updatedRule.replaceAll(replacement.key, replacement.value);
  });
  return evaluateRuleExpression({}, updatedRule);
};

export const getDefaultValueFromComponentConfigs = (workflowKey, components) => {
  const selectedComponent = (components || [])
    .find((component) => component.workflowKey === workflowKey);
  if (selectedComponent) {
    const { default: defaultValue } = selectedComponent;
    return typeof defaultValue !== 'undefined' ? defaultValue : null;
  }
  return null;
};
