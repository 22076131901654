import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import '../../../Workflow.scss';

function GenericConfirmationModal({
  titleText,
  descriptionTextArr,
  cancelBtnText,
  confirmBtnText,
  onCancelClick,
  onConfirmClick,
}) {
  return (
    <div className="add_node_overlay">
      <div className="generic_confirmation_modal">
        <div className="generic_confirmation_modal_header">
          <span>{titleText}</span>
        </div>
        <Divider />
        {(descriptionTextArr || []).map(({ id, text }) => (
          <div key={id} className="generic_confirmation_modal_content">
            <p className="generic_confirmation_modal_description">{text}</p>
          </div>
        ))}
        <Divider />
        <div className="generic_confirmation_modal_btn_container">
          <Button variant="outlined" color="primary" onClick={onCancelClick}>
            {cancelBtnText}
          </Button>
          <Button variant="contained" color="error" onClick={onConfirmClick}>
            {confirmBtnText}
          </Button>
        </div>
      </div>
    </div>
  );
}

GenericConfirmationModal.propTypes = {
  titleText: PropTypes.string.isRequired,
  descriptionTextArr: PropTypes.array.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

export default GenericConfirmationModal;
