/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { cloneDeep } from 'lodash';
import workflow, { selectModules } from '../reducers/workflow';
import updateGotoTagsInWorkflow from '../utils/updateGotoTags';
import updateModuleNamesInWorkflow from '../utils/updateModuleNamesInWorkflow';
import store from '../store';
import convertToNodesEdges from '../components/utils';
import { endStates } from '../containers/workflowOperations';

const preProcessor = (workflow, preOperations) => preOperations.reduce((accumulator, currFn) => currFn(accumulator), workflow);

const validate = (workflow, validators) => {
  let isValid = true;
  let code = null;
  validators.forEach(({ code: errorCode, fn: validator }) => {
    isValid = isValid && validator(workflow);
    if (!code && !isValid) code = errorCode;
  });
  return { isValid, code };
};

const getStateValue = () => store.getState();

const updateModuleNames = (workflow) => {
  const modules = selectModules(getStateValue());
  return updateModuleNamesInWorkflow(workflow, modules);
};

const isTerminalPresentAsNextStep = (components) => {
  if (components?.length === 0) return false;
  let isPresent = false;
  (components || []).forEach((component) => {
    if (component.onClick?.nextStep) {
      const nextStep = component.onClick?.nextStep;
      if (endStates.includes(nextStep)) isPresent = true;
    } else if (component?.subComponents?.length) {
      const subComponents = component?.subComponents || [];
      if (!isPresent) isPresent = isTerminalPresentAsNextStep(subComponents);
    }
  });
  return isPresent;
};

const terminalNotAllowedInDynamicForm = (workflow) => {
  let isVaild = true;
  (workflow?.modules || []).forEach((module) => {
    if (module.type === 'dynamicForm') {
      const { components = [] } = module.properties.sections[0];
      if (isVaild) isVaild = !isTerminalPresentAsNextStep(components);
    }
  });
  return isVaild;
};

const anyEndStateReachable = (workflow) => {
  const { isEndStateReachable } = convertToNodesEdges(workflow);
  return isEndStateReachable;
};

const validateCountryModule = (workflow) => {
  const state = store.getState();
  const { countryIdNameMapping } = state.workflow;
  const isPresent = workflow.modules?.[0].type === 'countries';
  const isNotDuplicate = (workflow.modules?.filter((module) => module.type === 'countries') || []).length === 1;
  const { countriesSupported } = workflow.modules?.[0].properties || {};
  const allowedCountries = countriesSupported?.filter((country) => Boolean(countryIdNameMapping[country]));
  const notEmpty = allowedCountries?.length >= 1 || 0;
  const allCountriesSupported = allowedCountries?.length === countriesSupported?.length;
  return isPresent && isNotDuplicate && notEmpty && allCountriesSupported;
};

export const validateWorkflow = (workflow) => {
  const validators = [
    { code: 'noEndStateReachable', fn: anyEndStateReachable },
    // Commenting the below check because its not consistent as we are allowing terminal nodes
    // as next step from other modules
    // { code: 'noTerminalsInDynamicForm', fn: terminalNotAllowedInDynamicForm },
    { code: 'countryModuleNotCorrect', fn: validateCountryModule },
  ];
  return validate(workflow, validators);
};

export const updateWorkflowInState = (workflow, isEdited = true) => {
  const preOperations = [
    updateModuleNames,
    updateGotoTagsInWorkflow,
  ];
  const operatingWorkflow = cloneDeep(workflow);
  const processedWorkflow = preProcessor(operatingWorkflow, preOperations);
  const { isValid: isValidOperation, code } = validateWorkflow(processedWorkflow);
  if (isValidOperation) {
    store.dispatch({ type: 'workflow/updateSelectedWorkflow', payload: { workflow: processedWorkflow, isEdited } });
  } else {
    alert(`Operation leading to invalid workflow\n${code || 'Something went wrong'}\nTerminated !!!`);
    return false;
  }
  return true;
};
