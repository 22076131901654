/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes, { object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import './NewConditionDrawer.scss';
import { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import Divider from '../../Divider';
import {
  selectModules,
  selectOrderOfNodes, selectSelectedNode, selectSelectedWorkflow, updateSelectedWorkflow,
} from '../../../../reducers/workflow';
import splitRule from './utils/splitRule';
import SelectRuleLeftSide from './SelectRuleLeftSide';
import SelectRuleRightSide from './SelectRuleRightSide';
import ShowInputOptions from './ShowInputOptions';
import areParenthesesBalanced from './utils/areParanthesisBalanced';
import computeCompiledRule from './utils/computeCompiledRule';
import regenerateRule from './utils/regenerateRule';
import createTextRuleMap from './utils/createTextRuleMap';
import EditConditionTitleRibbon from './EditConditionTitleRibbon';
import ConditionCompiledRuleBox from './ConditionCompiledRuleBox';
import IndividualRule from './IndividualRule';
import updateWorkflowCondition from './utils/updateWorkflowCondition';
import areAllVariablesDeclared from './utils/areParanthesisBalanced copy';
import getNextVariableName from './utils/getNextVariableName';
import ConditionalVariablePicker from '../ConditionalVariables/ConditionalVariablePicker';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import WorkflowInputPicker from '../ConditionalVariables/WorkflowInputPicker';

function EditConditionModal({
  closeModal, rule, previousModules, setRule, conditionalVariable = false, divReference = {},
}) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const screenHeight = window.innerHeight;
  const [isConditionalVariable, setIsCondVar] = useState(false);
  const selectedNodeId = useSelector(selectSelectedNode).id;
  const moduleConfigs = useSelector(selectModules);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const orderOfNodes = useSelector(selectOrderOfNodes);

  const [compiledRule, setCompiledRule] = useState('');
  const [isRuleValid, setIsRuleValid] = useState('checking');

  const [ruleMap, setRuleMap] = useState(null);
  const [textRuleMap, setTextRuleMap] = useState(null);
  const [ruleTypeMap, setRuleTypeMap] = useState(null);
  const [selectedRule, setSelectedRule] = useState('');
  const [selectedRuleKey, setSelectedRuleKey] = useState('');

  const [selectedSide, setSelectedSide] = useState('');
  const [showOptionStyle, setShowOptionStyle] = useState({});
  const [showOption, setShowOption] = useState(false);

  const [OpenLeftVariableDrawer, setOpenLeftVariableDrawer] = useState(false);
  const [OpenRightVariableDrawer, setOpenRightVariableDrawer] = useState(false);

  const [showListOfConditionalVars, setShowListOfConditionalVars] = useState(false);
  const [conditionalVariablePickerStyle, setConditionalVariablePickerStyle] = useState({});

  const [showListOfWorkflowInputs, setShowListOfWorkflowInputs] = useState(false);

  const [rulePosition, setRulePosition] = useState({});

  const getAlphabet = (index) => String.fromCharCode(65 + index);

  const updateWorkflow = (conditionRule) => {
    const editedWorkflow = updateWorkflowCondition(selectedWorkflow, selectedNodeId, conditionRule);
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    if (conditionalVariable) setIsCondVar(true);
  }, []);

  const handleRuleChange = (e, key, type) => {
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);
    ruleObject[type] = e.target.value;
    ruleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${ruleObject.right}`;
    ruleTypeMap.leftTypes[key] = ruleObject.leftType;
    ruleTypeMap.rightTypes[key] = ruleObject.rightType;
    // const newRule = regenerateRule(compiledRule, ruleMap);
    // updateWorkflow(newRule);
  };

  const handleShowOptionClick = (event, side, ruleKey, reference) => {
    ruleTypeMap[`${side}Types`][ruleKey] = 'module';
    setShowOption(true);
    setSelectedSide(side);
    const { clientY } = event;

    const { offsetLeft, offsetTop } = event.target;
    const containerRect = containerRef.current;

    // const { offsetLeft, offsetTop } = event.target;
    setSelectedRuleKey(ruleKey);
    if (side === 'right') {
      setShowOptionStyle({ right: '27.3%', top: conditionalVariable ? clientY - 40 : clientY + 20 });
    } else setShowOptionStyle({ left: conditionalVariable ? '10%' : '22.6%', top: conditionalVariable ? clientY - 40 : clientY + 20 });
  };

  const handleInputSelect = (ruleKey, side) => {
    ruleTypeMap[`${side}Types`][ruleKey] = 'custom';
    setShowOption(false);
  };

  const handleLeftVariableClick = (event, ruleKey, reference) => {
    const { offsetLeft, offsetTop, offsetWidth } = reference.current;
    const pickerHeight = 393;

    let style = {};
    if ((offsetTop + 31) + pickerHeight > screenHeight) {
      style = {
        left: offsetLeft + offsetWidth,
        bottom: 0,
      };
    } else {
      style = {
        left: offsetLeft + offsetWidth,
        top: offsetTop + 31,
      };
    }
    setRulePosition({
      ...style,
      // right: parseFloat(reference.right) + parseFloat(reference.width),
      // top: clientY,
      // top: parseFloat(reference.top),
    });

    setSelectedRule(ruleMap[ruleKey]);
    setSelectedRuleKey(ruleKey);
    setOpenLeftVariableDrawer(true);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(false);
    setSelectedSide('left');
  };

  const validateRule = () => {
    if (compiledRule && areParenthesesBalanced(compiledRule) && areAllVariablesDeclared(compiledRule, ruleMap)) {
      setIsRuleValid(true);
    } else setIsRuleValid(false);
  };

  useEffect(() => {
    validateRule();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compiledRule]);

  const handleRightVariableClick = (event, ruleKey, reference) => {
    // const { clientX, clientY } = event;
    const { offsetLeft, offsetTop, offsetWidth } = reference.current;
    const pickerHeight = 393;

    let style = {};
    if ((offsetTop + 31) + pickerHeight > screenHeight) {
      style = {
        left: offsetLeft - offsetWidth,
        bottom: 0,
      };
    } else {
      style = {
        left: offsetLeft - offsetWidth,
        top: offsetTop + 31,
      };
    }
    setRulePosition({
      ...style,
    });

    setSelectedRule(ruleMap[ruleKey]);
    ruleTypeMap.rightTypes[ruleKey] = 'module';

    setSelectedRuleKey(ruleKey);
    setOpenRightVariableDrawer(true);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(false);
    setSelectedSide('right');
  };

  const handleModuleSelect = (event, ruleKey, side, reference) => {
    if (side === 'right') {
      handleRightVariableClick(event, ruleKey, reference);
    } else handleLeftVariableClick(event, ruleKey, reference);
  };

  const handleConditionalVariableSelect = (e, ruleKey, side, divRef) => {
    const { offsetLeft, offsetTop, offsetWidth } = divRef.current;
    const pickerHeight = 191;

    let style = {};
    if ((offsetTop + 31) + pickerHeight > screenHeight) {
      style = {
        left: offsetLeft + offsetWidth,
        bottom: '0%',
      };
    } else {
      style = {
        left: offsetLeft + offsetWidth,
        top: offsetTop + 31,
      };
    }

    // setConditionalVariablePickerStyle({ ...style });
    setOpenLeftVariableDrawer(false);
    setOpenRightVariableDrawer(false);
    setShowListOfConditionalVars(true);
    setShowListOfWorkflowInputs(false);
    setSelectedRuleKey(ruleKey);
    setSelectedSide(side);
  };

  const handleWorkflowInputsSelect = (e, ruleKey, side, divRef) => {
    const { offsetLeft, offsetTop, offsetWidth } = divRef.current;
    const pickerHeight = 191;

    let style = {};
    if ((offsetTop + 31) + pickerHeight > screenHeight) {
      style = {
        left: offsetLeft + offsetWidth,
        bottom: '0%',
      };
    } else {
      style = {
        left: offsetLeft + offsetWidth,
        top: offsetTop + 31,
      };
    }

    setConditionalVariablePickerStyle({ ...style });
    setOpenLeftVariableDrawer(false);
    setOpenRightVariableDrawer(false);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(true);
    setSelectedRuleKey(ruleKey);
    setSelectedSide(side);
  };

  const setConditionalVariableValue = (value) => {
    const ruleObject = splitRule(ruleMap[selectedRuleKey], orderOfNodes);

    // update the textRuleMap,
    const textRuleObject = splitRule(textRuleMap[selectedRuleKey], orderOfNodes);
    const localTextRuleMap = cloneDeep(textRuleMap);

    // find the name of the conditional variable
    const { conditionalVariables } = selectedWorkflow;
    const name = conditionalVariables[value].name || value;
    textRuleObject[selectedSide] = `conditionalVariables.${name}`;

    // textRuleObject[selectedRuleKey]-
    localTextRuleMap[selectedRuleKey] = `${textRuleObject.left} ${textRuleObject.operator} ${textRuleObject.right}`;

    setTextRuleMap(localTextRuleMap);

    // update the ruleTypeMap
    const localRuleTypeMap = cloneDeep(ruleTypeMap);
    localRuleTypeMap[`${selectedSide}Types`][selectedRuleKey] = 'module';

    setRuleTypeMap(localRuleTypeMap);

    // update the ruleMap,
    ruleObject[selectedSide] = `conditionalVariables.${value}`;
    const localRuleMap = cloneDeep(ruleMap);
    localRuleMap[selectedRuleKey] = `${ruleObject.left} ${ruleObject.operator} ${ruleObject.right}`;
    setRuleMap(localRuleMap);
    setShowOption(false);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(false);
  };

  const setWorkflowInputValue = (value) => {
    const ruleObject = splitRule(ruleMap[selectedRuleKey], orderOfNodes);

    // update the textRuleMap,
    const textRuleObject = splitRule(textRuleMap[selectedRuleKey], orderOfNodes);
    const localTextRuleMap = cloneDeep(textRuleMap);

    // find the name of the conditional variable
    const name = value;
    textRuleObject[selectedSide] = `inputs.${name}`;

    // textRuleObject[selectedRuleKey]-
    localTextRuleMap[selectedRuleKey] = `${textRuleObject.left} ${textRuleObject.operator} ${textRuleObject.right}`;

    setTextRuleMap(localTextRuleMap);

    // update the ruleTypeMap
    const localRuleTypeMap = cloneDeep(ruleTypeMap);
    localRuleTypeMap[`${selectedSide}Types`][selectedRuleKey] = 'module';

    setRuleTypeMap(localRuleTypeMap);

    // update the ruleMap,
    ruleObject[selectedSide] = `inputs.${value}`;
    const localRuleMap = cloneDeep(ruleMap);
    localRuleMap[selectedRuleKey] = `${ruleObject.left} ${ruleObject.operator} ${ruleObject.right}`;
    setRuleMap(localRuleMap);
    setShowOption(false);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(false);
  };

  const updateLeftSideRule = (leftSide, key) => {
    const { workflowRule, type, text } = leftSide;
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);
    const localRuleMap = cloneDeep(ruleMap);
    localRuleMap[key] = `${workflowRule} ${ruleObject.operator} ${ruleObject.right}`;
    setRuleMap(localRuleMap);

    // ruleMap[key] = `${workflowRule} ${ruleObject.operator} ${ruleObject.right}`;
    ruleTypeMap.leftTypes[key] = ruleObject.leftType;

    const textRuleObject = splitRule(textRuleMap[key], orderOfNodes);
    textRuleMap[key] = `${text} ${textRuleObject.operator} ${textRuleObject.right}`;

    setShowOption(false);
    ruleTypeMap.leftTypes[key] = type;

    if (isConditionalVariable) {
      const newRule = regenerateRule(compiledRule, localRuleMap);
      setRule(newRule);
    }
  };

  const updateRightSideRule = (rightSide, key) => {
    const { workflowRule, type, text } = rightSide;
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);

    const localRuleMap = cloneDeep(ruleMap);
    localRuleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${workflowRule}`;
    setRuleMap(localRuleMap);

    // ruleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${workflowRule}`;
    ruleTypeMap.rightTypes[key] = ruleObject.rightType;

    const textRuleObject = splitRule(textRuleMap[key], orderOfNodes);
    textRuleMap[key] = `${textRuleObject.left} ${textRuleObject.operator} ${text}`;

    setShowOption(false);
    ruleTypeMap.rightTypes[key] = type;

    if (isConditionalVariable) {
      const newRule = regenerateRule(compiledRule, localRuleMap);
      setRule(newRule);
    }
  };
  const handleOperatorChange = (value, key) => {
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);

    const localRuleMap = cloneDeep(ruleMap);
    localRuleMap[key] = `${ruleObject.left} ${value} ${ruleObject.right}`;
    setRuleMap(localRuleMap);
  };

  const handleRuleTextInput = (value, key, side) => {
    const localRuleMap = cloneDeep(ruleMap);
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);
    if (side === 'right') {
      localRuleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${value === '' ? 'NONE_NONE' : value}`;
      // ruleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${value === '' ? 'NONE_NONE' : value}`;
    } else {
      localRuleMap[key] = `${value === '' ? 'NONE_NONE' : value} ${ruleObject.operator} ${ruleObject.right}`;
      // ruleMap[key] = `${value === '' ? 'NONE_NONE' : value} ${ruleObject.operator} ${ruleObject.right}`;
    }
    setRuleMap(localRuleMap);

    if (isConditionalVariable) {
      const newRule = regenerateRule(compiledRule, localRuleMap);
      setRule(newRule);
    }
  };

  const getUpdatedLocalRuleTypeMap = (map) => {
    const localRuleTypeMap = {
      rightTypes: {},
      leftTypes: {},
    };
    Object.keys(map).forEach((key) => {
      const ruleObject = splitRule(map[key], orderOfNodes);
      localRuleTypeMap.rightTypes[key] = ruleObject.rightType;
      localRuleTypeMap.leftTypes[key] = ruleObject.leftType;
    });
    return localRuleTypeMap;
  };

  const addNewRule = () => {
    const nextAlphabet = getNextVariableName(Object.keys(ruleMap));
    const updatedRuleMap = { ...ruleMap, [nextAlphabet]: 'NONE_NONE == NONE_NONE' };
    setRuleMap(updatedRuleMap);
    const updatedRuleTypeMap = getUpdatedLocalRuleTypeMap(updatedRuleMap);
    setRuleTypeMap(updatedRuleTypeMap);
    const localTextRuleMap = createTextRuleMap(updatedRuleMap, selectedWorkflow, moduleConfigs, orderOfNodes);
    setTextRuleMap(localTextRuleMap);
    setShowOption(false);
    setCompiledRule(`${compiledRule} && ${nextAlphabet} `);
  };

  const closeVariableDrawer = () => {
    setOpenLeftVariableDrawer(false);
    setOpenRightVariableDrawer(false);
    setShowListOfConditionalVars(false);
    setShowListOfWorkflowInputs(false);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleDelete = (key) => {
    // remove from ruleMap, textRuleMap, ruleTypeMap and compiled Rule
    const updatedRuleMap = { ...ruleMap };
    delete updatedRuleMap[key];
    setRuleMap(updatedRuleMap);

    const updatedTextRuleMap = { ...textRuleMap };
    delete updatedTextRuleMap[key];
    setTextRuleMap(updatedTextRuleMap);

    const updatedRuleTypeMap = {
      rightTypes: { ...(ruleTypeMap?.rightTypes || {}) },
      leftTypes: { ...(ruleTypeMap?.leftTypes || {}) },
    };
    delete updatedRuleTypeMap.rightTypes[key];
    delete updatedRuleTypeMap.leftTypes[key];
    setRuleTypeMap(updatedRuleTypeMap);

    // Removing character and associated parentheses
    let temp = compiledRule.replace(new RegExp(`\\(${key}\\)`, 'g'), '');

    // Removing operators preceded or succeeded by the character
    temp = temp.replace(new RegExp(`\\s*${key}\\s*([&|]{2})`, 'g'), '');
    temp = temp.replace(new RegExp(`([&|]{2})\\s*${key}\\s*`, 'g'), '');

    setCompiledRule(temp.trim());
  };

  const handleSaveCondition = () => {
    if (isRuleValid) {
      const newRule = regenerateRule(compiledRule, ruleMap);
      setRule(newRule);
      closeModal();
    } else {
      // eslint-disable-next-line no-alert
      alert('please double check your rule');
    }
  };

  useEffect(() => {
    if (isConditionalVariable) {
      const newRule = regenerateRule(compiledRule, ruleMap);
      setRule(newRule);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleMap, textRuleMap]);

  // generate compiled rule, ruleMap and ruleTypeMap
  useEffect(() => {
    const { injectedRule, injectedRuleMap } = computeCompiledRule(rule, getAlphabet);
    setCompiledRule(injectedRule);
    setRuleMap(injectedRuleMap);
    const updatedRuleTypeMap = getUpdatedLocalRuleTypeMap(injectedRuleMap);
    setRuleTypeMap(updatedRuleTypeMap);
    const localTextRuleMap = createTextRuleMap(injectedRuleMap, selectedWorkflow, moduleConfigs, orderOfNodes);
    setTextRuleMap(localTextRuleMap);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkflow, rule]);

  return (
    <>
      {
      compiledRule && ruleMap && textRuleMap && (
      <div className={!conditionalVariable ? 'condition' : ''}>
        <div className={!conditionalVariable ? 'condition__content' : ''}>
          {!conditionalVariable && (
          <>
            <EditConditionTitleRibbon
              handleClose={handleCloseModal}
              handleSave={handleSaveCondition}
            />
            <Divider
              width={100}
              leftMargin={null}
              topMargin="4px"
            />
          </>
          )}
          <div className={!conditionalVariable ? 'condition__content__body' : 'condition-var__content__body'}>
            <ConditionCompiledRuleBox
              compiledRule={compiledRule}
              isRuleValid={isRuleValid}
              setCompiledRule={setCompiledRule}
              conditionalVariable={conditionalVariable}
            />
            <div className={!conditionalVariable ? 'condition__content__rules' : 'conditional__content__rules'} ref={containerRef}>
              {textRuleMap && ruleTypeMap && Object.keys(textRuleMap)
             && (Object.keys(textRuleMap).map((ruleKey) => (
               <>
                 <IndividualRule
                   ruleKey={ruleKey}
                   ruleMap={ruleMap}
                   selectedRuleKey={selectedRuleKey}
                   selectedSide={selectedSide}
                   ruleTypeMap={ruleTypeMap}
                   rule={splitRule(ruleMap[ruleKey], orderOfNodes)}
                   textRule={splitRule(textRuleMap[ruleKey], orderOfNodes)}
                   setShowFieldTypesOption={handleShowOptionClick}
                   showOption={showOption}
                   handleRuleTextInput={handleRuleTextInput}
                   handleDelete={handleDelete}
                   isConditionalVar={!conditionalVariable}
                   handleOperatorChange={handleOperatorChange}
                 />
               </>
             )))}

              <div
                className="condition__content__rules__add"
                onClick={addNewRule}
              >
                + Add

              </div>
            </div>
          </div>
        </div>
        {OpenLeftVariableDrawer
      && (
      <SelectRuleLeftSide
        side="left"
        posRef={rulePosition}
        selectedRule={selectedRule}
        selectedRuleKey={selectedRuleKey}
        closeVariableModal={(val) => setOpenLeftVariableDrawer(!val)}
        previousModules={previousModules}
        selectedWorkflow={selectedWorkflow}
        updateLeftSideRule={updateLeftSideRule}
        setShowOption={setShowOption}
      />
      )}
        {OpenRightVariableDrawer
      && (
      <SelectRuleRightSide
        side="right"
        posRef={rulePosition}
        selectedRule={selectedRule}
        selectedRuleKey={selectedRuleKey}
        closeVariableModal={(val) => setOpenRightVariableDrawer(!val)}
        previousModules={previousModules}
        selectedWorkflow={selectedWorkflow}
        updateRightSideRule={updateRightSideRule}
        setShowOption={setShowOption}
      />
      )}
        {showOption
       && (
       <ShowInputOptions
         side={selectedSide}
         handleModuleSelect={handleModuleSelect}
         handleInputSelect={handleInputSelect}
         selectedRuleKey={selectedRuleKey}
         showOptionStyle={showOptionStyle}
         showOption={showOption}
         setShowOption={setShowOption}
         closeVariableDrawer={closeVariableDrawer}
         handleCondVarSelect={handleConditionalVariableSelect}
         isConditionalVariable={conditionalVariable}
         handleWorkflowInputSelect={handleWorkflowInputsSelect}
       />
       )}
        {
        showListOfConditionalVars && (
          <ConditionalVariablePicker
            setValue={setConditionalVariableValue}
            style={conditionalVariablePickerStyle}
          />
        )
       }
        {
        showListOfWorkflowInputs && (
          <WorkflowInputPicker
            setValue={setWorkflowInputValue}
            style={conditionalVariablePickerStyle}
          />
        )
       }
      </div>
      )
}
    </>
  );
}

export default EditConditionModal;

EditConditionModal.propTypes = {
  closeModal: PropTypes.func,
  divReference: PropTypes.instanceOf(object),
  rule: PropTypes.string.isRequired,
  setRule: PropTypes.func.isRequired,
  previousModules: PropTypes.array.isRequired,
  conditionalVariable: PropTypes.bool,
};
