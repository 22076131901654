import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../utils/useDebounce';

function ShowConditionDrawer({ defaultValue, onChange }) {
  const prevValue = useRef(defaultValue || '');
  const [value, setValue] = useState(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onChange(debouncedValue);
    prevValue.current = debouncedValue;
  }, [debouncedValue]);

  const handleClick = () => {
    setValue('');
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
  };

  const addCondition = !!(value !== 'yes' && value !== 'no');

  return (
    <div>
      {!addCondition && (
        <button
          className="edit-settings-div__condition_button"
          type="button"
          onClick={handleClick}
        >
          + add condition
        </button>
      )}
      {addCondition && (
        <input
          type="text"
          className="edit-settings-div__condition_input"
          value={value}
          onChange={handleTextChange}
        />
      )}
    </div>
  );
}

ShowConditionDrawer.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ShowConditionDrawer;
