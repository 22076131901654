import Joi from 'joi-browser';

export const isValidTypedData = (data) => {
  const schema = Joi.array().items(
    Joi.object({
      key: Joi.string().required().allow(''),
      value: Joi.alternatives().try(
        Joi.number(),
        Joi.boolean(),
        Joi.string().required().allow(''),
      ),
    }),
  );
  const { error } = schema.validate(data);
  return error === null;
};

export const isValidUnTypedData = (data) => {
  const schema = Joi.array().items(
    Joi.object({
      key: Joi.string().required().allow(''),
      value: Joi.string().required().allow(''),
    }),
  );
  const { error } = schema.validate(data);
  return error === null;
};
