import {
  cloneDeep,
  get,
  set,
  unset,
} from 'lodash';
import { logPropertyUnsetForModule, logPropertyUpdateForModule } from '../../../../../logger/logHighLevelWorkflowUpdates';

const updateNestedValue = (obj, path, value) => set(obj, path, value);
const isReqParameter = (workflowKey) => workflowKey.startsWith('requestParameters.');
const isSuperModuleProp = (workflowKey) => workflowKey.includes('[+]');
const isSuperModuleBuilderProp = (workflowKey) => workflowKey.includes('builderProperties[-]');
const isRequestParamCombination = (workflowKey) => workflowKey === 'requestParametersCombinations';

const searchAndUpdateRequestParam = (params, key, value) => {
  const clonnedParams = params ? cloneDeep(params) : [];
  const indexToBeUpdated = clonnedParams.findIndex((param) => param.name === key);
  if (indexToBeUpdated === -1) clonnedParams.push(value);
  else clonnedParams[indexToBeUpdated] = value;
  return clonnedParams;
};

const deleteRequestParam = (params, key) => params.filter((param) => param.name !== key);

const isParamPartOfRequestParamCombination = (workflowKey, selectedModuleConfig) => {
  if (!selectedModuleConfig?.properties?.requestParametersCombinations) return false;
  const requestParametersCombinations =
   selectedModuleConfig?.properties?.requestParametersCombinations || {};
  const paramKeys = Object.values(requestParametersCombinations || {}).flat();
  return paramKeys.includes(workflowKey);
};

const getReqParamKeyFromWorkflowKey = (workflowKey) => {
  const [, ...rest] = workflowKey.split('.');
  return rest.join('.');
};

const getParamDetails = (workflowKey, selectedModuleConfig) => {
  if (isReqParameter(workflowKey)) {
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    return { isRequestParam: true, key };
  }
  const isParamPartOfRequestParameterCombination =
   isParamPartOfRequestParamCombination(workflowKey, selectedModuleConfig);
  if (isParamPartOfRequestParameterCombination) return { isRequestParam: true, key: workflowKey };
  return { isRequestParameter: false, key: workflowKey };
};

// Checks if all the elements of array1 are present in array2
const containsAll = (array1, array2) => array1.every((element) => array2.includes(element));

const getValueByPath = (obj, path) => get(obj, path);

const unsetNestedValue = (obj, path) => unset(obj, path);

const getParamFromList =
 (paramName, paramList) => (paramList || []).find((param) => param.name === paramName);

const splitStringOnFirstOccurrence = (inputString, delimiter) => {
  const [firstPart, ...remainingParts] = inputString.split(delimiter);
  return [firstPart, remainingParts.join(delimiter)];
};

export const getSourceAndVariable = (value) => {
  if (typeof value === 'string') {
    const [first, second] = splitStringOnFirstOccurrence(value, '.');
    return {
      selectedSource: first,
      selectedVariable: second,
    };
  }
  return {
    selectedSource: '',
    selectedVariable: '',
  };
};

export const getSelectedValueFromModuleInputs = (
  selectedSource,
  selectedVariable,
  selectedWorkflowModules,
) => {
  let finalValue = null;
  const isModuleVariable =
   selectedWorkflowModules.findIndex((mod) => mod.id === selectedSource) !== -1;
  const isConditionalVariable = selectedSource === 'conditionalVariables';
  const isInput = selectedSource === 'inputs';
  if (isConditionalVariable || isModuleVariable || isInput) {
    // conditional variable or module output
    finalValue = `${selectedSource}.${selectedVariable}`;
  }
  return finalValue;
};

export const prepareInputForWorkflowModification = (
  inputValue,
  selectedModuleConfig,
  workflowKey,
) => {
  const { isRequestParam, key } = getParamDetails(
    workflowKey,
    selectedModuleConfig,
  );
  if (isRequestParam) {
    const defaultRequestParameter = selectedModuleConfig?.properties?.requestParameters?.filter(
      (param) => param.name === key,
    )?.[0] || {};
    const { name, type } = defaultRequestParameter;
    return {
      name,
      type,
      value: inputValue,
    };
  }
  return inputValue;
};

export const fetchCurrentValueFromWorkflow = (
  selectedModule,
  workflowKey,
  selectedModuleConfig,
) => {
  if (!selectedModule) return null;
  const isRequestParameterCombination = isRequestParamCombination(workflowKey);
  const isParamPartOfRequestParameterCombination =
   isParamPartOfRequestParamCombination(workflowKey, selectedModuleConfig);
  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  const isBuilderProp = isSuperModuleBuilderProp(workflowKey);

  if (isBuilderProp) {
    const [, propertyName] = workflowKey.split('builderProperties[-]');
    return selectedModule.builderProperties[propertyName];
  } if (isSuperModuleProperty) {
    return selectedModule.properties[workflowKey];
  } if (isRequestParameterCombination) {
    const { requestParameters } = selectedModule.properties;
    const currentRequestParameters = (requestParameters || []).map((param) => param.name);
    const optionValues = selectedModuleConfig.values || [];
    // By default return the first combination
    let currentValue = optionValues?.[0];
    optionValues.forEach((params) => {
      if (containsAll(params, currentRequestParameters)) currentValue = params;
    });
    return currentValue;
  } if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    const paramFromWorkflow = getParamFromList(key, selectedModule.properties.requestParameters);
    return paramFromWorkflow?.value;
  } if (isParamPartOfRequestParameterCombination) {
    const key = workflowKey;
    const paramFromWorkflow = getParamFromList(key, selectedModule.properties.requestParameters);
    return paramFromWorkflow?.value;
  }
  // accessing normal object properties workflowKey: a.b.c
  return getValueByPath(selectedModule.properties, workflowKey);
};

export const getCurrentValueFromWorkflowForModuleInputs = (
  selectedModule,
  workflowKey,
  selectedModuleConfig,
) => {
  const currentValue = fetchCurrentValueFromWorkflow(
    selectedModule,
    workflowKey,

    selectedModuleConfig,
  ) || null;
  return getSourceAndVariable(currentValue);
};

export const getCurrentValueFromWorkflowForSingleSelectDropDown = (
  selectedModule,
  workflowKey,
  selectedModuleConfig,
) => {
  const currentValue = fetchCurrentValueFromWorkflow(
    selectedModule,
    workflowKey,
    selectedModuleConfig,
  ) || null;
  return currentValue;
};

export const getSelectedModule = (workflow, moduleId) => (workflow?.modules || []).find(
  (module) => module.id === moduleId,
);

export const fetchCurrentValueFromWorkflowConfig = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  selectedModuleConfig,
) => {
  const selectedModule = getSelectedModule(selectedWorkflow, selectedModuleId);
  if (!selectedModule) return null;
  return fetchCurrentValueFromWorkflow(selectedModule, workflowKey, selectedModuleConfig);
};

export const setDefaultParams = (
  requestParamCombination,
  selectedModuleConfig,
  selectedWorkflow,
  selectedModuleId,
) => {
  const defaultRequestParams = selectedModuleConfig?.properties?.requestParameters;
  const clonnedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex =
  clonnedWorkflow.modules.findIndex((module) => module.id === selectedModuleId);

  if (selectedModuleIndex === -1) return clonnedWorkflow;

  const existingRequestParams = clonnedWorkflow.modules[selectedModuleIndex]
    ?.properties?.requestParameters;
  const newRequestParams = requestParamCombination.map((paramName) => {
    const existingParamFromWorkflow = getParamFromList(paramName, existingRequestParams);
    if (existingParamFromWorkflow) return existingParamFromWorkflow;
    const defaultParam = getParamFromList(paramName, defaultRequestParams);
    return defaultParam;
  });

  const updatedParams = newRequestParams.filter((param) => param);
  clonnedWorkflow.modules[selectedModuleIndex].properties.requestParameters = updatedParams;

  return clonnedWorkflow;
};

export const setModuleProperty = (
  workflowKey,
  inputValue,
  properties,
  moduleConfig,
) => {
  const value = prepareInputForWorkflowModification(
    inputValue,
    moduleConfig,
    workflowKey,
  );
  let editedProperties = cloneDeep(properties);
  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  const isParamPartOfRequestParameterCombination =
   isParamPartOfRequestParamCombination(workflowKey, moduleConfig);
  if (isSuperModuleProperty) {
    editedProperties[workflowKey] = value;
  } else if (isParamPartOfRequestParameterCombination) {
    // Assumption: if param is part of requestParamCombinations workflowKey is the attribute name
    const paramsFromWorkflow = editedProperties.requestParameters;
    const newRequestParams = searchAndUpdateRequestParam(paramsFromWorkflow, workflowKey, value);
    editedProperties.requestParameters = newRequestParams;
  } else if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    if (key) {
      const paramsFromWorkflow = editedProperties.requestParameters;
      const newRequestParams = searchAndUpdateRequestParam(paramsFromWorkflow, key, value);
      editedProperties.requestParameters = newRequestParams;
    }
  } else {
    // generic object case
    editedProperties = updateNestedValue(
      editedProperties,
      workflowKey,
      value,
    );
  }
  return editedProperties;
};

export const setBuilderProperty = (
  workflowKey,
  inputValue,
  builderProperties,
  moduleConfig,
) => {
  const value = prepareInputForWorkflowModification(
    inputValue,
    moduleConfig,
    workflowKey,
  );
  const editedBuilderProperties = cloneDeep(builderProperties);
  const [, propertyName] = workflowKey.split('builderProperties[-]');
  updateNestedValue(editedBuilderProperties, propertyName, value);
  return editedBuilderProperties;
};

const logModuleUpdates = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  value,
  selectedModuleConfig,
) => {
  try {
    const currentValue = fetchCurrentValueFromWorkflowConfig(
      selectedWorkflow,
      selectedModuleId,
      workflowKey,
      selectedModuleConfig,
    );
    if (value === null) {
      logPropertyUnsetForModule({
        id: selectedModuleId,
        workflowKey,
        oldValue: currentValue,
      });
    } else {
      logPropertyUpdateForModule({
        id: selectedModuleId,
        workflowKey,
        oldValue: currentValue,
        newValue: value,
      });
    }
  } catch (err) {
    // TODO: Handle the error
    // console.log(err);
  }
};

export const setModulePropertyInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  value,
  selectedModuleConfig,
) => {
  logModuleUpdates(
    selectedWorkflow,
    selectedModuleId,
    workflowKey,
    value,
    selectedModuleConfig,
  );
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;
  if (isSuperModuleBuilderProp(workflowKey)) {
    const editedBuilderProperties = setBuilderProperty(
      workflowKey,
      value,
      editedWorkflow.modules[selectedModuleIndex].builderProperties,
      selectedModuleConfig,
    );
    editedWorkflow.modules[selectedModuleIndex].builderProperties = editedBuilderProperties;
  } else {
    const editedProperties = setModuleProperty(
      workflowKey,
      value,
      editedWorkflow.modules[selectedModuleIndex].properties,
      selectedModuleConfig,
    );
    editedWorkflow.modules[selectedModuleIndex].properties = editedProperties;
  }
  return editedWorkflow;
};

export const setModuleVariablesInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  value,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;
  editedWorkflow.modules[selectedModuleIndex].variables = value;
  return editedWorkflow;
};

export const unsetModulePropertyInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  selectedModuleConfig,
) => {
  logModuleUpdates(
    selectedWorkflow,
    selectedModuleId,
    workflowKey,
    null,
    selectedModuleConfig,
  );
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;

  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  const isParamPartOfRequestParameterCombination =
   isParamPartOfRequestParamCombination(workflowKey, selectedModuleConfig);

  if (isSuperModuleProperty) {
    delete editedWorkflow.modules[selectedModuleIndex].properties[workflowKey];
  } else if (isParamPartOfRequestParameterCombination) {
    // Assumption: if param is part of requestParamCombinations workflowKey is the attribute name
    const paramsFromWorkflow =
            editedWorkflow.modules[selectedModuleIndex].properties.requestParameters;
    const newRequestParams = deleteRequestParam(paramsFromWorkflow, workflowKey);
    editedWorkflow.modules[selectedModuleIndex].properties.requestParameters = newRequestParams;
  } else if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    if (key) {
      const paramsFromWorkflow =
            editedWorkflow.modules[selectedModuleIndex].properties.requestParameters;
      const newRequestParams = deleteRequestParam(paramsFromWorkflow, key);
      editedWorkflow.modules[selectedModuleIndex].properties.requestParameters = newRequestParams;
    }
  } else {
    // generic object case
    unsetNestedValue(
      editedWorkflow.modules[selectedModuleIndex].properties,
      workflowKey,
    );
  }
  return editedWorkflow;
};

export const convertV1OutputToV2Output = (v1Output = {}) => Object.entries(
  v1Output,
).map(([keyName, { name, description }]) => ({
  type: 'outputItem',
  displayName: name,
  description,
  key: keyName,
}));
