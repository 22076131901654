import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import conditionDeleteIcon from '../../../../assests/icons/conditionDeleteIcon.svg';

function ModuleVariable({
  name: defaultName,
  path: defaultPath,
  onNameChange,
  onPathChange,
  onDelete,
  nameTitle,
  pathTitle,
}) {
  const handleNameChange = (event) => {
    const { value } = event.target;
    onNameChange(value);
  };

  const handlePathChange = (event) => {
    const { value } = event.target;
    onPathChange(value);
  };

  return (
    <div className="workflow_output_field">
      <TextField
        label={nameTitle}
        variant="outlined"
        value={defaultName}
        onChange={handleNameChange}
        error={false}
        helperText=""
        size="small"
        className="workflow_output_field_textfield"
      />
      <TextField
        label={pathTitle}
        variant="outlined"
        value={defaultPath}
        onChange={handlePathChange}
        error={false}
        helperText=""
        placeholder="response.a.b"
        size="small"
        className="workflow_output_field_textfield"
      />
      <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
        <img
          src={conditionDeleteIcon}
          alt="delete"
        />
      </button>
    </div>
  );
}

ModuleVariable.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  nameTitle: PropTypes.string.isRequired,
  pathTitle: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPathChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ModuleVariable;
