// import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectSelectedWorkflow } from '../reducers/workflow';
import './Workflow.scss';
import closeButton from '../assests/icons/closeButton.svg';
import addIcon from '../assests/icons/addIcon.svg';
import decline from '../assests/icons/decline.svg';
import review from '../assests/icons/review.svg';
import approve from '../assests/icons/approve.svg';
import goto from '../assests/icons/gotoIcon.svg';

function EditNodeModal({ onClose, onEditEndState }) {
  const [endStateNodes, setEndStateNodes] = useState([]);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const workflow = useSelector(selectSelectedWorkflow);

  useEffect(() => {
    // Event listener for clicks outside the modal
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.add_node_modal')) {
        onClose();
      }
    };
    // Event listener for the escape key press
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    // Add event listeners when the modal is open
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const replaceEndNodeBy = (nodeHeading) => {
    if (nodeHeading === 'Auto Approve') {
      onEditEndState('approve', workflow);
    }
    if (nodeHeading === 'Auto Decline') {
      onEditEndState('decline', workflow);
    }
    if (nodeHeading === 'Send to Review') {
      onEditEndState('manualReview', workflow);
    }
    if (nodeHeading === 'Go-To') {
      onEditEndState('goto', workflow);
    }
  };

  useState(() => {
    const endStates = [];
    Object.keys(workflowModules).forEach((module) => {
      if (workflowModules[module].nodeDisplayType === 'output') { endStates.push(workflowModules[module]); }
    });
    const gotoNode = {
      nodeDisplayType: 'output',
      uiConfig: {
        node: {
          heading: 'Go-To',
          subHeading: 'This will link to another existing node',
        },
      },
    };
    endStates.push(gotoNode);
    setEndStateNodes(endStates);
  }, []);

  const getIcon = (nodeHeading) => {
    if (nodeHeading === 'Auto Approve') {
      return (
        <div className="modal_node__icon">
          <img className="modal_node__icon__image" src={approve} alt="icon" />
        </div>
      );
    }
    if (nodeHeading === 'Auto Decline') {
      return (
        <div className="modal_node__icon">
          <img className="modal_node_icon__image" src={decline} alt="icon" />
        </div>
      );
    }
    if (nodeHeading === 'Send to Review') {
      return (
        <div className="modal_node__icon">
          <img className="modal_node_icon__image" src={review} alt="icon" />
        </div>
      );
    }
    if (nodeHeading === 'Go-To') {
      return (
        <div className="modal_node__icon">
          <img className="modal_node_icon__image" src={goto} alt="icon" />
        </div>
      );
    }
    return (
      <div className="modal_node__emptyIcon" />
    );
  };

  const displayEndStates = () => (
    <>
      <div className="modal_module_type_heading">END STATES</div>
      {
        endStateNodes.map((state) => (
          <div key={state.uiConfig.node.heading} className="modal_node">
            {
              getIcon(state.uiConfig.node.heading)
            }
            <div className="modal_node__content">
              <div className="modal_node__content__heading">{state.uiConfig.node.heading}</div>
              <div className="modal_node__content__subheading">{state.uiConfig.node.subHeading}</div>
            </div>
            <button type="button" className="modal_node__addButton" onClick={() => replaceEndNodeBy(state.uiConfig.node.heading)}>
              <img
                src={addIcon}
                alt="close"
              />
            </button>
          </div>
        ))
      }
    </>
  );

  return (
    <div className="add_node_overlay">
      <div className="add_node_modal">
        <div className="add_node_modal_header">
          <span>
            Edit End State
          </span>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>
        <input className="add_node_modal_searchbox" type="text" placeholder="Search for an end state" />
        <div className="add_node_modal_content">
          {endStateNodes.length ? displayEndStates() : null}
        </div>
      </div>
    </div>
  );
}

EditNodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEditEndState: PropTypes.func.isRequired,
};

export default EditNodeModal;
