const findConditionalVariablesToDisplay = (conditionalVariables) => {
  if (!conditionalVariables) return [];

  const listOfConditionalVariables = Object.keys(conditionalVariables);

  const parentIds = listOfConditionalVariables.map((condVar) => {
    if (conditionalVariables[condVar]?.parent) {
      return conditionalVariables[condVar].parent;
    } return condVar;
  });

  const listToDisplay = { };
  parentIds.forEach((key) => {
    listToDisplay[key] = conditionalVariables[key];
  });

  return listToDisplay;
};

export default findConditionalVariablesToDisplay;
