/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import PropTypes, { object } from 'prop-types';
import './ConditionalVariable.scss';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Button } from '@mui/base';
import CollapsibleDiv from '../../../Common/CollapsibleDiv';
import EditConditionModal from '../NewConditionDrawer/EditConditionModal';
import { findModules } from '../../../FormModule/utils';
import Divider from '../../Divider';
import { selectModules, selectOrderOfNodes, selectSelectedWorkflow } from '../../../../reducers/workflow';
import ConditionalVariableName from './ConditionalVariableName';
import ConditionalVariableValue from './ConditionalVariableValue/ConditionalVariableValue';

function IndividualConditionalVariable({
  updateVariableValue, parentId, parentConditionalVar,
  updateChildrenConditionalVar, updateParentCondition, updateParentConditionResult,
}) {
  const containerRef = useRef(null);
  const nodesAndConditions = useSelector(selectOrderOfNodes);
  const moduleConfigs = useSelector(selectModules);
  const [childrenConditionalVars, setChildrenConditionalVars] = useState([]);

  const [addRule, setAddRule] = useState(false);
  const [modules, setModules] = useState(findModules(nodesAndConditions));

  const selectedWorkflow = useSelector(selectSelectedWorkflow);

  useEffect(() => {
    const allModuleConfigs = [];

    selectedWorkflow.modules.forEach((module) => {
      const config = cloneDeep(moduleConfigs[`${module.subType}_uiConfig`] || moduleConfigs[module.subType]);
      config.moduleId = module.id;
      allModuleConfigs.push(config);
    });

    setModules(allModuleConfigs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateChildrenConditionalVar(childrenConditionalVars);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenConditionalVars]);

  const addChildConditionalVar = () => {
    const localChildConditionalVar = cloneDeep(childrenConditionalVars);
    localChildConditionalVar.push({
      parent: parentId,
      rule: '',
      if_true: '',
      if_false: '',
    });

    setChildrenConditionalVars(localChildConditionalVar);
  };

  const handleAddRule = () => {
    setAddRule(true);
  };

  const handleRuleChange = (rule, index) => {
    if (index >= 0) {
      const localChildren = cloneDeep(childrenConditionalVars);
      localChildren[index].rule = rule;
      setChildrenConditionalVars(localChildren);
    } else {
      updateParentCondition(rule);
    }
  };

  const updateConditionalVariableValue = (value, type) => {
    const localChildren = cloneDeep(childrenConditionalVars);

    if (type === 'parentTrue') {
      updateParentConditionResult(value, 'if_true');
    } else if (type === 'childIfFalse') {
      if (localChildren.length > 0) {
        localChildren[localChildren.length - 1].if_true = value;
        setChildrenConditionalVars(localChildren);
      } else {
        updateParentConditionResult(value, 'if_false');
      }
    } else {
      localChildren[type].if_true = value;
      setChildrenConditionalVars(localChildren);
    }
  };

  const deleteChild = (index) => {
    const localChildren = cloneDeep(childrenConditionalVars);
    localChildren.splice(index, 1);

    setChildrenConditionalVars(localChildren);
  };

  return (
    <>
      <div
        className="individual_conditional_var_div"
        ref={containerRef}
      >
        <CollapsibleDiv
          title="If"
          showDelete={false}
        >
          <div>
            {!addRule && (
            <button
              className="add-rule"
              type="button"
              onClick={handleAddRule}

            >
              + Add Rule
            </button>
            )}
            {addRule && (
            <EditConditionModal
              rule="NONE_NONE == NONE_NONE"
              previousModules={modules}
              setRule={(rule) => handleRuleChange(rule)}
              conditionalVariable
              divRef={containerRef}
            />
            )}
            <Divider
              bottomMargin="12px"
              width="110%"
              leftMargin="-10px"
            />

            <div className="conditional-var_value">
              <span className="is_true">
                is True
                {' '}
              </span>
              <span className="then">
                then,
                {' '}
                {' '}
              </span>
              <span className="conditionalVar__name__value">
                {parentConditionalVar.name || 'conditionalVariable'}
              </span>
              <span className="then">
                is,
                {' '}
                {' '}
              </span>
              <ConditionalVariableValue
                modules={modules}
                containerRef={containerRef}
                updateConditionalVariableValue={(value) => updateConditionalVariableValue(value, 'parentTrue')}
              />
            </div>

          </div>
        </CollapsibleDiv>
        {
          childrenConditionalVars && (childrenConditionalVars.map((conditionalVar, index) => (
            <div key={index}>
              <CollapsibleDiv
                title="Else if"
                showDelete
                handleDelete={() => deleteChild(index)}
              >
                <div>
                  {!addRule && (
                  <button
                    className="add-rule"
                    type="button"
                    onClick={handleAddRule}
                  >
                    + Add Rule
                  </button>
                  )}
                  {addRule && (
                  <EditConditionModal
                    rule="NONE_NONE == NONE_NONE"
                    previousModules={modules}
                    setRule={(rule) => handleRuleChange(rule, index)}
                    conditionalVariable
                    divRef={containerRef}
                  />
                  )}
                  <Divider
                    bottomMargin="12px"
                    width="110%"
                    leftMargin="-10px"
                  />

                  <div className="conditional-var_value">
                    <span className="is_true">
                      is True
                      {' '}
                    </span>
                    <span className="then">
                      then,
                      {' '}
                      {' '}
                    </span>
                    <span className="conditionalVar__name__value">
                      {parentConditionalVar.name || 'conditionalVariable'}
                    </span>
                    <span className="then">
                      is,
                      {' '}
                      {' '}
                    </span>
                    <ConditionalVariableValue
                      modules={modules}
                      containerRef={containerRef}
                      updateConditionalVariableValue={
                        (value) => updateConditionalVariableValue(value, index)
}
                    />
                  </div>

                </div>
              </CollapsibleDiv>
            </div>
          )))
        }
        <Button
          className="add-else-if"
          onClick={addChildConditionalVar}
        >
          Add Else if
        </Button>
        <CollapsibleDiv
          title="Else"
          showDelete={false}
        >
          <div className="conditional-var_value">
            <span className="conditionalVar__name__value">
              {parentConditionalVar.name || 'conditionalVariable'}
            </span>
            <span className="then">
              is
            </span>
            <ConditionalVariableValue
              modules={modules}
              containerRef={containerRef}
              updateConditionalVariableValue={(value) => updateConditionalVariableValue(value, 'childIfFalse')}
            />
          </div>
        </CollapsibleDiv>
      </div>
    </>
  );
}

export default IndividualConditionalVariable;

IndividualConditionalVariable.propTypes = {
  updateVariableValue: PropTypes.func.isRequired,
  parentConditionalVar: PropTypes.instanceOf(object).isRequired,
  updateChildrenConditionalVar: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  updateParentCondition: PropTypes.func.isRequired,
  updateParentConditionResult: PropTypes.func.isRequired,
};
