import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import './TextInput.scss';

function TextInput(props) {
  const {
    defaultInput, label, errorText = 'Invalid Input', placeholder, regex, onSave,
  } = props;

  const [value, setValue] = useState(defaultInput);

  const matchesRegex = (inputString, regexPattern) => {
    const regexExp = new RegExp(regexPattern);
    return regexExp.test(inputString);
  };

  const isError = useMemo(
    () => !matchesRegex(value, regex),
    [value, regex],
  );

  const onValueChange = (event) => {
    setValue(event.target.value);
    onSave(event.target.value);
  };

  return (
    <div className="text-input-div">
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item xs={12}>{label}</Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            className="text-input-div__text-input"
            value={value}
            onChange={onValueChange}
            placeholder={placeholder}
            error={isError}
            helperText={isError ? errorText : ''}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultInput: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  regex: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};
