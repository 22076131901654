import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import ArrowDownIcon from '../../../assests/icons/dropDownIcon.svg';

function DropDownWithCustomInteger(props) {
  const {
    label,
    placeholder,
    onChange,
    defaultValue,
    options,
  } = props;

  const [showDropDown, setShowDropDown] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [customInput, setCustomInput] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if (options.some((option) => option.key === defaultValue)) {
      return;
    }
    setIsCustomInput(true);
    setCustomInput(defaultValue);
  }, [options, defaultValue]);

  const onDropDown = (event) => {
    event.stopPropagation();
    setShowDropDown((e) => !e);
  };

  const handleOptionSelect = (event, option) => {
    event.stopPropagation();
    setIsCustomInput(false);
    onChange(option.key);
  };
  const setCustomInputValue = (event) => {
    const input = event.target.value;
    if (input === '' || /^\d+$/.test(input)) {
      onChange(input);
    }
  };

  const handleCustomInputSelect = (event) => {
    event.stopPropagation();
    setIsCustomInput(true);
    inputRef.current.focus();
  };

  const getValue = (currDefaultValue, isCustomInputParam, currCustomInput) => {
    if (isCustomInputParam) return currCustomInput;
    return currDefaultValue === undefined ? '' : currDefaultValue;
  };

  return (
    <Grid container alignItems="center" className="edit-properties-div__custom-dropdown-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <div className="edit-properties-div__custom-dropdown">
          <input
            type="text"
            label={label}
            value={getValue(defaultValue, isCustomInput, customInput)}
            className="edit-properties-div__custom-dropdown-input"
            readOnly={!isCustomInput}
            onChange={setCustomInputValue}
            ref={inputRef}
            placeholder={placeholder}
          />
          <button
            type="button"
            className="edit-properties-div__custom-dropdown-button"
            onClick={onDropDown}
            onBlur={() => setTimeout(() => setShowDropDown(false), 200)}
          >
            <img src={ArrowDownIcon} alt="down" />
          </button>
          {showDropDown && (
            <ul className="edit-properties-div__custom-dropdown-options">
              {
                options.map((option) => (
                  <li key={option.key}>
                    <button
                      type="button"
                      className="edit-properties-div__custom-dropdown-option"
                      onClick={(e) => handleOptionSelect(e, option)}
                    >
                      {option.name}
                    </button>
                  </li>
                ))
              }
              <li>
                <button
                  type="button"
                  className="edit-properties-div__custom-dropdown-option"
                  onClick={handleCustomInputSelect}
                >
                  Custom Input
                </button>
              </li>
            </ul>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

DropDownWithCustomInteger.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
  options: PropTypes.array,
};

DropDownWithCustomInteger.defaultProps = {
  options: [],
  placeholder: '',
};

export default DropDownWithCustomInteger;
