/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const inputsToModuleSlice = createSlice({
  name: 'inputsToModule',
  initialState: {
    selectedInputCombination: [],
    allowedInputCombinations: [],
    inputsToModule: [],
  },
  reducers: {
    setSelectedInputCombination: (state, action) => {
      const { selectedOption } = action.payload;
      state.selectedInputCombination = selectedOption;
    },
    setAllowedInputCombinations: (state, action) => {
      const { inputsCombinations } = action.payload;
      state.allowedInputCombinations = inputsCombinations;
    },
  },
});

export const selectAllowedInputCombinations =
  (state) => state.inputsToModule.allowedInputCombinations;
export const selectSelectedInputCobination =
 (state) => state.inputsToModule.selectedInputCombination;

export const {
  setSelectedInputCombination, setAllowedInputCombinations,
} = inputsToModuleSlice.actions;

export default inputsToModuleSlice.reducer;
