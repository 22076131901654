/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import getNextVariableName from './getNextVariableName';
// const computeCompiledRule = (ruleToCompile, getAlphabet) => {
//   let ruleNumber = 0;
//   const injectedRuleMap = {};
//   const regex = /(\w+(\.\w+)*\s+(===?|!==?|[<>]=?|==)\s+(?:(?:(?<=\s)|^)(?=\s)|(?:(?:"(?:\\"|[^"])*"|'(?:\\'|[^'])*')|\d*\.?\d+|null|true|false|\w+(\.\w+)*))\s*(?:(?:(?<=\s)|$)(?=\s)|))/gi;

//   const injectedRule = ruleToCompile.replace(regex, (match) => {
//     const letter = getAlphabet(ruleNumber++);
//     injectedRuleMap[letter] = match;
//     return `${letter}`;
//   });

//   console.log('injectedRule: ', injectedRule);
//   console.log('injectedRuleMap: ', injectedRuleMap);
//   return { injectedRule, injectedRuleMap };
// };

const computeCompiledRule = (ruleToCompile, getAlphabet) => {
  // let ruleNumber = 0;
  const usedAlphas = [];
  const injectedRuleMap = {};
  const regex = /(\w+(\.\w+)*\s+(===?|!==?|[<>]=?|==)\s+(?:(?:(?<=\s)|^)(?=\s)|(?:(?:"(?:\\"|[^"])*"|'(?:\\'|[^'])*')|\d*\.?\d+|null|true|false|\w+(\.\w+)*))\s*(?:(?:(?<=\s)|$)(?=\s)|))/gi;
  const injectedRule = ruleToCompile.replace(regex, (match) => {
    const letter = getNextVariableName(usedAlphas);
    usedAlphas.push(letter);
    injectedRuleMap[letter] = match;
    return `${letter}`;
  });

  return { injectedRule, injectedRuleMap };
};

export default computeCompiledRule;
