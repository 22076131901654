import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function ConditionalVariableName({ updateConditionalVariableName }) {
  const [name, setName] = useState('');

  useEffect(() => {
    // debounce
    const timer = setTimeout(() => {
      updateConditionalVariableName(name);
    }, 500);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  return (
    <div className="conditionalVar__div">
      <div className="conditionalVar__name"> Name</div>
      <input
        className="conditionalVar__name__input"
        value={name}
        placeholder="Ex: Driving License Number"
        onChange={handleOnChange}
      />
    </div>
  );
}

export default ConditionalVariableName;

ConditionalVariableName.propTypes = {
  updateConditionalVariableName: PropTypes.func.isRequired,
};
