/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

const splitRule = (rule, orderOfNodes) => {
  const moduleNames = orderOfNodes ? orderOfNodes.map((node) => {
    if (!['start', 'approve', 'decline', 'condition'].includes(node.nodeType)) return node.id;
    return null;
  }).filter((item) => item !== null) : [];
  // const moduleNames = orderOfNodes ?
  // orderOfNodes.filter((node) => !(['start', 'approve', 'decline', 'condition'].includes(node.nodeType))) : [];

  const operators = ['==', '!=', '>=', '<=', '>', '<'];
  const operatorIndex = operators.findIndex((op) => rule.includes(op));

  if (operatorIndex === -1) {
    throw new Error('Invalid comparison operator in the rule.');
  }

  const operator = operators[operatorIndex];

  let [left, right] = rule.split(operator).map((part) => part.trim());

  if (right.includes('NONE_NONE')) {
    right = 'NONE_NONE';
  }

  if (left.includes('NONE_NONE')) {
    left = 'NONE_NONE';
  }

  moduleNames.push('conditionalVariables', 'inputs');
  if (moduleNames) {
    const leftType = left && typeof left === 'string'
    && (moduleNames.includes(left.split('.')[0]) || (left === 'NONE_NONE')) ? 'module' : 'custom';

    const rightType = right && typeof right === 'string'
    && (moduleNames.includes(right.split('.')[0]) || (right === 'NONE_NONE')) ? 'module' : 'custom';

    return {
      left,
      operator,
      right,
      leftType,
      rightType,
    };
  }
  return {
    left,
    operator,
    right,
  };
};

export default splitRule;
