import { cloneDeep } from 'lodash';

import {
  fetchCurrentValueFromWorkflow,
  setModulePropertyInWorkflow,
  setModuleVariablesInWorkflow,
  unsetModulePropertyInWorkflow,
} from '../InputsToModule/utils/updateWorkflow';

export const getWorkflowValueForRootKey = (rootKey, module, moduleConfig) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const data = fetchCurrentValueFromWorkflow(
      module,
      workflowKey,
      moduleConfig,
    );
    return typeof data === 'undefined' ? null : data;
  }
  if (rootKey === 'variables') {
    const { variables } = module;
    return typeof variables === 'undefined' ? null : cloneDeep(variables);
  }
  return null;
};

export const setValueInWorkflow = (workflow, data, rootKey, moduleId, moduleConfig) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
      data,
      moduleConfig,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      data,
    );
    return editedWorkflow;
  }
  return workflow;
};

// Assumption: rootKey follows the pattern properties.<some path>
export const getWorkflowKeyForProperties = (rootKey) => rootKey.slice(11, rootKey.length);

export const unSetValueInWorkflow = (workflow, rootKey, moduleId, moduleConfig) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = getWorkflowKeyForProperties(rootKey);
    const editedWorkflow = unsetModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
      moduleConfig,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      [],
    );
    return editedWorkflow;
  }
  return workflow;
};

export const ifOnlyReqParamsAndBodyInPanel = (panel) => {
  if (panel?.tabs?.length === 2) {
    const rootKey1 = panel?.tabs[0]?.rootWorkflowKey;
    const rootKey2 = panel?.tabs[1]?.rootWorkflowKey;
    const paramKey = 'properties.requestParameters';
    const bodyKey = 'properties.requestBody';
    if (rootKey1 === paramKey && rootKey2 === bodyKey) return true;
  }
  return false;
};
