/* eslint-disable no-restricted-syntax */

const areAllVariablesDeclared = (expression, rulesMap) => {
  if (!expression || !rulesMap) return false;
  const isValidVariable = (token) => /^[A-Za-z_][A-Za-z0-9_]*$/.test(token);
  const variables = expression
    .split(/(\(|\)|&&|\|\||!)/)
    .map((token) => token.trim())
    .filter((token) => token !== '' && isValidVariable(token));

  for (const variable of variables) {
    if (!rulesMap[variable]) return false;
  }
  return true;
};

export default areAllVariablesDeclared;
