import PropTypes from 'prop-types';

import sideIcon from '../../../../assests/icons/sideIcon.svg';

function DropList({
  list, isDisabledFn, onItemClick, shouldShowArrowFn,
}) {
  const clickHandler = (event, id) => {
    if (event.stopPropagation) event.stopPropagation();
    onItemClick(id);
  };
  return (
    <ul>
      {
          (list || []).map(({ label: mainLabel, id }) => (
            <li key={id}>
              <button
                type="button"
                className="workflow_output_field_dropdown_liButton"
                onClick={(event) => { clickHandler(event, id); }}
                disabled={isDisabledFn(id)}
              >
                {mainLabel}
                {
                    shouldShowArrowFn(id) ? (
                      <img
                        src={sideIcon}
                        alt="down"
                      />
                    ) : null
                }
              </button>
            </li>
          ))
        }
    </ul>
  );
}

DropList.propTypes = {
  isDisabledFn: PropTypes.func.isRequired,
  shouldShowArrowFn: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};

export default DropList;
