import { Route, Routes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Main from './containers/Main';
import PrivateRoute from './utils/PrivateRoute';
import Builder from './containers/Builder';
import ViewWorkflow from './containers/ViewWorkflow';
import WorkflowList from './components/WorkflowList';
import NotFound from './containers/NotFound';
import WorkflowRouter from './pages/WorkflowRouter';
import DrawerLayout from './containers/DrawerLayout';
import 'reactflow/dist/style.css';
import Branding from './pages/Branding';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <Routes>
          <Route path="/" element={<PrivateRoute><Main /></PrivateRoute>}>
            <Route path="/" element={<DrawerLayout />}>
              <Route path="/" element={<WorkflowList />} />
            </Route>
            <Route path="view" element={<WorkflowRouter />}>
              <Route index element={<ViewWorkflow />} />
              <Route path="ui-screens" element={<Branding />} />
            </Route>
            <Route path="build" element={<Builder />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
