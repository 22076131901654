const getExposedVariables = (formComponentList) => formComponentList
  .reduce((exposedVariableList, component) => {
    // eslint-disable-next-line no-param-reassign
    exposedVariableList[component.type] = component.variables;
    return exposedVariableList;
  }, {});

const getFormComponentOutputs = (formComponents, exposedVariableList) => {
  const outputs = [];
  const filteredComponents = formComponents.filter((comp) => comp.type !== 'label');
  filteredComponents.forEach((comp) => {
    if (comp.type === 'vertical' || comp.type === 'horizontal') {
      outputs.push(...getFormComponentOutputs(comp.subComponents, exposedVariableList));
    } else {
      const exposedVarsOfComponent = exposedVariableList[comp.type];
      const outputsOfComponent = exposedVarsOfComponent?.map((variable) => ({
        name: `${comp.id} > ${variable.key}`,
        id: `${comp.id}.${variable.value}`,
      }));
      outputs.push(...(outputsOfComponent || []));
    }
  });
  return outputs;
};

const findFormModuleOutputs = (formModule, formComponentList) => {
  const formComponents = formModule.properties.sections[0].components || [];
  const exposedVariableList = getExposedVariables(formComponentList);
  return getFormComponentOutputs(formComponents, exposedVariableList);
};

export default findFormModuleOutputs;
