/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import PropTypes, { object } from 'prop-types';

import { useEffect, useState } from 'react';
import kebabMenuIcon from '../../../../assests/icons/kebabMenuIcon.svg';

function CustomInputForRule({
  side,
  ruleKey, rule, setShowFieldTypesOption, handleRuleTextInput,
}) {
  const setDefaultValue = () => {
    const ruleValue = rule[side] === 'NONE_NONE' ? ''
      : rule[side];
    return ruleValue;
  };

  const [inputValue, setInputValue] = useState(setDefaultValue());
  const [changeFieldType, setChangeFieldType] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value, ruleKey);
  };

  const handleChangeFieldType = (e) => {
    setShowFieldTypesOption(e, side, ruleKey);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleRuleTextInput(inputValue, ruleKey, side);
    }, 500);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setInputValue(setDefaultValue());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleKey]);

  useEffect(() => {
    if (changeFieldType)setShowFieldTypesOption(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFieldType]);

  return (
    <div
      className="condition__content__var__textBox"
    >
      <input
        type="text"
        className="condition__content__var__textBox_input"
        placeholder="Enter Value"
        value={inputValue}
        onChange={handleInputChange}
      />
      <button className="condition__content__var__textBox__more_button" type="button" onClick={handleChangeFieldType}>
        <img src={kebabMenuIcon} alt="more" />
      </button>
    </div>
  );
}

export default CustomInputForRule;

CustomInputForRule.propTypes = {
  side: PropTypes.string.isRequired,
  ruleKey: PropTypes.string.isRequired,
  rule: PropTypes.instanceOf(object).isRequired,
  setShowFieldTypesOption: PropTypes.func.isRequired,
  handleRuleTextInput: PropTypes.func.isRequired,
};
