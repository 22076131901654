import PropTypes from 'prop-types';
import addImg from '../../assests/icons/addIcon.svg';
import ListFormModuleComponents from './ListFormModuleComponents';

function ListFormModule(props) {
  const {
    formComponents,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    formComponentsConfig,
    selectedComponentPath,
  } = props;

  return (
    <div className="component-list-div">
      <div className="component-heading">
        <div className="component-list-div__heading">
          UI COMPONENTS
        </div>
        <button type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd([]); }}>
          <img src={addImg} alt="add" />
        </button>
      </div>
      <ListFormModuleComponents
        formComponents={formComponents}
        handleOnClick={handleOnClick}
        handleOnDelete={handleOnDelete}
        handleOnAdd={handleOnAdd}
        handleOnDrag={handleOnDrag}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={selectedComponentPath}
      />
    </div>
  );
}

export default ListFormModule;

ListFormModule.propTypes = {
  formComponents: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentPath: PropTypes.array.isRequired,
};
