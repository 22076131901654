import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import gotoIcon from '../assests/icons/gotoIcon.svg';
import editIcon from '../assests/icons/editIcon.svg';
import { selectSelectedWorkflow } from '../reducers/workflow';

function GotoNode({ data }) {
  const {
    id,
    name,
    parentBranch,
    parentId,
    parentPath,
    showEditNodeModal,
  } = data;

  const workflow = useSelector(selectSelectedWorkflow);

  const isParentForm = useMemo(
    () => workflow.modules.find((module) => module.id === parentId)?.subType === 'form',
    [parentId, workflow.modules],
  );

  const onEditGoto = (event, parentModuleId, parentModuleBranch, parentModulePath) => {
    event.stopPropagation();
    showEditNodeModal({
      id: parentModuleId,
      parentBranch: parentModuleBranch,
      parentPath: parentModulePath,
    });
  };

  return (
    <div className="custom_node_outer">
      {
        !isParentForm && (
        <button
          type="button"
          className="custom_node_outer__sideButton"
          onClick={(e) => onEditGoto(e, parentId, parentBranch, parentPath)}
        >
          <img
            src={editIcon}
            alt="edit"
          />
        </button>
        )
}
      <div className="custom_node">
        <div className="custom_node_inner">
          <Handle type="target" position={Position.Top} isConnectable />
          <div className="custom_node__icon">
            <img
              src={gotoIcon}
              alt={id}
              className="custom_edge_button__img"
            />
          </div>
          <div className="custom_node__body">
            <p className="custom_node__text_goto">
              {`Goto: ${name}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

GotoNode.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GotoNode;
