/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

function Toggle(props) {
  const { value, onChange, label } = props;

  return (
    <div className="edit-settings-div__toggle-container">
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <label htmlFor={label} className="edit-properties-div__label">{label}</label>
        </Grid>
        <Grid item xs={8} container className="edit-settings-div__toggle">
          <div className="toggle">
            <label htmlFor={label}>
              <input
                type="checkbox"
                checked={value}
                id={label}
                onChange={() => onChange(!value)}
              />
              <span className="slider round" />
            </label>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default Toggle;
