/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useGetModulesStyle from './useGetModulesStyle';
import './WorkflowList.scss';

function TableModules({ modules }) {
  const moduleConfig = useSelector((state) => state.workflow.modules);
  const styleConfig = useGetModulesStyle();
  return (
    <div id="workflow_table__modules">
      {modules.map((module, idx) => {
        if (styleConfig[module]) {
          return (
            <p
              key={idx}
              style={{ ...styleConfig[module] }}
            >
              {moduleConfig[`${module}_uiConfig`]?.moduleName || moduleConfig[module]?.uiConfig?.node?.heading}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
}

TableModules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableModules;
