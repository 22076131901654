import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import review from '../assests/icons/review.svg';
import editIcon from '../assests/icons/editIcon.svg';

function ReviewNode({ type, data }) {
  const { parentBranch, parentId, showEditNodeModal } = data;
  const editNode = (event) => {
    event.stopPropagation();
    showEditNodeModal({ id: parentId, parentBranch });
  };
  return (
    <div className="custom_node">
      <button type="button" className="custom_node__sideButton_edit" onClick={editNode}>
        <img
          src={editIcon}
          alt="edit"
        />
      </button>
      <Handle type="target" position={Position.Top} isConnectable />
      <div className="custom_node__icon">
        <img
          src={review}
          alt={type}
          className="custom_edge_button__img"
        />
      </div>
      <div className="custom_node__body">
        <p className="custom_node__heading">
          Review
        </p>
      </div>
    </div>
  );
}

ReviewNode.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ReviewNode;
