/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import Toggle from '../FormModule/Common/Toggle';
import DropDown from '../FormModule/Common/DropDown';
import IntTextInput from './v2/IntTextInput/IntTextInput';

function DisplayWorkflowConfigurations({ workflowConfig }) {
  const onSetRedirectToMobile = (value) => {
    const newWorkflow = cloneDeep(workflowConfig);
    newWorkflow.properties.redirectToMobile = value;
    updateWorkflowInState(newWorkflow);
  };

  const onSetResumeWorkflow = (value) => {
    const { enable, duration } = value;
    const newWorkflow = cloneDeep(workflowConfig);
    if (typeof enable !== 'undefined') newWorkflow.properties.enableResumeWorkflow = enable;
    if (typeof duration !== 'undefined') newWorkflow.properties.resumeWorkflowDuration = duration;
    updateWorkflowInState(newWorkflow);
  };

  return (
    <>
      <div className="drawer_content__heading_default">CONFIGURATIONS OF THE WORKFLOW</div>
      <div className="edit-properties-div__property">
        <DropDown
          label="Redirect to mobile"
          options={[
            { key: 'yes', name: 'Yes' },
            { key: 'no', name: 'No' },
            { key: 'optional', name: 'Optional' },
          ]}
          defaultValue={workflowConfig.properties?.redirectToMobile || ''}
          onChange={(data) => {
            onSetRedirectToMobile(data);
          }}
        />
      </div>
      <div className="edit-properties-div__property">
        <Toggle
          label="Resume workflow"
          onChange={(val) => {
            onSetResumeWorkflow({ enable: val });
          }}
          // eslint-disable-next-line no-unneeded-ternary
          value={workflowConfig.properties?.enableResumeWorkflow === false ? false : true}
        />
      </div>
      <IntTextInput
        label="Resume duration"
        onChange={(data) => {
          const duration = parseInt(data, 10);
          if (typeof duration === 'number' && duration > 0) onSetResumeWorkflow({ duration });
        }}
        defaultValue={workflowConfig.properties?.resumeWorkflowDuration}
      />
    </>
  );
}

DisplayWorkflowConfigurations.propTypes = {
  workflowConfig: PropTypes.object.isRequired,
};

export default DisplayWorkflowConfigurations;
