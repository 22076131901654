const selectedDocuments = (selectedModule, documentList) => {
  const result = Object.entries(selectedModule.properties.documentsSupported)
    .map(([country, documents]) => ({ country, documents }));
  const overridenProperties = selectedModule.properties.documentsOverride || {};

  const intialDocumentsSupported = result.map(
    (obj) => ({
      ...obj,
      documentsName: [],
      countryName: '',
      configurations: overridenProperties[obj.country] || {},
    }),
  );

  intialDocumentsSupported.forEach((res, index) => {
    const filtered = documentList.filter((doc) => doc.id === res.country);
    if (filtered.length) {
      intialDocumentsSupported[index].countryName = filtered[0].name;
      res.documents.forEach((doc) => {
        const filteredDocument = filtered[0].documents.filter((docx) => doc === docx.id);
        intialDocumentsSupported[index].documentsName.push(filteredDocument[0].name);
        if (!Object.keys(res.configurations[doc] || {}).length) {
          res.configurations[doc] = {
            sides: filteredDocument[0].sides,
          };
        }
      });
    }
  });

  return intialDocumentsSupported;
};

export default selectedDocuments;
