import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import RequestParameter from './RequestParameter';
import isValidData from './validate';

function RequestParameters({
  onParamsChange,
  params,
  nameLabel,
  pathLabel,
  typeLabel,
  default: defaultType,
  values: typeOptionValues = [],
  text: typeOptionLabels = [],
}) {
  const supportedTypes = typeOptionValues
    .map((value, index) => ({ id: value, label: typeOptionLabels[index] || value }));

  const addNewParam = (currentParams) => {
    const newParamsList = cloneDeep(currentParams || []);
    newParamsList.push({ name: '', type: defaultType, value: '' });
    onParamsChange(newParamsList);
  };

  const deleteVariable = (currentParams, index) => {
    const newParamsList = cloneDeep(currentParams || []);
    newParamsList.splice(index, 1);
    if (newParamsList.length === 0) onParamsChange(null);
    else onParamsChange(newParamsList);
  };

  const onChange = (currentParams, newValue, index, key) => {
    const newParamsList = cloneDeep(currentParams || []);
    if (index < newParamsList.length) newParamsList[index][key] = newValue;
    onParamsChange(newParamsList);
  };

  const isValid = (currentParams) => {
    const data = currentParams || [];
    return isValidData(data);
  };

  return (
    <div className="workflowOutput__bottomContent_body">
      {
          isValid(params) ?
            (params || []).map(({ name, value, type }, index) => (
              <RequestParameter
                // TODO: What key can be used here as a key?
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name={name}
                value={value}
                type={type}
                nameLabel={nameLabel}
                pathLabel={pathLabel}
                typeLabel={typeLabel}
                onNameChange={(newValue) => { onChange(params, newValue, index, 'name'); }}
                onValueChange={(newValue) => { onChange(params, newValue, index, 'value'); }}
                onTypeChange={(newValue) => { onChange(params, newValue, index, 'type'); }}
                onDelete={() => { deleteVariable(params, index); }}
                supportedTypes={supportedTypes}
              />
            ))
            : <p>Opps! Workflow has invalid data</p>
      }
      {
        isValid(params) ? <button type="button" onClick={() => { addNewParam(params); }} className="workflowInput__bottomContent_button">Add New Variable</button> : null
      }
    </div>
  );
}

RequestParameters.defaultProps = {
  params: null,
};

RequestParameters.propTypes = {
  params: PropTypes.array, // can be null as well
  onParamsChange: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  default: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  text: PropTypes.array.isRequired,
};

export default RequestParameters;
