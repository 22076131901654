import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import PropTypes from 'prop-types';

import { validateWorkflow } from '../../workflowOperations/updateWorkflow';
import CreateWorkflowButton from './CreateWorkflowButton';
import useShowErrorAlert from '../../utils/customHooks';
import AlertCode from '../../constants/alertCodes';
import useApiHooks from '../../utils/useApiHooks';
import createWorkflowId from './helper';
import FileUpload from './fileUpload';

function ImportWorkflow({ currentAppId }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpload, setEnableUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const showErrorAlert = useShowErrorAlert();

  const {
    publishTextConfig, publishUiConfig, publishWorkflow,
  } = useApiHooks();

  const handleImportButton = () => {
    setShowDrawer((status) => !status);
    setIsLoading(false);
    setWorkflowData({});
  };

  const handleUploadButton = async () => {
    if (currentAppId && workflowData?.workflowConfig) {
      setIsLoading(true);
      const workflowId = createWorkflowId();
      const workflow = workflowData.workflowConfig;
      set(workflow, 'properties.builtOnBuilder', true);
      if (!workflow?.properties?.sdkVersions ||
        !Object.keys(workflow?.properties?.sdkVersions).length) {
        set(
          workflow,
          'properties.sdkVersions',
          {
            mobile: {
              maximum: '10.0.0',
              minimum: '0.3.0',
            },
            web: {
              maximum: '10.0.0',
              minimum: '5.0.0',
            },
          },
        );
      }
      const { code, isValid } = validateWorkflow(workflow);
      if (!isValid) {
        showErrorAlert({ message: AlertCode.error[code] || 'Something went wrong!' });
        setIsLoading(false);
      } else {
        const { textConfig, uiConfig } = workflowData;
        const promises = [
          publishWorkflow(currentAppId, workflowId, workflow),
        ];
        if (textConfig && Object.keys(textConfig).length > 0) {
          promises.push(publishTextConfig(workflowId, textConfig, 'en'));
        }
        if (uiConfig && Object.keys(uiConfig).length > 0) {
          promises.push(publishUiConfig(workflowId, uiConfig));
        }
        const responses = await Promise.all(promises);
        setIsLoading(false);
        const failedPromise = responses.find((response) => !response.isSuccessful);
        if (failedPromise && Object.keys(failedPromise)) {
          showErrorAlert({ error: failedPromise.error, message: failedPromise.message });
        } else {
          navigate(`/view?id=${workflowId}`);
          handleImportButton();
        }
      }
    }
    setEnableUpload(true);
  };

  const handleDataChange = (data, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: data,
    }));
  };

  useEffect(() => {
    setEnableUpload(currentAppId && workflowData?.workflowConfig);
  }, [workflowData, currentAppId]);

  return (
    <>
      <div className="import_workflow__item">
        <button type="button" onClick={handleImportButton} className="import_workflow__item_button">
          Import Workflow
        </button>
      </div>
      { showDrawer
        && (
          <div className="import_workflow__drawer_background">
            <div className="import_workflow__drawer">
              <FileUpload
                title="Workflow"
                onChange={(jsonData) => { handleDataChange(jsonData, 'workflowConfig'); }}
              />
              <FileUpload
                title="Text Config (en)"
                onChange={(jsonData) => { handleDataChange(jsonData, 'textConfig'); }}
              />
              <FileUpload
                title="UI Config"
                onChange={(jsonData) => { handleDataChange(jsonData, 'uiConfig'); }}
              />
              <div className="import_workflow__item">
                <button type="button" onClick={handleImportButton} className="import_workflow__button">
                  Cancel
                </button>
                { enableUpload
                  ? (
                    <CreateWorkflowButton
                      onButtonClick={handleUploadButton}
                      normalButtonText="Upload"
                      loadingButtonText="Uploading"
                      isLoading={isLoading}
                      buttonClassName="import_workflow__button__primary"
                    />
                  )
                  : null}
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default ImportWorkflow;

ImportWorkflow.propTypes = {
  currentAppId: PropTypes.string.isRequired,
};
