import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

function CreateWorkflowButton({
  onButtonClick, isLoading, loadingButtonText, normalButtonText, buttonClassName,
}) {
  return (
    <button type="button" onClick={onButtonClick} className={buttonClassName} disabled={isLoading}>
      {
        isLoading
          ? (
            <>
              <CircularProgress size="1rem" />
              {loadingButtonText}
            </>
          )
          : normalButtonText
      }
    </button>
  );
}

CreateWorkflowButton.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingButtonText: PropTypes.string.isRequired,
  normalButtonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
};

export default CreateWorkflowButton;
