import { cloneDeep } from 'lodash';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { extractComponentIdsForModule } from '../../utils/helper';

export const getFormComponents = (module) => module?.properties?.sections?.[0]?.components || [];

export const getComponentFromPath = (components, pathArray) => {
  if (!components?.length || !pathArray?.length) return null;
  if (pathArray.length === 1) return components[pathArray[0]] || null;
  const [currentIndex, ...newPathArray] = pathArray;
  const childComponents = components[currentIndex]?.subComponents;
  return getComponentFromPath(childComponents, newPathArray);
};

export const getSelectedComponent = (module, pathArray) => {
  const components = getFormComponents(module);
  if (!components?.length) return null;
  const finalComponent = getComponentFromPath(components, pathArray);
  return finalComponent;
};

const allowedOperations = ['add', 'delete', 'insert', 'update'];

export const operateOnFormComponents =
(operation, components, pathArray, newComponent = null) => {
  if (!allowedOperations.includes(operation)
   || typeof components?.length !== 'number'
   || typeof pathArray?.length !== 'number') { return components; }

  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 0 && operation === 'add') {
    clonnedComponents.push(newComponent);
    return clonnedComponents;
  }
  if (pathArray.length === 1 && ['delete', 'insert', 'update'].includes(operation)) {
    if (operation === 'insert') clonnedComponents.splice([pathArray[0]], 0, newComponent);
    if (clonnedComponents?.length > pathArray[0]) {
      if (operation === 'delete') clonnedComponents.splice(pathArray[0], 1);
      if (operation === 'update') clonnedComponents[pathArray[0]] = newComponent;
    }
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents;
  if (!currentSubComponents) return clonnedComponents;
  const newChildSubComponents =
    operateOnFormComponents(operation, currentSubComponents, newPath, newComponent);
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const performOpOnWorkflow =
(operation, workflow, moduleId, pathArray, newComponent = null) => {
  if (!allowedOperations.includes(operation)) return workflow;
  const editedWorkflow = cloneDeep(workflow);
  const selectedModule = getSelectedModule(editedWorkflow, moduleId);
  if (!selectedModule) return editedWorkflow;
  const components = selectedModule?.properties?.sections[0]?.components || [];
  if (!components?.length) return editedWorkflow;
  const updatedComponents = operateOnFormComponents(operation, components, pathArray, newComponent);
  selectedModule.properties.sections[0].components = updatedComponents;
  return editedWorkflow;
};

export const getTotalBranches = (components) => {
  let totalBranches = 0;
  (components || []).forEach((component) => {
    if (component?.onClick?.nextStep) totalBranches += 1;
    if (component?.subComponents?.length) {
      const totalSubBranches = getTotalBranches(component.subComponents || []);
      totalBranches += totalSubBranches;
    }
  });
  return totalBranches;
};

export const getAllNextSteps = (components) => {
  const nextSteps = [];
  (components || []).forEach((component) => {
    if (component?.onClick?.nextStep) nextSteps.push(component?.onClick?.nextStep);
    if (component?.subComponents?.length) {
      const subNextSteps = getAllNextSteps(component.subComponents || []);
      nextSteps.push(...subNextSteps);
    }
  });
  return nextSteps;
};

export const canDeleteComponent = (module, pathArray) => {
  const components = module?.properties?.sections[0]?.components || [];
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  if (componentToBeDeleted?.type === 'button' && componentToBeDeleted?.onClick?.nextStep) {
    const totalBranches = getTotalBranches(components);
    return totalBranches > 1;
  }
  return true;
};

const generateRuleString = (component) => {
  let ruleString = ' ';
  ruleString += ` ${component?.required || ' '}`;
  ruleString += ` ${component?.visible || ' '}`;
  ruleString += ` ${component?.enabled || ' '}`;
  const rulesValidation = (component?.validation || []).filter((validation) => validation?.type === 'rule');
  ruleString += rulesValidation.reduce((accumulator, currentValue) => accumulator + currentValue.value, ' ');
  return ruleString;
};

const extractFieldsForComponent = (component, moduleId) => {
  const ruleString = generateRuleString(component);
  let fields = extractComponentIdsForModule(ruleString, moduleId);
  fields = fields.filter((field) => field !== component.id);
  return fields;
};

const getReloadDependency = (components, moduleId) => {
  let dependency = {};
  if (!components?.length) return dependency;
  (components || []).forEach((component) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const subComponentDependency = getReloadDependency(subComponents, moduleId);
        dependency = { ...dependency, ...subComponentDependency };
      }
    } else {
      const fields = extractFieldsForComponent(component, moduleId);
      dependency[component.id] = fields;
    }
  });
  return dependency;
};

const updateReloadDependency = (components, reloadDependency) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components);
  (clonnedComponents || []).forEach((component, index) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const updatedSubComponents = updateReloadDependency(subComponents, reloadDependency);
        clonnedComponents[index].subComponents = updatedSubComponents;
      }
    } else {
      clonnedComponents[index].onChange = {
        reloadComponents: Object.keys(reloadDependency[component.id] || {}),
      };
    }
  });
  return clonnedComponents;
};

export const updateDependencyForOnReload = (components, moduleId) => {
  const onReloadInverseDependency = getReloadDependency(components, moduleId);
  const onReloadDependency = {};
  Object.keys(onReloadInverseDependency).forEach((dependency) => {
    const fields = onReloadInverseDependency[dependency];
    fields.forEach((field) => {
      const existingObj = onReloadDependency[field];
      onReloadDependency[field] = { [dependency]: 'present', ...(existingObj || {}) };
    });
  });
  const updatedComponents = updateReloadDependency(components, onReloadDependency);
  return updatedComponents;
};

export const getdefaultUIValue = (currUiConfig, defaultUiArray, subType = '') => {
  if (!defaultUiArray?.length) return null;
  const defaultUiObjForSubType = defaultUiArray.find((obj) => obj?.subType === subType);
  if (!defaultUiObjForSubType) return null;
  const { key } = defaultUiObjForSubType;
  let uiValue;
  Object.entries(currUiConfig).forEach(([, sectionValue]) => {
    Object.entries(sectionValue).forEach(([innerKey, innerValue]) => {
      if (innerKey === key) uiValue = innerValue;
    });
  });
  return uiValue;
};
