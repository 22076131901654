/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import close from '../../../../assests/icons/close.svg';

function EditConditionTitleRibbon({ handleClose, handleSave }) {
  return (
    <div className="condition__content__title-ribbon">
      <div className="condition__content__title-ribbon__title">If (Branch 1) Rule</div>
      <div className="condition__content__title-ribbon__close-div">
        <button
          className="condition__content__title-ribbon__button"
          type="button"
          onClick={handleSave}
        >
          Save Conditions
        </button>
        <img
          src={close}
          alt="close"
          onClick={handleClose}
        />
      </div>
    </div>
  );
}

export default EditConditionTitleRibbon;

EditConditionTitleRibbon.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
