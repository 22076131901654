/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import invalidRuleIcon from '../../../../assests/icons/invalidRuleIcon.svg';
import validRuleIcon from '../../../../assests/icons/tickIcon.svg';

function ConditionCompiledRuleBox({
  compiledRule, isRuleValid, setCompiledRule, conditionalVariable,
}) {
  const handleInputRuleChange = (e) => {
    const { value } = e.target;
    if (value !== '') {
      setCompiledRule(value);
    }
  };
  return (
    <>
      {!conditionalVariable && (
      <div className="condition__content__body__title">
        Branch 1 is executed when the following rule is True
      </div>
      )}
      <div className={!conditionalVariable ? 'condition__content__rule-box' : 'conditional__content__rule-box'}>
        <div className="condition__content__rule-box__title">Rule:</div>
        <div className={`condition__content__rule-box__input  ${isRuleValid ? '' : 'invalid-rule'}`}>
          <input
            className="condition__content__rule-box__input"
            type="text"
            value={compiledRule}
            onChange={handleInputRuleChange}
          />
          <div className="condition__content__rule-box__rule-validity">
            <img src={isRuleValid ? validRuleIcon : invalidRuleIcon} alt="validity" />
          </div>
          {!conditionalVariable
          && (
          <div className="condition__content__rule-box__sample-rule">
            Sample Rule: A && B || (C && D)
          </div>
          )}
        </div>
      </div>
      <div className={!conditionalVariable ? 'condition__content__where' : 'conditional__content__where'}>
        where,
      </div>
    </>
  );
}

export default ConditionCompiledRuleBox;

ConditionCompiledRuleBox.propTypes = {
  compiledRule: PropTypes.string.isRequired,
  setCompiledRule: PropTypes.func.isRequired,
  isRuleValid: PropTypes.bool.isRequired,
  conditionalVariable: PropTypes.bool.isRequired,
};
