import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import closeButton from '../../assests/icons/closeButton.svg';
import WorkflowOutput from './WorkflowOutput';
import WorkflowInput from './WorkflowInput';
import { selectSelectedWorkflow } from '../../reducers/workflow';
import DisplayConditionalVariables from './v2/ConditionalVariablesV2/DisplayConditionalVariables';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import getOutputsOfModule from './v2/InputsToModule/utils/getModuleOutputs';
import { selectFormComponents } from '../../reducers/dynamicForm';
import { fetchDependencyOfField } from '../../utils/fetchDependencyList';
import pushBulkLogs from '../../logger/pushBulkLogs';

function WorkflowConfigurationModal(props) {
  const { onClose, page, checkDependencies: checkDeletionDependencies } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [sdkInputs, setSdkInputs] = useState([]);
  const [sdkResponse, setSdkResponse] = useState([]);
  const [conditionalVariables, setConditionalVariables] = useState();
  const [conditionalVariableLogs, setConditionalVariableLogs] = useState([]);

  const workflow = useSelector(selectSelectedWorkflow);
  const moduleConfigs = useSelector((state) => state.workflow.modules);
  const formComponents = useSelector(selectFormComponents);

  const generateUniqueID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueID = '';

    for (let i = 0; i < 6; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueID += characters.charAt(randomIndex);
    }

    return uniqueID;
  };

  useEffect(() => {
    const inputs = workflow.properties.inputsRequired || {};
    if (inputs) {
      setSdkInputs(
        Object.entries(inputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, type: value }
          ),
        ),
      );
    }
    const outputs = workflow.sdkResponse || {};
    if (outputs) {
      setSdkResponse(
        Object.entries(outputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, value }
          ),
        ),
      );
    }

    const condVars = workflow.conditionalVariables || {};
    setConditionalVariables(condVars);
  }, [workflow]);

  const dropDownOptions = useMemo(() => {
    const moduleOutputs = workflow.modules.map((module) => {
      const individualModuleOutputs = (getOutputsOfModule(
        module.id,
        formComponents,
        workflow.modules,
        moduleConfigs,
      ) || []).map((val) => (
        {
          value: `${module.id}.${val.id}`,
          label: val.name,
          displayLabel: `${module.name || module.id} > ${val.name || val.id}`,
        }
      ));
      return {
        label: module.name,
        items: individualModuleOutputs,
      };
    });
    const workflowInputs = Object.keys(workflow.properties.inputsRequired || {}).map((val) => ({
      label: val,
      value: `inputs.${val}`,
      displayLabel: `Inputs > ${val}`,
    }));
    const conVarOutputs = Object.entries(conditionalVariables || {})
      .filter(([key, value]) => !value.parent || (value.parent === key))
      .map(([key, value]) => ({
        value: `conditionalVariables.${key}`,
        label: value.name || key,
        displayLabel: `Conditional Variables > ${key}`,
      }));
    return [
      {
        label: 'Module Outputs',
        id: 'moduleOutputs',
        items: moduleOutputs,
      },
      {
        label: 'Workflow Inputs',
        id: 'workflowInputs',
        items: workflowInputs,
      },
      {
        label: 'Conditional Variables',
        id: 'conditionalVariables',
        items: conVarOutputs,
      },
    ];
  }, [conditionalVariables, formComponents, moduleConfigs, workflow]);

  const setVariableName = (id, variable) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, variable };
      return input;
    }));
  };
  const setOutputVariableName = (id, variable) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, variable };
      return output;
    }));
  };
  const setOutputValue = (id, value) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, value };
      return output;
    }));
  };

  const setType = (id, type) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, type };
      return input;
    }));
  };
  const addNewVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      type: 'string',
    };
    setSdkInputs((prevInputs) => [...prevInputs, newField]);
  };

  const addNewOutputVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      value: '',
    };
    setSdkResponse((prevOutputs) => [...prevOutputs, newField]);
  };

  const deleteVariable = (id, selectedWorkflow) => {
    // Check dependencies
    const currInput = sdkInputs.find((input) => input.id === id);
    const isDependent = checkDeletionDependencies({
      variableId: currInput.variable,
      nodeId: 'inputs',
      workflow: selectedWorkflow,
    });
    if (isDependent) return;
    setSdkInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const deleteOutputVariable = (id) => {
    setSdkResponse((prevOutputs) => prevOutputs.filter((output) => output.id !== id));
  };

  const validateInputConfig = () => {
    let isValid = true;
    sdkInputs.forEach((input) => {
      if (!input.variable || input.variable.includes(' ')) {
        isValid = false;
      }
    });
    // eslint-disable-next-line no-alert
    if (!isValid) alert('Variable names cannot be empty or contain spaces!');
    return isValid;
  };

  const validateOutputConfig = () => {
    let isValidVariable = true;
    let isValidValue = true;
    sdkResponse.forEach((output) => {
      if (!output.variable || output.variable.includes(' ')) {
        isValidVariable = false;
      }
      if (!output.value) isValidValue = false;
    });
    // eslint-disable-next-line no-alert
    if (!isValidVariable) alert('Variable names cannot be empty or contain spaces!');
    // eslint-disable-next-line no-alert
    if (!isValidValue) alert('Values cannot be empty!');
    return isValidValue && isValidVariable;
  };

  const saveWorkflowInputs = () => {
    if (validateInputConfig()) {
      // update the workflow
      const newWorkflow = cloneDeep(workflow);
      const newInputs = {};
      sdkInputs.forEach((input) => {
        newInputs[input.variable] = input.type;
      });
      newWorkflow.properties.inputsRequired = newInputs;
      updateWorkflowInState(newWorkflow);
      onClose();
    }
  };

  const saveWorkflowOutputs = () => {
    if (validateOutputConfig()) {
      // update the workflow
      const newWorkflow = cloneDeep(workflow);
      const newOutputs = {};
      sdkResponse.forEach((output) => {
        newOutputs[output.variable] = output.value;
      });
      newWorkflow.sdkResponse = newOutputs;
      updateWorkflowInState(newWorkflow);
      onClose();
    }
  };

  const canDeleteConditionalVariable = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return checkDeletionDependencies(
      {
        variableId: conVar,
        nodeId: 'conditionalVariables',
        workflow: workflowToPass,
      },
    );
  };

  const getConditionalVariableDeps = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return fetchDependencyOfField(conVar, 'conditionalVariables', workflowToPass);
  };

  const saveConditionalVariables = (condVars, currWorkflow) => {
    const newWorkflow = cloneDeep(currWorkflow);
    newWorkflow.conditionalVariables = condVars;
    pushBulkLogs(conditionalVariableLogs);
    updateWorkflowInState(newWorkflow);
    onClose();
  };

  const onSave = () => {
    if (currentPage === 'input') saveWorkflowInputs();
    else if (currentPage === 'output') saveWorkflowOutputs();
    else if (currentPage === 'conditionalVariables') saveConditionalVariables(conditionalVariables, workflow);
    else onClose();
  };

  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <div className="workflow_configuration_modal__header">
          <span>
            Input/Output Definition
          </span>
          <button
            type="button"
            className="workflow_configuration_modal__header_button"
            onClick={onSave}
          >
            Save
          </button>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>

        <div className="workflow_configuration_modal__content">
          <div className="workflow_configuration_modal__sidebar">
            <button
              type="button"
              className={currentPage === 'input' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('input')}
            >
              Workflow Inputs
            </button>
            <button
              type="button"
              className={currentPage === 'output' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('output')}
            >
              Workflow Outputs
            </button>
            <button
              type="button"
              className={currentPage === 'conditionalVariables' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('conditionalVariables')}
            >
              Conditional Variables
            </button>

          </div>
          <div className="workflow_configuration_modal__maincontent">
            {
              currentPage === 'output'
                ? (
                  <WorkflowOutput
                    sdkResponse={sdkResponse}
                    setVariableName={setOutputVariableName}
                    addNewVariable={addNewOutputVariable}
                    deleteVariable={deleteOutputVariable}
                    setOutputValue={setOutputValue}
                  />
                ) : ''
            }
            {
              currentPage === 'input'
                ? (
                  <WorkflowInput
                    sdkInputs={sdkInputs}
                    setVariableName={setVariableName}
                    setType={setType}
                    addNewVariable={addNewVariable}
                    deleteVariable={(...args) => deleteVariable(...args, workflow)}
                  />
                ) : ''
            }
            {
              (currentPage === 'conditionalVariables') ? (
                <DisplayConditionalVariables
                  conditionalVariables={conditionalVariables}
                  setConditionalVariables={setConditionalVariables}
                  canDeleteConditionalVariable={
                    (conVar) => canDeleteConditionalVariable(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  dropDownOptions={dropDownOptions}
                  getConditionalVariableDeps={
                    (conVar) => getConditionalVariableDeps(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  setConditionalVariableLogs={
                    (log) => setConditionalVariableLogs((currLogs) => [...currLogs, log])
                  }
                />
              )
                : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(WorkflowConfigurationModal);
