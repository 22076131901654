/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import PropTypes, { object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import sideIcon from '../../../../../assests/icons/sideIcon.svg';
import './ConditionalVariableValue.scss';
import '../../NewConditionDrawer/NewConditionDrawer.scss';
import getOutputsOfModule from '../../InputsToModule/utils/getModuleOutputs';
import { selectFormComponents } from '../../../../../reducers/dynamicForm';

function SelectConditionalVarModuleValue({
  posRef, selectedRule, closeVariableModal,
  previousModules, selectedWorkflow, updateConditionalVar,
}) {
  const moduleRef = useRef(null);
  const screenHeight = window.innerHeight;
  const formComponents = useSelector(selectFormComponents);
  const moduleConfigs = useSelector((state) => state.workflow.modules);
  const [moduleNameStyle, setModuleNameStyle] = useState({});
  const [variableNameStyle, setVariableNameStyle] = useState({});
  const [variables, setVariables] = useState([]);
  const [activeModule, setActiveModule] = useState('');
  const [selectedModule, setSelectedModule] = useState({ moduleId: '', moduleName: '' });
  const [selectedVariable, setSelectedVariable] = useState({
    workflowKey: '', variableName: '',
  });

  const handleOutsideClick = () => {
    closeVariableModal(true);
  };

  const handleOnVariableSelect = (variable) => {
    setSelectedVariable(
      {
        variableName: variable.variableName || variable.name,
        workflowKey: variable.key || variable.name,
      },
    );
  };

  useEffect(() => {
    if (selectedVariable.workflowKey) {
      updateConditionalVar({
        text: `${selectedModule.moduleName}.${selectedVariable.variableName}`,
        workflowRule: `${selectedModule.moduleId}.${selectedVariable.workflowKey}`,
        type: 'module',
      });
      closeVariableModal(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariable]);

  useEffect(() => {
    if (selectedModule?.moduleId) {
      const source = selectedModule?.moduleId;
      const { modules } = selectedWorkflow;
      const outputsOfModule = getOutputsOfModule(source, formComponents, modules, moduleConfigs);
      const list = (outputsOfModule || []).map((output) => (
        { variableName: output.name, key: output.id }));
      setVariables(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousModules, selectedModule]);

  useEffect(() => {
    if (activeModule && activeModule > 0) {
      setVariables([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule]);

  const handleOnModuleSelect = (event, module, index, reference) => {
    const referenceStyle = window.getComputedStyle(reference.current);
    const { clientY } = event;
    setActiveModule(index + 1);
    setSelectedModule({
      moduleName: module.moduleName || module.uiConfig?.node?.heading,
      moduleId: module.moduleId,
    });
    if (screenHeight < clientY + 190) {
      setVariableNameStyle({
        position: 'absolute',
        bottom: screenHeight - clientY,
        left: parseFloat(referenceStyle.left) + parseFloat(referenceStyle.width),
      });
    } else {
      setVariableNameStyle({
        position: 'absolute',
        top: clientY - 100,
        left: parseFloat(referenceStyle.left) + parseFloat(referenceStyle.width),
      });
    }
  };

  useEffect(() => {
    if (screenHeight < posRef.top + 393) {
      setModuleNameStyle({
        position: 'absolute',
        top: screenHeight - (posRef.top),
        left: posRef.left,
      });
    } else {
      setModuleNameStyle({
        position: 'absolute',
        top: posRef.top,
        left: posRef.left,
      });
    }
  }, [posRef, screenHeight]);

  return (
    <div>
      <div
        className="search-module"
        style={moduleNameStyle}
        ref={moduleRef}
      >
        {previousModules.map((module, index) => (
          <div
            key={index}
            onClick={(e) => handleOnModuleSelect(e, module, index, moduleRef)}
            className={`search-module__modules ${activeModule - 1 === index ? 'active_module' : ''}`}
          >
            <div className="search-module__modules__name">
              {module.moduleName || module.uiConfig.node.heading}
            </div>
            <div className="search-module__modules__icon">
              <img src={sideIcon} alt="side" />
            </div>
          </div>
        ))}
      </div>
      <div
        className="search-module__background"
        onClick={handleOutsideClick}
      />
      {activeModule && activeModule > 0 && variables && (
        <div>
          <div
            className="search-variables"
            style={variableNameStyle}
          >
            { variables.map((variable, index) => (
              <div
                key={`variable_${index}`}
                onClick={() => handleOnVariableSelect(variable)}
              >
                <div
                  className="search-variables__name"
                >
                  {variable.variableName || variable.name}
                  {' '}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectConditionalVarModuleValue;

SelectConditionalVarModuleValue.propTypes = {
  posRef: PropTypes.any.isRequired,
  selectedRule: PropTypes.string.isRequired,
  closeVariableModal: PropTypes.func.isRequired,
  previousModules: PropTypes.array.isRequired,
  selectedWorkflow: PropTypes.instanceOf(object).isRequired,
  updateConditionalVar: PropTypes.func.isRequired,
};
