import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './InputsDropDown.scss';
import {
  selectModules, selectSelectedNode,
} from '../../../../reducers/workflow';
import InputsDropDownRow from './InputsDropDownRow';
import { findPreviousModulesWithNames } from './utils/findPreviousModules';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import {
  getCurrentValueFromWorkflowForModuleInputs,
  setModulePropertyInWorkflow,
  unsetModulePropertyInWorkflow,
  getSelectedValueFromModuleInputs,
} from './utils/updateWorkflow';
import { selectAllowedInputCombinations, selectSelectedInputCobination } from '../../../../reducers/inputsToModule';
import { selectFormComponents } from '../../../../reducers/dynamicForm';
import getOutputsOfModule from './utils/getModuleOutputs';
import { getConditionalVariables, getWorkflowInputVariables } from './utils';

function Inputs({ input }) {
  const orderOfModules = useSelector((state) => state.workflow.orderOfNodes);
  const moduleConfigs = useSelector((state) => state.workflow.modules);
  const selectedNodeId = useSelector((state) => state.workflow.selectedNode)?.id;
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);
  const selectedWorkflowModules = selectedWorkflow.modules;
  const [selectedModule, setSelectedModule] = useState(selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  ));
  const [options, setOptions] = useState([]);
  const [variableOptions, setVariableOptions] = useState([]);
  const [dropDownState, setDropDownState] = useState({
    selectedVariable: '',
    selectedSource: '',
  });

  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];
  const allowedInputCombinations = useSelector(selectAllowedInputCombinations);
  const selectedInputCombination = useSelector(selectSelectedInputCobination);
  const formComponents = useSelector(selectFormComponents);
  const {
    title, key, workflowKey,
  } = input;

  const setSelectedSource = (selectedSource) => {
    setDropDownState({
      selectedVariable: '',
      selectedSource,
    });
  };

  const setSelectedVariable = (selectedVariable) => {
    setDropDownState((state) => ({
      ...state,
      selectedVariable,
    }));
  };

  const onUnSelect = () => {
    const editedWorkflow = unsetModulePropertyInWorkflow(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
    );
    updateWorkflowInState(editedWorkflow);
  };

  // Update source list
  useEffect(() => {
    // if there is a default value, use it otherwise use 0th value as default
    // find previous modules
    const indexOfCurrentModule = orderOfModules.findIndex(
      (module) => module.id === selectedNodeId,
    );
    const previousModules = findPreviousModulesWithNames(
      orderOfModules,
      indexOfCurrentModule,
      selectedWorkflow,
    );
    if (selectedWorkflow.conditionalVariables) {
      previousModules.push(
        {
          id: 'conditionalVariables',
          name: 'Conditional Variables',
        },
      );
    }
    const workflowInputs = getWorkflowInputVariables(selectedWorkflow);
    if (workflowInputs?.length) {
      previousModules.push(
        {
          id: 'inputs',
          name: 'Workflow Inputs',
        },
      );
    }
    setOptions(previousModules);
    setSelectedModule(selectedWorkflow.modules.find(
      (module) => module.id === selectedNodeId,
    ));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  // Set default values
  useEffect(() => {
    const {
      selectedSource: currentSelectedSource,
      selectedVariable: currentSelectedVariable,
    } = getCurrentValueFromWorkflowForModuleInputs(
      selectedModule,
      workflowKey,
      selectedModuleConfig,
    );
    setDropDownState({
      selectedVariable: currentSelectedVariable,
      selectedSource: currentSelectedSource,
    });
  }, [selectedModule, input, JSON.stringify(selectedInputCombination)]);

  // Update the workflow with latest selection
  useEffect(() => {
    const { selectedSource, selectedVariable } = dropDownState;
    if (selectedVariable) {
      const selectedValue = getSelectedValueFromModuleInputs(
        selectedSource,
        selectedVariable,
        selectedWorkflowModules,
      );
      const editedWorkflow = setModulePropertyInWorkflow(
        selectedWorkflow,
        selectedNodeId,
        workflowKey,
        selectedValue,
        selectedModuleConfig,
      );
      updateWorkflowInState(editedWorkflow);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dropDownState)]);

  const getVariableNames = (source, workflow, formComponentList) => {
    if (source === 'conditionalVariables') {
      return getConditionalVariables(workflow);
    }
    if (source === 'inputs') {
      return getWorkflowInputVariables(workflow);
    }
    return getOutputsOfModule(source, formComponentList, selectedWorkflowModules, moduleConfigs);
  };

  // Update variable list
  useEffect(() => {
    const { selectedSource } = dropDownState;
    const variables = getVariableNames(selectedSource, selectedWorkflow, formComponents);
    setVariableOptions(variables);
  }, [JSON.stringify(dropDownState), selectedModule]);

  return (
    (allowedInputCombinations.length === 0 || selectedInputCombination.includes(key)) ? (
      <div className="title-and-dropdowns">
        <h2 className="title-and-dropdowns__title">{title}</h2>
        <div className="title-and-dropdowns__dropdowns">
          <InputsDropDownRow
            input={input}
            sourceOptions={options}
            variableOptions={variableOptions}
            selectedSource={dropDownState.selectedSource}
            selectedVariable={dropDownState.selectedVariable}
            setSelectedSource={setSelectedSource}
            setSelectedVariable={setSelectedVariable}
            onUnSelect={onUnSelect}
          />
        </div>
      </div>
    )
      : null
  );
}

Inputs.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Inputs;
