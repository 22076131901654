/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './ConditionalVariableValue.scss';

import ShowConditionalVarInputOptions from './ShowConditionalVarInputOptions';
import SelectConditionalVarModuleValue from './SelectConditionalVarModuleValue';

import { selectSelectedWorkflow } from '../../../../../reducers/workflow';
import ConditionVarValueModuleInput from './ConditionVarValueModuleInput';
import CustomInputForConditionalVar from './CustomInputForConditionalVar';
import calculateButtonPosition from '../../MultiSelectDropDown/utils/CalculateButtonPosition';

function ConditionalVariableValue({
  modules, containerRef, updateConditionalVariableValue,
}) {
  const dropDownRef = useRef(null);
  const [valueType, setValueType] = useState('module');
  const [showOptionStyle, setShowOptionStyle] = useState({});
  const [showOption, setShowOption] = useState(false);
  const [OpenVariableDrawer, setOpenVariableDrawer] = useState(false);
  const [rulePosition, setRulePosition] = useState({});
  const [conditionalVar, setConditionalVar] = useState('');
  const [conditionalVarValue, setConditionalVarValue] = useState('');
  const selectedWorkflow = useSelector(selectSelectedWorkflow);

  const closeVariableDrawer = () => {
    setOpenVariableDrawer(false);
    setShowOption(false);
  };

  const handleVariableClick = (event, reference) => {
    const { clientY } = event;

    setRulePosition({
      left: parseFloat(reference.left) + parseFloat(reference.width),
      top: parseFloat(reference.top),
    });
    setOpenVariableDrawer(true);
  };

  const handleModuleSelect = (event, reference) => {
    const referenceStyle = window.getComputedStyle(reference.current);
    setValueType('module');

    handleVariableClick(event, referenceStyle);
  };

  const handleInputSelect = () => {
    setValueType('custom');
    setShowOption(false);
  };

  const updateConditionalVar = (rule) => {
    const { workflowRule, type, text } = rule;
    setConditionalVar(text);
    setConditionalVarValue(workflowRule);
    setShowOption(false);
  };

  const handleShowOptionClick = (event) => {
    setShowOption(true);

    const { offsetLeft, offsetTop } = event.target;
    const containerRect = containerRef.current;

    setShowOptionStyle({
      left: offsetLeft,
      top: offsetTop - containerRect.scrollTop + 31,
    });
  };

  const handleTextInput = (value, key, side) => {
    setConditionalVar(value);
    setConditionalVarValue(value);
  };

  useEffect(() => {
    updateConditionalVariableValue(conditionalVarValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionalVarValue]);

  return (
    <>
      <div className="conditional-var-value-dropdown" ref={dropDownRef}>
        <>
          {
         valueType && valueType === 'module' && (
         <ConditionVarValueModuleInput
           handleShowOptionClick={handleShowOptionClick}
           conditionalVar={conditionalVar}
           showOption={showOption}
         />
         )
         }
          {
         valueType && valueType === 'custom' && (
         <CustomInputForConditionalVar
           conditionalVar={conditionalVar}
           setShowFieldTypesOption={handleShowOptionClick}
           handleTextInput={handleTextInput}
         />
         )
         }
        </>
      </div>
      {showOption
       && (
       <ShowConditionalVarInputOptions
         handleModuleSelect={handleModuleSelect}
         handleInputSelect={handleInputSelect}
         showOptionStyle={showOptionStyle}
         showOption={showOption}
         setShowOption={setShowOption}
         closeVariableDrawer={closeVariableDrawer}
       />
       )}
      {OpenVariableDrawer
      && (
      <SelectConditionalVarModuleValue
        posRef={rulePosition}
        selectedRule={conditionalVar}
        closeVariableModal={(val) => setOpenVariableDrawer(!val)}
        previousModules={modules}
        selectedWorkflow={selectedWorkflow}
        updateConditionalVar={updateConditionalVar}
        setShowOption={setShowOption}
      />
      )}
    </>
  );
}

export default ConditionalVariableValue;

ConditionalVariableValue.propTypes = {
  modules: PropTypes.array.isRequired,
  containerRef: PropTypes.func.isRequired,
  updateConditionalVariableValue: PropTypes.func.isRequired,
};
