import Button from '@mui/material/Button';
import { useState } from 'react';
// import DisplayConditionalVariables from './ConditionalVariables/DisplayConditionalVariables';
import WorkflowConfigurationModal from '../WorkflowConfigurationModal';

function ConditionalVariablesButton() {
  const [showConditionalVariable, setShowConditionalVariables] = useState(false);

  const handleClick = () => {
    setShowConditionalVariables(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        className="more-options-workflow"
      >
        Conditional Variables
      </Button>
      {
        showConditionalVariable
        && (
        <WorkflowConfigurationModal
          onClose={() => { setShowConditionalVariables(false); }}
          page="conditionalVars"
        />
        )
      }
    </>
  );
}

export default ConditionalVariablesButton;
