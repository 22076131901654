import PropTypes, { object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { getSelectedModule, setModulePropertyInWorkflow, unsetModulePropertyInWorkflow } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { selectModules, selectSelectedNode, selectSelectedWorkflow } from '../../reducers/workflow';
import { setAllowedInputCombinations, setSelectedInputCombination } from '../../reducers/inputsToModule';
import { evaulateRuleForModule, getDefaultValueFromComponentConfigs } from '../../utils/helper';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import InputComponent from '../../components/ViewWorkflow/v2/InputComponent/InputComponent';
import Divider from '../../components/ViewWorkflow/Divider';
import SetNodeName from '../../components/ViewWorkflow/v2/SetNodeName/SetNodeName';
import OutputDrawer from '../../components/ViewWorkflow/v2/Outputs/OutputDrawer';
import ConfigurationModal from '../../components/ViewWorkflow/v2/ConfigurationPanel/main';
import '../../components/Workflow.scss';
import TextInput from '../../components/ImportWorkflow/TextInput';

// TODO: Add a validator to check validity of props

function PropertiesTab({
  inputs,
  configurations,
  outputs,
  modalConfig,
}) {
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];
  const dispatch = useDispatch();

  const getVisibility = (workflow, components, moduleId, moduleConfig) => {
    const visibility = {};
    const module = getSelectedModule(workflow, moduleId);
    if (module) {
      components.forEach((component) => {
        const { visible: rule, workflowKey } = component;
        const key = `${moduleId}[*]${workflowKey}`;
        visibility[key] = typeof rule === 'string' ? evaulateRuleForModule(rule, module, moduleConfig) : true;
      });
    }
    return visibility;
  };

  const onChanges = (updates, workflow, moduleId, moduleConfig) => {
    let editedWorkflow = workflow;
    updates.forEach(({ workflowKey, value }) => {
      if (value === null) {
        editedWorkflow = unsetModulePropertyInWorkflow(
          editedWorkflow,
          moduleId,
          workflowKey,
          moduleConfig,
        );
      } else {
        editedWorkflow = setModulePropertyInWorkflow(
          editedWorkflow,
          moduleId,
          workflowKey,
          value,
          moduleConfig,
        );
      }
    });
    updateWorkflowInState(editedWorkflow);
  };

  const initialVisibility = getVisibility(
    selectedWorkflow,
    [...configurations, ...inputs],
    selectedNodeId,
    selectedModuleConfig,
  );
  const [visible, setVisible] = useState(initialVisibility);
  const prevVisibleRef = useRef(initialVisibility);

  useEffect(() => {
    // Hardcoded legacy logic for requestParametersCombintation
    const requestParameters = inputs.filter((input) => input.workflowKey === 'requestParametersCombinations');

    if (requestParameters.length === 0) {
      dispatch(setAllowedInputCombinations({ inputsCombinations: [] }));
      dispatch(setSelectedInputCombination({ selectedOption: [] }));
    }
  }, [inputs]);

  useEffect(() => {
    const prevVisibleState = prevVisibleRef.current;
    // Unset those properties which aren't visible
    const updates = [];
    Object.keys(visible || {}).forEach((key) => {
      const workflowKey = key.split('[*]')[1];
      if (typeof workflowKey === 'string') {
        if (visible[key] === false) updates.push({ workflowKey, value: null });
        if (visible[key] === true && prevVisibleState[key] === false) {
          // Set default value if present
          const allComponents = [...(inputs || []), ...(configurations || [])];
          const defaultValue = getDefaultValueFromComponentConfigs(workflowKey, allComponents);
          if (defaultValue !== null) updates.push({ workflowKey, value: defaultValue });
        }
      }
    });

    onChanges(updates, selectedWorkflow, selectedNodeId, selectedModuleConfig);
    prevVisibleRef.current = visible;
  }, [JSON.stringify(visible)]);

  useEffect(() => {
    // Get visibility of all the components
    const visibility = getVisibility(
      selectedWorkflow,
      [...configurations, ...inputs],
      selectedNodeId,
      selectedModuleConfig,
    );
    setVisible(visibility);
  }, [JSON.stringify(selectedWorkflow),
    JSON.stringify(configurations),
    JSON.stringify(inputs),
    selectedNodeId]);

  return (
    <div>
      <div className="border-box">
        <h2 className="border-box__heading">INPUTS</h2>
        <div className="border-box__content">
          <SetNodeName />
          <TextInput
            label="Node Id"
            setDefaultValue
            readOnly
            placeholder={selectedNodeId}
          />
          {inputs?.length ? inputs.map((element) => {
            if (visible[`${selectedNodeId}[*]${element.workflowKey}`] === false) return null;
            return (
              <InputComponent
                key={`${selectedNodeId}_${element.workflowKey}`}
                element={element}
              />
            );
          }) : null}
        </div>
      </div>
      <Divider />
      { configurations ? (
        <>
          <div className="configuration-heading">
            {' '}
            Configurations of Module
          </div>
          <div className="elements">
            {(configurations || []).map((element) => {
              if (visible[`${selectedNodeId}[*]${element.workflowKey}`] === false) return null;
              return (
                <InputComponent
                  key={`${selectedNodeId}_${element.workflowKey}`}
                  element={element}
                />
              );
            })}
          </div>
        </>
      ) : null}
      {
        outputs ? (
          <>
            <Divider />
            <OutputDrawer heading="Output from Module" data={outputs} />
          </>
        )
          : null
      }
      {
        modalConfig ? (
          <>
            <Divider />
            <ConfigurationModal
              config={modalConfig}
            />
          </>
        ) : null
      }
    </div>
  );
}

PropertiesTab.propTypes = {
  inputs: PropTypes.instanceOf(object).isRequired,
  configurations: PropTypes.instanceOf(object).isRequired,
  outputs: PropTypes.instanceOf(object).isRequired,
  modalConfig: PropTypes.any.isRequired,
};

export default PropertiesTab;
