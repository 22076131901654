/* eslint-disable react/prop-types */
import { getSmoothStepPath } from 'reactflow';
import './Workflow.scss';

// const edgeYDisplace = 30;
// const foreignObjectSize = 150;

export default function ModuleEdge(props) {
  const {
    id,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data,
  } = props;
  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    // centerY: sourceY - edgeYDisplace,
  });
  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {
        source !== 'start'
        && (
        <foreignObject
          width={150}
          height={targetY - sourceY}
          x={edgeCenterX - 150 / 2}
          y={edgeCenterY - (targetY - sourceY) / 2}
          className="edgebutton_foreignobject"
        >
          <div className="react-flow__edge-path_div">
            <div id={`${id}_addModule_edge`} className="edge-without-box">
              <button
                type="button"
                className="edgebutton"
                onClick={() => data.showAddNodeModal(source, target, id)}
                aria-label="edge"
              />
            </div>
          </div>
        </foreignObject>
        )
      }
    </>
  );
}
