/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import { useRef } from 'react';

import sideIcon from '../../../../../assests/icons/sideIcon.svg';
import '../../NewConditionDrawer/NewConditionDrawer.scss';

function ConditionVarValueModuleInput({
  handleShowOptionClick,
  conditionalVar, showOption,
}) {
  const variableRef = useRef(null);

  return (
    <>
      <div
        className="condition__content__var__dropDown "
        onClick={(e) => handleShowOptionClick(e, variableRef)}
      >
        <div className="condition__content__var__dropDown__module" ref={variableRef}>
          {
          (conditionalVar !== 'conditionalVariable' && !showOption)
            ? conditionalVar.split('.')[0] : 'Choose module & its output variable'
          }
        </div>
        {!(showOption) && (
        <div className="condition__content__var__dropDown__var-div">
          {(conditionalVar.split('.').length <= 1) ? '' : (
            <img
              className="condition__content__var__dropDown__side-icon"
              src={sideIcon}
              alt="side icon"
            />
          )}
          <div className="condition__content__var__dropDown__module-var">
            {
            (conditionalVar.split('.').length <= 1) || conditionalVar === 'conditionalVariable'
              ? ''
              : conditionalVar.split('.')[1]
            }
          </div>
        </div>
        )}
      </div>
    </>
  );
}

export default ConditionVarValueModuleInput;

ConditionVarValueModuleInput.propTypes = {
  handleShowOptionClick: PropTypes.func.isRequired,
  conditionalVar: PropTypes.string.isRequired,
  showOption: PropTypes.bool.isRequired,
};
