import PropTypes from 'prop-types';

import closeButton from '../../../../assests/icons/close.svg';

function Header({
  titleText, saveBtnText, onSave, onClose,
}) {
  return (
    <div className="workflow_configuration_modal__header">
      <span>
        {titleText || 'Configure'}
      </span>
      <button
        type="button"
        className="workflow_configuration_modal__header_button"
        onClick={onSave}
      >
        {saveBtnText || 'Save'}
      </button>
      <button type="button" className="closeModalButton" onClick={onClose}>
        <img
          src={closeButton}
          alt="close"
        />
      </button>
    </div>
  );
}

Header.propTypes = {
  titleText: PropTypes.string.isRequired,
  saveBtnText: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
