import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import conditionDeleteIcon from '../../../../assests/icons/conditionDeleteIcon.svg';
import StringInput from '../AnyInput/StringInput';
import TypedInput from '../AnyInput/TypedInput';

function SimpleObject({
  keyName: defaultKeyName,
  value: defaultValue,
  onKeyChange,
  onValueChange,
  onDelete,
  nameLabel,
  pathLabel,
  typeLabel,
  errorText,
  typed,
  isKeyNameValid,
}) {
  const handleKeyChange = (event) => {
    const { value } = event.target;
    onKeyChange(value);
  };

  return (
    <div className="workflow_output_field">
      <div className="workflow_output_field_key">
        <TextField
          label={nameLabel}
          variant="outlined"
          value={defaultKeyName}
          onChange={handleKeyChange}
          error={!isKeyNameValid}
          helperText={errorText}
          size="small"
          className="workflow_output_field_textfield"
        />
      </div>
      {
        typed ? (
          <TypedInput
            value={defaultValue}
            onChange={onValueChange}
            inputLabel={pathLabel}
            typeLabel={typeLabel}
          />
        )
          : (
            <StringInput
              value={`${defaultValue}`}
              onChange={onValueChange}
              label={pathLabel}
            />
          )
      }

      <div className="workflow_output_field_deleteButtonDiv">
        <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
          <img
            src={conditionDeleteIcon}
            alt="delete"
          />
        </button>
      </div>
    </div>
  );
}

SimpleObject.propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onKeyChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  typed: PropTypes.bool.isRequired,
  isKeyNameValid: PropTypes.bool.isRequired,
};

export default SimpleObject;
