/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import search from '../../../../assests/icons/search.svg';

function CountriesPopup({
  options, documentList, handleOptionChange, shouldSelectCheckBox,
  togglePopup, selectAllCountries, handleConfirmClick,
}) {
  const {
    popupSearchText, popupTitle, popupSelectAllButtonText, popupDeselectButtonText,
    popupConfirmButtonText, popupCancelButtonText,
  } = options;

  const [shouldSelectAll, setShouldSelectAll] = useState(false);

  const [query, setQuery] = useState('');
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  const filteredOptions = documentList.filter(
    (country) => country.name.toLowerCase().includes(query.toLowerCase()),
  );

  const handleSelectAllClick = () => {
    const selectAll = !shouldSelectAll;
    setShouldSelectAll(selectAll);
    selectAllCountries(selectAll);
  };

  return (
    <div>
      <div className="popup">
        <div className="popup__title">{popupTitle}</div>
        <div className="popup__searchdiv">
          <div className="popup__searchdiv__searchbardiv">
            <img alt="" src={search} />
            <input
              className="popup__searchdiv__searchbar"
              type="search"
              placeholder={popupSearchText}
              onChange={handleQueryChange}
            />
          </div>
          <input
            className="popup__checklist"
            type="checkbox"
            // id={country.name}
            onChange={() => handleSelectAllClick()}
            checked={shouldSelectAll}
          />
          {/* <button
            onClick={() => handleSelectAllClick(true)}
            className="popup__searchdiv__selectAll-button"
            type="button"
          >
            {popupSelectAllButtonText}
            {' '}
          </button>
          <button
            onClick={() => handleSelectAllClick(false)}
            className="popup__searchdiv__deselectAll-button"
            type="button"
          >
            {popupDeselectButtonText}

          </button> */}
        </div>
        <div className="popup__documents-checklist-div">

          {filteredOptions.map((country) => (
            <div key={country.id}>
              <div className="popup__documents-checklist-div__country">
                <input
                  className="popup__checklist"
                  type="checkbox"
                  id={country.name}
                  onChange={() => handleOptionChange({
                    id: country.id,
                    name: country.name,
                  })}
                  checked={shouldSelectCheckBox({
                    id: country.id,
                    name: country.name,
                  })}
                />
                <div className="popup__countries-and-flag">
                  <label className="popup__documents-checklist-div__country__label" htmlFor={country.name}>
                    {country.name}
                  </label>
                  <img className="popup__countries-flag" src={`https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/flags/${country.id}.png`} alt={country.id} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="popup__footer">
          <button className="popup__cancel-button" type="button" onClick={togglePopup}>{popupCancelButtonText}</button>
          <button className="popup__confirm-button" type="button" onClick={() => handleConfirmClick(shouldSelectAll)}>{popupConfirmButtonText}</button>
        </div>

      </div>
      <div className="modal" onClick={togglePopup} />
    </div>
  );
}

export default CountriesPopup;

CountriesPopup.propTypes = {
  options: PropTypes.instanceOf(object).isRequired,
  documentList: PropTypes.instanceOf(object).isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  shouldSelectCheckBox: PropTypes.func.isRequired,
  togglePopup: PropTypes.func.isRequired,
  selectAllCountries: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
};
