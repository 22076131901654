import { useState } from 'react';
import PropTypes from 'prop-types';
import deleteImg from '../../assests/icons/deleteIcon3.svg';
import addImg from '../../assests/icons/addIcon.svg';

function ListFormModuleComponents(props) {
  const {
    formComponents,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    formComponentsConfig,
    selectedComponentPath,
  } = props;

  const [draggedIndex, setDraggedIndex] = useState();
  const clickHandler = (pathArr) => {
    handleOnClick(pathArr);
  };

  const deleteClickHandler = (pathArr) => {
    handleOnDelete(pathArr);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (dropIndex) => {
    // Ensures that drag and drop happens only within the same level
    if (typeof draggedIndex === 'number' && typeof dropIndex === 'number') {
      handleOnDrag([draggedIndex], [dropIndex]);
    }
  };

  const getSelectedIndexForChild = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex[0] === currentIndex) {
      const newSelectedIndexArr = [...currentSelectedIndex];
      newSelectedIndexArr.shift();
      return newSelectedIndexArr;
    }
    return [];
  };

  const isCurrentComponentSelected = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex.length === 1 && currentSelectedIndex[0] === currentIndex) return true;
    return false;
  };

  return (
    <div className="component-list-div-children">
      {
   (formComponents || []).map((component, index) => {
     const key = (formComponentsConfig.filter(
       (comp) => comp.type === component.type,
     )[0]
     )?.primaryBrandingKey;
     if (component.type === 'vertical' || component.type === 'horizontal') {
       return (
         <div
           key={`${component.id}`}
           role="menuitem"
           tabIndex={0}
           onKeyUp={() => {}} // currently not handling keyboard events
           draggable
           onDragStart={(event) => { event.stopPropagation(); handleDragStart(index); }}
           onDragOver={handleDragOver}
           onDrop={(event) => { event.stopPropagation(); handleDrop(index); }}
           onClick={(event) => { event.stopPropagation(); clickHandler([index]); }}
         >
           <div className="sub-component-list-div">
             <div className="sub-component-heading">
               <div className="sub-component-list-div__heading">
                 {component?.id}
               </div>
               <button
                 className="sub-component-add-btn"
                 onClick={(event) => { event.stopPropagation(); deleteClickHandler([index]); }}
                 type="button"
               >
                 <img src={deleteImg} alt="delete" />
               </button>
               <button
                 className="sub-component-add-btn"
                 onClick={(event) => { event.stopPropagation(); handleOnAdd([index]); }}
                 type="button"
               >
                 <img src={addImg} alt="add" />
               </button>
             </div>
             <ListFormModuleComponents
               formComponents={component?.subComponents}
               handleOnClick={(path) => clickHandler([index, ...path])}
               handleOnDelete={(path) => deleteClickHandler([index, ...path])}
               handleOnAdd={(path) => handleOnAdd([index, ...path])}
               selectedComponentPath={getSelectedIndexForChild(selectedComponentPath, index)}
               formComponentsConfig={formComponentsConfig}
               handleOnDrag={(dragPath, dropPath) => {
                 handleOnDrag([index, ...dragPath], [index, ...dropPath]);
               }}
             />
           </div>

         </div>
       );
     }

     return (
       <div
         key={`${component.id}`}
         role="menuitem"
         tabIndex={0}
         onKeyUp={() => {}} // currently not handling keyboard events
         className={`component-name${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}`}
         onClick={(event) => { event.stopPropagation(); clickHandler([index]); }}
         draggable
         onDragStart={(event) => { event.stopPropagation(); handleDragStart(index); }}
         onDragOver={handleDragOver}
         onDrop={(event) => { event.stopPropagation(); handleDrop(index); }}
       >
         <div className="component-name-label">
           {component[key] || component.text || component.title || component.id}
         </div>
         <button
           type="button"
           onClick={(event) => {
             event.stopPropagation();
             deleteClickHandler([index]);
           }}
           className="component-name-dlt-btn"
         >
           <img src={deleteImg} alt="delete" />
         </button>
       </div>
     );
   })
}
    </div>
  );
}

export default ListFormModuleComponents;

ListFormModuleComponents.propTypes = {
  formComponents: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentPath: PropTypes.array.isRequired,
};
