import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useMemo, useState, useRef, useEffect,
} from 'react';

import { findPreviousModulesWithNames, getStringInputDisplayValue, isModuleVariable } from '../InputsToModule/utils/findPreviousModules';
import getOutputsOfModule from '../InputsToModule/utils/getModuleOutputs';
import downIcon from '../../../../assests/icons/downIcon.svg';
import DropList from './DropList';
import { defaultInputs } from '../InputsToModule/utils';

// INFO: Source taken from workflow inputs and udpated to configure latest modules.

function CustomStringSelection({
  value,
  moduleConfigs,
  setOutputValue,
  label,
  orderOfModules,
  selectedNodeId,
  selectedWorkflow,
  formComponents,
}) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedPrimaryItem, setPrimaryItem] = useState(null);
  const [secondaryList, setSecondaryList] = useState([]);
  const [selectedSecondaryItem, setSecondaryItem] = useState(null);
  const [tertiaryList, setTertiaryList] = useState([]);
  const inputRef = useRef();

  const { modules, conditionalVariables } = selectedWorkflow;

  const displayValue = useMemo(() => getStringInputDisplayValue(
    value,
    orderOfModules,
    selectedNodeId,
    selectedWorkflow,
  ), [selectedWorkflow, value, orderOfModules, selectedNodeId]);

  const isCustomInput = useMemo(() => {
    const arr = value.split('.');
    return !(isModuleVariable(arr[0], modules) || arr[0].startsWith('conditionalVariable') || arr[0].startsWith('inputs'));
  }, [value]);

  const closeDropDown = () => {
    setPrimaryItem(null);
    setSecondaryList([]);
    setSecondaryItem(null);
    setTertiaryList([]);
    setShowDropDown(false);
  };

  const setCustomInputValue = (outputValue) => {
    closeDropDown();
    setOutputValue(outputValue);
  };

  const onDropDown = (event) => {
    event.stopPropagation();
    if (showDropDown) {
      closeDropDown();
    } else setShowDropDown(true);
  };

  const getSelectedModuleOutputList = (
    source,
    componentsOfForm,
    inputModules,
    inputModuleConfigs,
  ) => {
    const outputsOfModule = getOutputsOfModule(
      source,
      componentsOfForm,
      inputModules,
      inputModuleConfigs,
    );
    const list = (outputsOfModule || []).map(({ id, name }) => ({
      id, label: name,
    }));
    return list;
  };

  const mainListItems = [
    { label: 'Module Outputs', id: 'moduleOutputs' },
    { label: 'Workflow Inputs', id: 'workflowInputs' },
    { label: 'Conditional Variables', id: 'conditionalVariables' },
    { label: 'Custom Inputs', id: 'customInputs' },
  ];

  const isMainMenuItemDisabled = (item, inputsList, conditionalVars) => {
    if (item === 'moduleOutputs') return false;
    if (item === 'workflowInputs') return inputsList?.length === 0;
    if (item === 'conditionalVariables') return Object.keys(conditionalVars || {}).length === 0;
    if (item === 'customInputs') return false;
    return false;
  };

  const mainMenuHandler = (item) => {
    setPrimaryItem(item);
    if (item === 'customInputs') {
      closeDropDown();
      setOutputValue('');
      inputRef.current.querySelector('input[type="text"]').focus();
    }
  };

  const secondaryMenuHandler = (primaryItem, secondaryItem) => {
    if (primaryItem === 'moduleOutputs') {
      setSecondaryItem(secondaryItem);
    } else if (primaryItem === 'workflowInputs' || primaryItem === 'conditionalVariables') {
      closeDropDown();
      const outputValue = `${primaryItem === 'workflowInputs' ? 'inputs' : 'conditionalVariables'}.${secondaryItem}`;
      setOutputValue(outputValue);
    }
  };

  const tertiaryMenuHandler = (primaryItem, secondaryItem, tertiaryItem) => {
    if (primaryItem === 'moduleOutputs') {
      const outputValue = `${secondaryItem}.${tertiaryItem}`;
      setOutputValue(outputValue);
    }
    closeDropDown();
  };

  const getModuleOutputList = (orderOfNodes, selectedModuleId, workflow) => {
    const indexOfCurrentModule = (orderOfNodes || []).findIndex(
      (module) => module.id === selectedModuleId,
    );
    if (indexOfCurrentModule === undefined) return [];
    const previousModules = findPreviousModulesWithNames(
      orderOfModules,
      indexOfCurrentModule,
      workflow,
    );
    const list = (previousModules || []).map((module) => ({
      id: module.id,
      label: module.name || module.id,
    }));
    return list;
  };

  const getConditionalVariableList = (conditionalVars) => {
    const list = Object.keys(conditionalVars || {}).map((variable) => ({
      id: variable,
      label: conditionalVars[variable].name || variable,
    }));
    return list;
  };

  const getWorkflowInputList = (inputs) => {
    const workflowInputs = Object.keys(inputs || {})
      .map((input) => ({
        id: input,
        label: input,
      }));
    // TODO: Remove this function post unification of drop downs
    defaultInputs.forEach((input) => {
      workflowInputs.push({ id: input, label: input });
    });
    return workflowInputs;
  };

  useEffect(() => {
    const moduleOutputList =
      getSelectedModuleOutputList(selectedSecondaryItem, formComponents, modules, moduleConfigs);
    setTertiaryList(moduleOutputList);
  }, [selectedSecondaryItem, formComponents, modules, moduleConfigs]);

  useEffect(() => {
    let list = [];
    if (selectedPrimaryItem === 'moduleOutputs') list = getModuleOutputList(orderOfModules, selectedNodeId, selectedWorkflow);
    else if (selectedPrimaryItem === 'workflowInputs') list = getWorkflowInputList(selectedWorkflow.properties?.inputsRequired);
    else if (selectedPrimaryItem === 'conditionalVariables') list = getConditionalVariableList(conditionalVariables);
    setSecondaryList(list);
  }, [selectedPrimaryItem, orderOfModules, selectedNodeId, selectedWorkflow, conditionalVariables]);

  return (
    <div className="workflow_output_field_dropdown">
      <TextField
        label={label}
        variant="outlined"
        value={displayValue}
        size="small"
        className="workflow_output_field_select"
        InputProps={{
          readOnly: !isCustomInput,
        }}
        onChange={(event) => setCustomInputValue(event.target.value)}
        ref={inputRef}
      />
      <button
        type="button"
        className="workflow_output_field_dropdown_button"
        onClick={onDropDown}
      >
        <img
          src={downIcon}
          alt="down"
        />
      </button>
      {
        showDropDown ? (
          <div className="workflow_output_field_dropdown_mainList">
            <DropList
              list={mainListItems || []}
              isDisabledFn={(id) => isMainMenuItemDisabled(
                id,
                getWorkflowInputList(selectedWorkflow?.properties?.inputsRequired),
                conditionalVariables,
              )}
              onItemClick={(id) => { mainMenuHandler(id); }}
              shouldShowArrowFn={() => true}
            />
          </div>
        ) : null
      }
      {
        showDropDown && secondaryList.length ? (
          <div className="workflow_output_field_dropdown_conditionalVariables">
            <DropList
              list={secondaryList || []}
              isDisabledFn={() => false}
              onItemClick={(id) => { secondaryMenuHandler(selectedPrimaryItem, id); }}
              shouldShowArrowFn={(id) => isModuleVariable(id, modules)}
            />
          </div>
        ) : null
      }
      {
        showDropDown && selectedPrimaryItem && tertiaryList.length ? (
          <div className="workflow_output_field_dropdown_selectedModule">
            <DropList
              list={tertiaryList || []}
              isDisabledFn={() => false}
              onItemClick={(id) => {
                tertiaryMenuHandler(selectedPrimaryItem, selectedSecondaryItem, id);
              }}
              shouldShowArrowFn={() => false}
            />
          </div>
        ) : null
      }
    </div>
  );
}

CustomStringSelection.propTypes = {
  value: PropTypes.string.isRequired,
  setOutputValue: PropTypes.func.isRequired,
  moduleConfigs: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  formComponents: PropTypes.array.isRequired,
};

export default CustomStringSelection;
