import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';
import './EditLogo.scss';

function EditLogo(props) {
  const { updateLogo } = props;
  const [selectedLogo, setSelectedLogo] = useState(null);

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setSelectedLogo(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (selectedLogo) {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedLogo.type });
        updateLogo(blob);
      };
      reader.readAsArrayBuffer(selectedLogo);
    }
  }, [selectedLogo]);

  return (
    <div className="add-logo-div">
      <div className="add-logo-div__name">Add logo</div>
      <input
        className="add-logo-div__upload-button"
        type="file"
        accept=".jpeg, .png, .jpg"
        onChange={handleFileUpload}
      />
    </div>
  );
}

export default EditLogo;

EditLogo.propTypes = {
  updateLogo: PropTypes.func.isRequired,
};
