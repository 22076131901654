/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import {
  useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { PermissionWrapper } from 'storybook-ui-components';
import {
  useNodesState,
  useEdgesState,
  MarkerType,
  ReactFlowProvider,
} from 'reactflow';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cloneDeep } from 'lodash';
import './Container.scss';
import convertToNodesEdges from '../components/utils';
import { DRAWER_TYPES } from '../components/constants';
import GenericModuleDrawer from '../components/ViewWorkflow/GenericModuleDrawer';
import DefaultDrawer from '../components/ViewWorkflow/DefaultDrawer';
import ModuleNode from '../components/ModuleNode';
import ApproveNode from '../components/ApproveNode';
import ConditionNode from '../components/ConditionNode';
import DeclineNode from '../components/DeclineNode';
import OutputDrawer from '../components/ViewWorkflow/OutputDrawer';
import ConditionEdge from '../components/ConditionEdge.js';
import ModuleEdge from '../components/ModuleEdge';
import ELKLayout from '../components/ViewWorkflow/ELKLayout';
import useGetUserPermissions from '../Permissions/hooks';
import getPermission from '../Permissions/mapping';
import NewBaseDrawer from '../components/ViewWorkflow/v2/NewBaseDrawer';

import {
  updateOrderOfNodes,
  updateSelectedNode,
  selectSelectedWorkflow,
  selectIsWorkflowEdited,
  selectOrderOfNodes,
  selectSelectedNode,
} from '../reducers/workflow';

import PublishWorkflow from '../components/ViewWorkflow/v2/PublishWorkflow';
import DemoWorkflow from '../components/ViewWorkflow/v2/DemoWorkflow';
import StartNode from '../components/StartNode';
import ReviewNode from '../components/ReviewNode';
import AddNodeModal from '../components/AddNodeModal';
import EditNodeModal from '../components/EditNodeModal';
import GotoNode from '../components/GotoNode';
import StartDrawer from '../components/ViewWorkflow/StartDrawer';
import MoreWorkflowOptions from '../components/ViewWorkflow/v2/MoreWorkflowOptions';
import {
  addNewConditionInWorkflow, addNewNodeInWorkflow, deleteConditionFromWorkflow,
  deleteNodeFromWorkflow,
  updateGotoInWorkflow,
} from './workflowOperations';
import { updateWorkflowInState } from '../workflowOperations/updateWorkflow';
import Modal from '../components/Common/Modal';
import SingleSelectDropdown from '../components/Common/SingleSelectDropdown';
import GotoDrawer from '../components/ViewWorkflow/GotoDrawer';
import { getConditionAndModuleItems } from '../utils/helper';
import withDeletionDependencyCheck from '../utils/withDeletionDependencyCheck.js';
import { selectCustomUIConfig, selectDefaultUIConfig, updateCustomUiConfig } from '../reducers/editBranding';
import { setDefaultUIConfigsForSuperModule } from './uiConfigOperations';
import { logEndStateUpdate } from '../logger/logHighLevelWorkflowUpdates';
import { selectDefaultFormSections } from '../reducers/dynamicForm.js';

let localOrderOfNodes = [];
function ViewWorkflow({ checkDependencies: checkDeletionDependencies }) {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [workflowName, setWorkflowName] = useState('');
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.none);
  const [addNodeBetween, setAddNodeBetween] = useState(null);
  const [editEndStateParent, setEditEndStateParent] = useState(null);
  const [showGotoModal, setShowGotoModal] = useState(null);
  const [newGotoTargetModuleId, setNewGotoTargetModuleId] = useState('');
  const [newNodeAdded, setNewNodeAdded] = useState(null);

  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const countryDocMapping = useSelector((state) => state.workflow.countryDocMapping);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const workflowConfig = useSelector(selectSelectedWorkflow);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const isWorkflowEdited = useSelector(selectIsWorkflowEdited);
  const defaultFormSections = useSelector(selectDefaultFormSections);
  const selectedNode = useSelector(selectSelectedNode);
  const navigate = useNavigate();

  const workflowId = new URLSearchParams(search).get('id');

  const resetDrawerType = () => {
    setDrawerType(DRAWER_TYPES.none);
    dispatch(updateSelectedNode({ selectedNode: {} }));
  };

  const showAddNodeModal = (parent, child, id) => {
    const edgeElement = document.getElementById(`${id}_addModule_edge`);
    edgeElement.removeAttribute('class');
    edgeElement.classList.add('edge-with-box');
    setAddNodeBetween({
      parent,
      child,
      edgeId: id,
    });
  };
  const closeAddNodeModal = () => {
    const edgeElement = document.getElementById(`${addNodeBetween.edgeId}_addModule_edge`);
    if (edgeElement) {
      edgeElement.removeAttribute('class');
      edgeElement.classList.add('edge-without-box');
    }
    setAddNodeBetween(null);
  };

  const showDeleteNodeModal = (id, parentId, workflow) => {
    const isDependent = checkDeletionDependencies({ nodeId: id, workflow });
    if (isDependent) return;
    deleteNode(id, parentId, workflow, customUiConfig, defaultUiConfig);
  };

  const updateGoto = (parentNodeId, parentBranch, updatedGotoModule, workflow) => {
    const editedWorkflow = updateGotoInWorkflow(parentNodeId, parentBranch, updatedGotoModule, workflow);
    updateWorkflowInState(editedWorkflow);
  };

  const onCreateGoto = (gotoNodeDetails, newGotoModule, workflow) => {
    const { id: parentNodeId, parentBranch, parentPath } = gotoNodeDetails;
    updateGoto(parentNodeId, parentBranch || parentPath, newGotoModule, workflow);
    resetDrawerType();
    setNewGotoTargetModuleId('');
    setShowGotoModal(null);
  };

  const displayGotoModal = () => {
    const items = getConditionAndModuleItems(workflowConfig);
    return (
      <div className="goto-modal_content">
        <SingleSelectDropdown items={items} onElementSelect={(value) => setNewGotoTargetModuleId(value)} overrideDropdownStyles="single-select-dropdown_override" />
        <button
          type="button"
          className="goto-modal_content__button"
          disabled={!newGotoTargetModuleId}
          onClick={() => onCreateGoto(showGotoModal, newGotoTargetModuleId, workflowConfig)}
        >
          Submit
        </button>
      </div>
    );
  };

  const showEditNodeModal = (obj) => {
    setEditEndStateParent(obj);
  };

  const onEditEndState = (type, selectedWorkflow) => {
    const { id, parentBranch, parentPath } = editEndStateParent;
    if (type === 'goto') {
      setShowGotoModal({ id, parentBranch, parentPath });
      setEditEndStateParent(null);
      return;
    }
    let currentEndState = null;
    const workflow = cloneDeep(selectedWorkflow);
    // set the nextstep of the parent
    workflow.modules.forEach((module) => {
      if (module.id === id) {
        module.nextStep = type;
        module.next_node_type = {};
      }
    });
    const newConditions = {};
    const { conditions } = workflow;
    Object.entries(conditions).forEach(([conditionId, value]) => {
      newConditions[conditionId] = { ...value };
      if (conditionId === id) {
        currentEndState = newConditions[conditionId][parentBranch];
        newConditions[conditionId][parentBranch] = type;
        if (newConditions[conditionId].next_node_type && Object.keys(newConditions[conditionId].next_node_type).length) {
          newConditions[conditionId].next_node_type[parentBranch] = '';
        }
      }
    });
    workflow.conditions = newConditions;
    // setWorkflowConfig(workflow);

    updateWorkflowInState(workflow);
    resetDrawerType();
    setEditEndStateParent(null);
    logEndStateUpdate({
      id,
      parentBranch,
      oldEndState: currentEndState,
      newEndState: type,
    });
  };

  const addUIPropertiesNodes = (workflowNodes) => {
    const uiAddedNodes = workflowNodes.map((node) => {
      try {
        const properties = workflowModules[`${node.nodeType}_uiConfig`]
        || workflowModules[node.nodeType];
        if (node.nodeType === 'start') {
          return {
            ...node,
            type: 'startNode',
            className: 'start_node',
            style: {
              height: 40,
              width: 80,
            },
          };
        }
        if (node.nodeType === 'goto') {
          return {
            ...node,
            type: 'gotoNode',
            className: 'goto_node',
            style: {
              height: 40,
              width: 180,
            },
            data: {
              id: node.id,
              name: node.name,
              parentBranch: node.parentBranch,
              parentId: node.parentId,
              showEditNodeModal,
            },
          };
        }
        if (node.nodeType === 'condition') {
          return {
            ...node,
            type: 'conditionNode',
            // data: node.ruleArr[0],
            className: 'conditions_module',
            style: {
              height: 40,
              width: 180,
            },
            data: {
              id: node.id,
              deleteCondition,
              name: node.name,
              parentId: node.parentId,
            },
          };
        }

        if (node.nodeType === 'approve') {
          return {
            ...node,
            type: 'approveNode',
            className: 'approve_node',
            style: {
              height: 40,
              width: 105,
            },
            data: {
              id: node.id,
              parentBranch: node.parentBranch,
              parentId: node.parentId,
              showEditNodeModal,
            },
          };
        }
        if (node.nodeType === 'manualReview') {
          return {
            ...node,
            type: 'reviewNode',
            className: 'review_node',
            style: {
              height: 40,
              width: 105,
            },
            data: {
              id: node.id,
              parentBranch: node.parentBranch,
              parentId: node.parentId,
              showEditNodeModal,
            },
          };
        }
        if (node.nodeType === 'decline') {
          return {
            ...node,
            type: 'declineNode',
            className: 'decline_node',
            style: {
              height: 40,
              width: 105,
            },
            data: {
              id: node.id,
              parentBranch: node.parentBranch,
              parentId: node.parentId,
              showEditNodeModal,
            },
          };
        }
        return {
          ...node,
          type: 'moduleNode',
          className: 'nodes__module',
          style: {
            height: 40,
            width: 150,
          },
          // eslint-disable-next-line max-len
          data: {
            name: node.name,
            nodeType: node.nodeType,
            showDeleteNodeModal,
            id: node.id,
            parentId: node.parentId,
            icon: properties?.icon || '',
          },
        };
      } catch (error) {
        throw new Error(`Error loading config: module ${node.nodeType} does not exist`);
      }
    });
    return uiAddedNodes;
  };
  const addUIPropertiesEdges = (workflowNodes) => {
    const uiAddedEdges = workflowNodes.map((edge) => ({
      ...edge,
      type: edge.type || 'step',
      animated: false,
      markerEnd: {
        type: MarkerType.Arrow,
      },
      data: {
        showAddNodeModal,
        ...edge.data,
      },
    }));
    return uiAddedEdges;
  };

  const deleteCondition = (id, parentId, selectedWorkflow) => {
    // set drawer type
    dispatch(updateSelectedNode({ selectedNode: {} }));
    setDrawerType(DRAWER_TYPES.none);
    const workflow = deleteConditionFromWorkflow(id, parentId, selectedWorkflow, localOrderOfNodes);
    updateWorkflowInState(workflow);
  };

  const deleteUIConfigOfModule = (moduleId, currUiConfig) => {
    const editedUiConfig = cloneDeep(currUiConfig);
    delete editedUiConfig[moduleId];
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
  };

  const deleteNode = (id, parentId, selectedWorkflow, customUiConfig, defaultUiConfig) => {
    dispatch(updateSelectedNode({ selectedNode: {} }));
    resetDrawerType();
    const workflow = deleteNodeFromWorkflow(id, parentId, selectedWorkflow, localOrderOfNodes);
    const moduleToBeDeleted = selectedWorkflow.modules.find((module) => module.id === id);
    // Assumption: Only dynamic form module supports custom UI config edits for each component.
    if (moduleToBeDeleted && moduleToBeDeleted.type === 'dynamicForm') {
      const currUiConfig = customUiConfig && Object.keys(customUiConfig).length > 0
        ? customUiConfig
        : defaultUiConfig;
      deleteUIConfigOfModule(id, currUiConfig);
    }
    // setWorkflowConfig(workflow);
    updateWorkflowInState(workflow);
  };

  const getUiConfig = () => (
    customUiConfig && Object.keys(customUiConfig).length > 0 ?
      customUiConfig :
      defaultUiConfig
  );

  const addNewNode = (node) => {
    closeAddNodeModal();
    const { editedWorkflow: workflow, highLevelUiConfig, newModule } = addNewNodeInWorkflow(node, addNodeBetween, nodes, workflowConfig, countryDocMapping, localOrderOfNodes, defaultFormSections);
    // add UI configs as well
    if (Object.keys(highLevelUiConfig || {}).length) {
      const currentUiConfig = getUiConfig();
      const updatedHighLevelUiConfig = setDefaultUIConfigsForSuperModule(currentUiConfig, highLevelUiConfig);
      // set uiConfigSource to custom
      workflow.properties.uiConfigSource = 'custom';
      dispatch(updateCustomUiConfig({ uiConfig: updatedHighLevelUiConfig }));
    }
    updateWorkflowInState(workflow);
    setNewNodeAdded(newModule.id);
  };

  const addNewCondition = () => {
    const { workflow, newConditionId } = addNewConditionInWorkflow(addNodeBetween, nodes, workflowConfig, localOrderOfNodes);
    updateWorkflowInState(workflow);
    closeAddNodeModal();
    setNewNodeAdded(newConditionId);
  };

  const onNodeClick = (_event, node) => {
    let DRAWER_TYPE;
    if (node.nodeType === 'start') {
      DRAWER_TYPE = DRAWER_TYPES.start;
    } else if (node.nodeType === 'goto') {
      DRAWER_TYPE = DRAWER_TYPES.goto;
    } else {
      DRAWER_TYPE = workflowModules[node.nodeType]?.DRAWER_TYPE || DRAWER_TYPES.none;
    }
    edges.forEach((edge) => {
      const edgeElement = document.getElementById(`${edge.id}_button_edge`);
      if (edge.source === node.id && edgeElement) {
        edgeElement.removeAttribute('class');
        edgeElement.classList.add('active-condition');
      } else if (edgeElement) {
        edgeElement.removeAttribute('class');
        edgeElement.classList.add('branch-name');
      }
    });
    dispatch(updateSelectedNode({ selectedNode: node }));
    setDrawerType(DRAWER_TYPE);
  };

  const edgeTypes = useMemo(() => (
    {
      conditionEdge: ConditionEdge,
      moduleEdge: ModuleEdge,
    }), []);

  const nodeTypes = useMemo(() => (
    {
      moduleNode: ModuleNode,
      approveNode: ApproveNode,
      declineNode: DeclineNode,
      conditionNode: ConditionNode,
      startNode: StartNode,
      reviewNode: ReviewNode,
      gotoNode: GotoNode,
    }), []);

  useEffect(() => {
    localOrderOfNodes = [...orderOfNodes];
  }, [orderOfNodes]);

  useEffect(() => {
    setWorkflowName(workflowConfig?.properties?.name || workflowConfig?.name || 'Workflow');

    const converted = convertToNodesEdges(workflowConfig);
    dispatch(updateOrderOfNodes({ nodes: converted.nodes }));
    const uiAddedNodes = addUIPropertiesNodes(converted.nodes);
    setNodes(uiAddedNodes);
    const uiAddedEdges = addUIPropertiesEdges(converted.edges);
    setEdges(uiAddedEdges);
    if (newNodeAdded) {
      const newNode = uiAddedNodes.find((node) => node.id === newNodeAdded);
      onNodeClick(null, newNode);
      setNewNodeAdded(null);
    }
    if (selectedNode && Object.keys(selectedNode).length) {
      const isOldSelectedNodeAvailable = converted.nodes.find((node) => node.id === selectedNode.id);
      if (isOldSelectedNodeAvailable) return;
      // Else goto node's module was updated
      const oldSelectedNodeIndex = orderOfNodes.findIndex((node) => node.id === selectedNode.id);
      if (converted.nodes[oldSelectedNodeIndex]) {
        dispatch(updateSelectedNode({ selectedNode: converted.nodes[oldSelectedNodeIndex] }));
      }
    }
  }, [workflowConfig, newNodeAdded]);

  let drawerContent = (
    <DefaultDrawer workflowConfig={workflowConfig} workflowId={workflowId} workflowName={workflowName} />
  );
  if (drawerType === DRAWER_TYPES.start) {
    drawerContent = (
      <StartDrawer workflowConfig={workflowConfig} />
    );
  } else if (drawerType === DRAWER_TYPES.goto) {
    const items = getConditionAndModuleItems(workflowConfig);
    drawerContent = (
      <GotoDrawer
        workflowConfig={workflowConfig}
        selectedNode={selectedNode}
        items={items}
        updateGoto={(parentNodeId, parentBranch, updatedGotoModule) => updateGoto(parentNodeId, parentBranch, updatedGotoModule, workflowConfig)}
      />
    );
  } else if (drawerType === DRAWER_TYPES.api_drawer) {
    drawerContent = (
      <GenericModuleDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
      />
    );
  } else if (drawerType === DRAWER_TYPES.condition) {
    drawerContent = (
      <NewBaseDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
        workflowId={workflowId}
        uiConfig={workflowModules[`${selectedNode.nodeType}_uiConfig`]}
      />
    );
  } else if (drawerType === DRAWER_TYPES.output) {
    drawerContent = (
      <OutputDrawer selectedNode={selectedNode} />
    );
  } else if (drawerType === 'new') {
    drawerContent = (
      <NewBaseDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
        workflowId={workflowId}
        uiConfig={workflowModules[`${selectedNode.nodeType}_uiConfig`]}
      />
    );
  }

  const goBack = () => {
    // TODO: fix isEdited at all places
    let shouldGoBack = true;
    if (isWorkflowEdited) {
      // TODO: show modal for this alert
      shouldGoBack = window.confirm('Save the changes or it will be lost forever!');
    }
    if (shouldGoBack) {
      navigate('/');
    }
  };

  return (
    <>
      {addNodeBetween
        ? (
          <AddNodeModal
            onClose={closeAddNodeModal}
            addNewNode={addNewNode}
            addNewCondition={addNewCondition}
          />
        )
        : null}
      {
        editEndStateParent
          ? (
            <EditNodeModal
              onClose={() => setEditEndStateParent(null)}
              onEditEndState={onEditEndState}
            />

          )
          : null
      }
      {
        showGotoModal
          ? (
            <Modal isOpen={Object.keys(showGotoModal || []).length} onClose={() => setShowGotoModal(null)} headerText="Select the module to Go To:">
              {displayGotoModal()}
            </Modal>
          )
          : null
      }

      <div className="view_workflow__container">
        <Grid container className="view_workflow_fullHeading">
          <Grid item xs={8} className="view_workflow__heading_container">
            <ArrowBackIcon onClick={goBack} sx={{ color: 'rgba(5, 5, 82, 0.5)' }} />
            <div className="view_workflow__heading">
              <div className="view_workflow__heading_name">
                {workflowName}
              </div>
              <div className="view_workflow__heading_id">
                {`Workflow ID: ${workflowId}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className="try-workflow__publish-workflow">
            <PublishWorkflow />
            <DemoWorkflow workflowId={workflowId} />
            <MoreWorkflowOptions />
          </Grid>
        </Grid>
        <div className="view_workflow__body_container">
          <div className="view_workflow__workflow_container">
            {nodes.length && edges.length ? (
              <ReactFlowProvider>
                <ELKLayout
                  initialNodes={nodes}
                  initialEdges={edges}
                  edgeTypes={edgeTypes}
                  nodeTypes={nodeTypes}
                  onNodeClick={onNodeClick}
                  resetDrawerType={resetDrawerType}
                />
              </ReactFlowProvider>
            ) : ''}
          </div>
          {drawerContent}
        </div>
      </div>
    </>
  );
}

ViewWorkflow.propTypes = {
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(PermissionWrapper(ViewWorkflow, useGetUserPermissions, getPermission('workflowDetailsPage')));
