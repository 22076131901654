import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../Workflow.scss';
import { useSelector } from 'react-redux';

function ModuleOutput({ selectedNode }) {
  const [moduleOutputs, setModuleOutputs] = useState({});
  const workflowModules = useSelector((state) => state.workflow.modules);

  useEffect(() => {
    if (workflowModules[selectedNode.nodeType].uiConfig.moduleOutput) {
      setModuleOutputs(workflowModules[selectedNode.nodeType].uiConfig.moduleOutput);
    } else { setModuleOutputs({}); }
  }, [selectedNode]);

  if (Object.keys(moduleOutputs).length === 0) {
    return (
      <Grid item xs={12} className="drawer_module_output__container">
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Module Output</div>
          </Grid>
          <Grid item xs={12}>
            <div className="drawer_content__subheading">This module does not output any fields that will be used in rest of the workflow</div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12} className="drawer_module_output__container">
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Module Output</div>
          </Grid>
          <Grid item xs={12}>
            <div className="drawer_content__subheading">Output fields that rest of the workflow can use are listed below:</div>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} className="drawer_table__container">
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  key="country"
                  className="drawer_table__heading"
                >
                  Field Name
                </TableCell>
                <TableCell
                  key="document_supported"
                  className="drawer_table__heading"
                >
                  Field&apos;s Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(moduleOutputs).map(([key, value]) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                  <TableCell key={`${key}_country`} className="drawer_content__text">
                    {value.name}
                  </TableCell>
                  <TableCell key={`${key}_documents`} className="drawer_content__text">
                    {value.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}

ModuleOutput.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModuleOutput;
