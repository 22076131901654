/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';

const updateDocumentsInWorkflow = (
  selectedWorkflow,
  finalCountriesSelected,
  documentList,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const countries = finalCountriesSelected.map((country) => country.id);
  editedWorkflow.modules.forEach((module, index) => {
    if (module.type === 'document') {
      const currentDocuments = module.properties.documentsSupported;
      const filtered = Object.keys(currentDocuments)
        .filter((key) => countries.includes(key))
        .reduce((obj, key) => {
          obj[key] = currentDocuments[key];
          return obj;
        }, {});
      countries.forEach((country) => {
        if (!filtered[country]) {
          const [doc, ..._rest] = documentList.filter((document) => document.id === country);
          filtered[country] = doc?.documents.map((document) => document.id);
        }
      });
      editedWorkflow.modules[index].properties
        .documentsSupported = filtered;
    }
  });
  return editedWorkflow;
};

export default updateDocumentsInWorkflow;
