import { cloneDeep } from 'lodash';
import replaceNextStepInConditions from './replaceNextStepInConditions';

export const checkAndUpdateNextStepsInForm = (components, replacementMap) => {
  if (!components?.length || typeof replacementMap !== 'object') return [];
  const clonnedComponents = cloneDeep(components);
  clonnedComponents.forEach((component, index) => {
    if (component?.onClick?.nextStep) {
      const nextStep = component.onClick?.nextStep;
      if (nextStep && replacementMap[nextStep]) {
        clonnedComponents[index].onClick.nextStep = replacementMap[nextStep];
      }
    } else if (component?.subComponents?.length) {
      const currentSubComponents = component?.subComponents || [];
      const updatedSubComponents =
          checkAndUpdateNextStepsInForm(currentSubComponents, replacementMap);
      clonnedComponents[index].subComponents = updatedSubComponents;
    }
  });
  return clonnedComponents;
};

const replaceNextStepId = (currentWorkflow, replacements) => {
  let workflow = cloneDeep(currentWorkflow);
  // Optimize to O(n)
  const replacementMap = {};
  replacements.forEach((replacement) => {
    const { key, value } = replacement;
    replacementMap[key] = value;
  });
  workflow.modules.forEach((module, index) => {
    if (module.type === 'dynamicForm') {
      // Assumption: Will always have only one section in form
      const currentComponents = module?.properties?.sections?.[0]?.components;
      if (currentComponents?.length) {
        const updatedComponents = checkAndUpdateNextStepsInForm(currentComponents, replacementMap);
        workflow.modules[index].properties.sections[0].components = updatedComponents;
      }
    } else if (replacementMap[module.nextStep]) {
      workflow.modules[index].nextStep = replacementMap[module.nextStep];
    }
  });

  // Replace next step in conditions
  workflow = replaceNextStepInConditions(workflow, replacementMap);
  return workflow;
};

export default replaceNextStepId;
