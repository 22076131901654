import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import './Container.scss';
import { Drawer, getDrawerApplicationPagePath, getHiddenAndDisabledDrawerKeysFromPermissions } from 'storybook-ui-components';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { persistor } from '../store';
import useShowErrorAlert from '../utils/customHooks';
import AlertCode from '../constants/alertCodes';
import navMapping from '../utils/navMapping';
import useGetUserPermissions from '../Permissions/hooks';

function DrawerLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();
  const docToken = useSelector((state) => state.onboard.docToken);
  const location = useLocation();
  const { pathname } = location;
  const { navKey, subNavKey } = get(navMapping, pathname, {});

  const { allowedPermissions, disallowedPermissions } = useGetUserPermissions();

  const applicationsTab = getDrawerApplicationPagePath(
    allowedPermissions,
    disallowedPermissions,
    pathname,
  );

  const { hiddenNavKeys, disabledNavKeys } = getHiddenAndDisabledDrawerKeysFromPermissions(
    disallowedPermissions,
  );

  const logoutAction = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/logout`,
      });
      await persistor.purge();
      dispatch({ type: 'RESET' });
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.default });
    }
  };

  const drawerFunctions = {
    home: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/home`);
    },
    applications: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}${applicationsTab}`);
    },
    workflows: () => { navigate('/'); },
    credentials: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/credentials`);
    },
    logout: logoutAction,
    usage: () => { window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/usage`); },
    documentation: () => window.open(`https://developer.hyperverge.co/global/kyc/-?jwt=${docToken}`),
    users: () => window.location.replace(`${process.env.REACT_APP_USERS_APP_URL}`),
    businessMetrics: () => window.location.replace(process.env.REACT_APP_ANALYTICS_APP_URL),
    stepwiseAnalytics: () => window.location.replace(`${process.env.REACT_APP_ANALYTICS_APP_URL}/stepwise`),
  };

  return (
    <div id="main__container">
      <div id="main__container__navbar">
        <Drawer
          onClickFunctions={drawerFunctions}
          activeNav={navKey}
          activeSubNav={subNavKey}
          hiddenNavKeys={hiddenNavKeys}
          disabledNavKeys={disabledNavKeys}
        />
      </div>
      <div id="main__container__body">
        <Outlet />
      </div>
    </div>
  );
}

export default DrawerLayout;
