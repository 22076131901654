import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ConditionalVariableTitleRibbon from './ConditionalVariableTitleRibbon';
import '../NewConditionDrawer/NewConditionDrawer.scss';
import ConditionalVariableName from './ConditionalVariableName';
import IndividualConditionalVariable from './IndividualConditionalVariable';
import generateUniqueID from '../../../../utils/generateUniqueId';

function ConditionalVariablesDrawer(props) {
  const {
    setShowDrawer,
    childArray,
    handleChildArrayUpdate,
    dropDownOptions,
  } = props;

  const [individualChildArray, setIndividualChildArray] = useState(childArray);
  const [containsInvalidRule, setContainsInvalidRule] = useState(false);

  useEffect(() => {
    setIndividualChildArray(childArray);
  }, [childArray]);

  const handleSave = () => {
    setShowDrawer(false);
    handleChildArrayUpdate(individualChildArray);
  };

  const handleClose = () => {
    setShowDrawer(false);
  };

  // takes a new conditional variable and updates it at the index
  const updateChild = (newChild, index) => {
    setIndividualChildArray((currArray) => {
      const newArray = [...currArray];
      newArray[index] = newChild;
      return newArray;
    });
  };

  // deletes an else-if condition
  const deleteChild = (index) => {
    setIndividualChildArray((currArray) => {
      const newArray = [...currArray];
      // upadte the if false value of element before element to be deleted
      newArray[index - 1] = {
        ...newArray[index - 1],
        if_false: newArray[index].if_false,
      };
      // remove the element
      const arrAfterDeletion = newArray.filter((elem, i) => i !== index);
      return arrAfterDeletion;
    });
  };

  // adds a new conditional variable at the end for a new else if condition
  const onAddElseIf = (currArray) => {
    const parent = currArray[0];
    const prev = currArray[currArray.length - 1];
    const id = `condVar_${generateUniqueID()}`;

    const newConVar = {
      if_false: prev.if_false,
      if_true: 'NONE_NONE',
      parent: parent.id,
      rule: 'NONE_NONE == NONE_NONE',
      id,
    };

    setIndividualChildArray((arr) => {
      // add the new element
      const newArray = [...arr, newConVar];
      // update the if false value of the previous element
      newArray[arr.length - 1] = {
        ...newArray[arr.length - 1],
        if_false: `conditionalVariables.${id}`,
      };
      return newArray;
    });
  };

  const updateConditionalVariableName = (name, currArray) => {
    const newRootConVar = {
      ...currArray[0],
      name,
    };
    updateChild(newRootConVar, 0);
  };

  return (
    <div className="conditionalVar__properties_tab">
      <div className="conditionalVar__properties_tab__content">
        <ConditionalVariableTitleRibbon
          handleSave={handleSave}
          handleClose={handleClose}
          containsInvalidRule={containsInvalidRule}
        />
        <ConditionalVariableName
          updateConditionalVariableName={
            (name) => updateConditionalVariableName(name, individualChildArray)
          }
          name={individualChildArray[0].name}
        />
        <div className="conditionalVar__conditions_container">
          <div className="conditionalVar__conditions_title">
            Conditions
          </div>
          <IndividualConditionalVariable
            individualChildArray={individualChildArray}
            updateChild={updateChild}
            deleteChild={deleteChild}
            onAddElseIf={() => onAddElseIf(individualChildArray)}
            dropDownOptions={dropDownOptions}
            setContainsInvalidRule={setContainsInvalidRule}
          />
        </div>
      </div>
    </div>
  );
}

export default ConditionalVariablesDrawer;

ConditionalVariablesDrawer.propTypes = {
  setShowDrawer: PropTypes.func.isRequired,
  childArray: PropTypes.array.isRequired,
  handleChildArrayUpdate: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
};
