import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, set, unset } from 'lodash';

import {
  selectFormComponents,
  updateReloadSdk,
  updateSelectedComponentPath,
  selectedComponentPath,
} from '../../reducers/dynamicForm';
import {
  canDeleteComponent as isLastButton, getComponentFromPath, getFormComponents,
  getSelectedComponent, performOpOnWorkflow, updateDependencyForOnReload,
} from './helper';
import FormModuleEditProperties from '../../components/FormModule/FormModuleEditProperties';
import { getDefaultComponent } from '../../components/FormModule/utils';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  selectOrderOfNodes, selectSelectedNode, selectSelectedWorkflow, selectModules,
} from '../../reducers/workflow';
import {
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectSelectedModuleState,
  selectSupportedFonts,
  updateCustomUiConfig,
  updateFontStyleSheets,
} from '../../reducers/editBranding';
import ListFormModuleComponents from '../../components/FormModule/ListFormModule';
import './FormModuleDrawer.scss';
import {
  logAddFormComponent, logDeleteFormComponent, logDragFormComponent, logSetFormComponentProperty,
} from '../../logger/logHighLevelWorkflowUpdates';
import { getConditionalVariables, getModuleOutputs, getWorkflowInputVariables } from '../../components/ViewWorkflow/v2/InputsToModule/utils';

function FormModuleDrawer({ checkDependencies: checkDeletionDependencies }) {
  const dispatch = useDispatch();
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedModuleId = useSelector(selectSelectedModuleState);
  const formComponentsConfig = useSelector(selectFormComponents);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const selectedComponentPathArray = useSelector(selectedComponentPath);
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const supportedFonts = useSelector(selectSupportedFonts);
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const moduleConfigs = useSelector(selectModules);
  const [selectedModule, setSelectedModule] = useState(
    getSelectedModule(selectedWorkflow, selectedModuleId),
  );
  const [selectedComponent, setSelectedComponent] = useState(
    getSelectedComponent(selectedModule, selectedComponentPathArray),
  );

  const currentUiConfig = customUiConfig && Object.keys(customUiConfig).length > 0
    ? customUiConfig
    : defaultUiConfig;

  useEffect(() => {
    const module = getSelectedModule(selectedWorkflow, selectedModuleId);
    setSelectedModule(module);
  }, [JSON.stringify(selectedWorkflow), selectedModuleId]);

  useEffect(() => {
    const newSelectedComponent = getSelectedComponent(selectedModule, selectedComponentPathArray);
    setSelectedComponent(newSelectedComponent);
  }, [JSON.stringify(selectedComponentPathArray), selectedModule]);

  const processWorkflowBeforeSave = (inputWorkflow) => {
    const workflow = cloneDeep(inputWorkflow);
    workflow.modules.forEach((module, index) => {
      if (module.type === 'dynamicForm') {
        const components = workflow?.modules?.[index]?.properties?.sections?.[0]?.components || [];
        if (components?.length) {
          const updatedComponents = updateDependencyForOnReload(components, module.id);
          workflow.modules[index].properties.sections[0].components = updatedComponents;
        }
      }
    });
    return workflow;
  };

  const updateWorkflow = (workflow) => {
    const processedWorkflow = processWorkflowBeforeSave(workflow);
    updateWorkflowInState(processedWorkflow);
    dispatch(updateReloadSdk());
  };

  const handleOnClick = (pathArray) => {
    dispatch(updateSelectedComponentPath({ pathArray }));
  };

  const isComponentDependent = (module, pathArray, currentWorkflow) => {
    const components = module?.properties?.sections[0]?.components || [];
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const isDependent = checkDeletionDependencies({
      variableId: componentToBeDeleted.id,
      nodeId: selectedModule.id,
      workflow: currentWorkflow,
    });
    return isDependent;
  };

  const handleOnDelete = (pathArray, workflow, moduleId, currCustomUiConfig) => {
    const isDependent = isComponentDependent(selectedModule, pathArray, workflow);
    if (isDependent) return null;
    const canDelete = isLastButton(selectedModule, pathArray);
    if (!canDelete) {
      alert('Can not delete this button as this is the last one');
      return null;
    }
    dispatch(updateSelectedComponentPath({ pathArray: [0] }));

    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const components = module?.properties?.sections[0]?.components || [];
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const editedUiConfig = cloneDeep(currCustomUiConfig);
    unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.id}`);

    const editedWorkflow = performOpOnWorkflow(
      'delete',
      selectedWorkflow,
      selectedModuleId,
      pathArray,
    );
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    updateWorkflow(editedWorkflow);
    logDeleteFormComponent({
      id: selectedModuleId,
      pathArray,
    });
    return null;
  };

  const handleOnUpdate = (
    newComponent,
    workflow,
    moduleId,
    pathArray,
    componentUIConfig,
    currUiConfig,
  ) => {
    const editedWorkflow = cloneDeep(workflow);
    const editedUiConfig = cloneDeep(currUiConfig);

    // Remove UI config of old component if present
    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const components = module?.properties?.sections[0]?.components || [];
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const oldCompPath = `${moduleId}.${componentToBeDeleted.id}`;
    const isUiConfigUpdated = unset(editedUiConfig, oldCompPath);
    const hasComponentUiConfig = Boolean(Object.keys(componentUIConfig || {}).length);

    if (hasComponentUiConfig) {
      // Add UI config of new component
      const newCompPath = `${moduleId}.${newComponent.id}`;
      set(editedUiConfig, newCompPath, componentUIConfig);
    }
    if (isUiConfigUpdated || hasComponentUiConfig) {
      editedWorkflow.properties.uiConfigSource = 'custom';
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    }
    const workflowToBeUpdated =
    performOpOnWorkflow('update', editedWorkflow, moduleId, pathArray, newComponent);
    logSetFormComponentProperty({
      id: moduleId,
      pathArray,
      newComponent,
    });
    updateWorkflow(workflowToBeUpdated);
  };

  const handleOnAdd = (pathArray, moduleId, workflow) => {
    // Currently adding the first component by default on add
    dispatch(updateSelectedComponentPath({ pathArray: [0] }));
    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const defaultConfig = formComponentsConfig[0];
    const componentToBeAdded = getDefaultComponent(defaultConfig, module);

    const editedWorkflow = performOpOnWorkflow(
      'add',
      workflow,
      moduleId,
      pathArray,
      componentToBeAdded,
    );
    logAddFormComponent({
      id: selectedModuleId,
      pathArray,
      componentId: componentToBeAdded?.id,
      componentType: componentToBeAdded?.type,
    });
    updateWorkflow(editedWorkflow);
  };

  const handleOnDrag = (fromPathArray, toPathArray) => {
    if (fromPathArray !== toPathArray) {
      const formComponents = getFormComponents(selectedModule);
      const draggedComponent = getComponentFromPath(formComponents, fromPathArray);
      let editedWorkflow =
      performOpOnWorkflow('delete', selectedWorkflow, selectedModuleId, fromPathArray);
      editedWorkflow = performOpOnWorkflow(
        'insert',
        editedWorkflow,
        selectedModuleId,
        toPathArray,
        draggedComponent,
      );
      logDragFormComponent({
        id: selectedModuleId,
        fromPathArray,
        toPathArray,
      });
      updateWorkflow(editedWorkflow);
    }
  };

  const addnewFontURL = (fontName) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', fontName);
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [fontName]: url } }));
  };

  return (
    <div className="master">
      <ListFormModuleComponents
        formComponents={getFormComponents(selectedModule)}
        handleOnClick={handleOnClick}
        handleOnDelete={(pathArray) => handleOnDelete(
          pathArray,
          selectedWorkflow,
          selectedModuleId,
          customUiConfig,
        )}
        handleOnAdd={(pathArray) => handleOnAdd(
          pathArray,
          selectedModuleId,
          selectedWorkflow,
        )}
        handleOnDrag={handleOnDrag}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={selectedComponentPathArray}
      />
      {
       selectedComponent
        && (
        <FormModuleEditProperties
          selectedModule={selectedModule}
          selectedComponent={selectedComponent}
          formComponentsConfig={formComponentsConfig}
          selectedModuleId={selectedModuleId}
          orderOfNodes={orderOfNodes}
          customUiConfig={customUiConfig}
          handleOnComponentChange={(newComponent, componentUIConfig) => {
            handleOnUpdate(
              newComponent,
              selectedWorkflow,
              selectedModuleId,
              selectedComponentPathArray,
              componentUIConfig,
              currentUiConfig,
            );
          }}
          supportedFonts={supportedFonts}
          currentUiConfig={currentUiConfig}
          addnewFontURL={addnewFontURL}
          workflowInputs={getWorkflowInputVariables(selectedWorkflow)}
          conditionalVariables={getConditionalVariables(selectedWorkflow)}
          moduleOutputs={getModuleOutputs(
            orderOfNodes,
            selectedNodeId,
            selectedWorkflow,
            formComponentsConfig,
            moduleConfigs,
          )}
        />
        )
      }
    </div>
  );
}

FormModuleDrawer.propTypes = {
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(FormModuleDrawer);
