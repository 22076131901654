import splitRule from './splitRule';

const createTextRuleMap = (ruleMap, selectedWorkflow, moduleConfigs, orderOfNodes) => {
  const localRuleMap = { ...ruleMap };

  // TODO: Remove code duplication
  // eslint-disable-next-line array-callback-return
  Object.keys(localRuleMap).map((key) => {
    const ruleObject = splitRule(ruleMap[key], orderOfNodes);
    const [id, ...rest] = ruleObject.left.split('.');
    const variableName = rest.join(' > ');
    if (id) {
      const moduleInRule = selectedWorkflow.modules.filter((module) => module.id === id)[0];
      if (moduleInRule) {
        const v2ModuleConfig = moduleConfigs[`${moduleInRule.subType}_uiConfig`];

        if (v2ModuleConfig) {
          const newModuleName = moduleInRule?.name || v2ModuleConfig?.moduleName;
          const newVariableName = v2ModuleConfig?.sections?.outputs?.filter(
            (output) => output.key === variableName,
          )[0]?.displayName || variableName;
          ruleObject.left = `${newModuleName}.${newVariableName}`;
        } else {
          const moduleName = moduleInRule?.name || moduleConfigs[`${moduleInRule.subType}`]?.uiConfig?.node?.heading;
          const variableNameFromWorkflow = moduleInRule.variables
            .filter((variable) => variable.name === variableName)[0]?.name || variableName;
          ruleObject.left = `${moduleName}.${variableNameFromWorkflow}`;
        }
      } else if (id === 'conditionalVariables') {
        const newModuleName = 'Conditional Variables';
        const newVariableName = selectedWorkflow
          .conditionalVariables[variableName].name || variableName;
        ruleObject.left = `${newModuleName}.${newVariableName}`;
      }
    }
    localRuleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${ruleObject.right}`;
  });
  // eslint-disable-next-line array-callback-return
  Object.keys(localRuleMap).map((key) => {
    const ruleObject = splitRule(localRuleMap[key], orderOfNodes);
    const [id, ...rest] = ruleObject.right.split('.');
    const variableName = rest.join(' > ');
    if (id) {
      const moduleInRule = selectedWorkflow.modules.filter((module) => module.id === id)[0];

      if (moduleInRule) {
        const v2ModuleConfig = moduleConfigs[`${moduleInRule?.subType}_uiConfig`];

        if (v2ModuleConfig) {
          const newModuleName = moduleInRule?.name || v2ModuleConfig?.moduleName;
          const newVariableName = v2ModuleConfig.sections.outputs.filter(
            (output) => output.key === variableName,
          )[0]?.displayName || variableName;
          ruleObject.right = `${newModuleName}.${newVariableName}`;
        } else {
          const moduleName = moduleInRule?.name || moduleConfigs[`${moduleInRule.subType}`]?.uiConfig?.node?.heading;
          const variableNameFromWorkflow = moduleInRule.variables
            .filter((variable) => variable.name === variableName)[0].name;
          ruleObject.right = `${moduleName}.${variableNameFromWorkflow}`;
        }
      }
    } else if (id === 'conditionalVariables') {
      const newModuleName = 'Conditional Variables';
      const newVariableName = selectedWorkflow
        .conditionalVariables[variableName].name || variableName;
      ruleObject.right = `${newModuleName}.${newVariableName}`;
    }
    localRuleMap[key] = `${ruleObject.left} ${ruleObject.operator} ${ruleObject.right}`;
  });
  return localRuleMap;
};

export default createTextRuleMap;
