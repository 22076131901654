import { getCurrentValueFromWorkflowForSingleSelectDropDown } from '../../InputsToModule/utils/updateWorkflow';

const setDefaultValue = (selectedModule, workflowKey, selectedModuleConfig) => {
  const defaultValue = getCurrentValueFromWorkflowForSingleSelectDropDown(
    selectedModule,
    workflowKey,
    selectedModuleConfig,
  );
  return defaultValue === null ? '' : defaultValue;
};

export default setDefaultValue;
