import { cloneDeep, set, unset } from 'lodash';
import { getSelectedComponent } from '../../containers/FormModule/helper';
import generateUniqueID from '../../utils/generateUniqueId';
import { extractBaseNameAndNumber } from '../../utils/updateModuleNamesInWorkflow';
import { getSelectedModule } from '../ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';

export const getAllComponentsRecursively = (components) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components || []);
  const allComponents = [];
  clonnedComponents.forEach((component) => {
    if (component?.subComponents?.length) {
      const allSubComponents = getAllComponentsRecursively(component.subComponents);
      allComponents.push(...allSubComponents);
    }
    // Don't use else as vertical component is also a component and should be added
    allComponents.push(component);
  });
  return allComponents;
};

const getAllComponentsInModule = (module) => {
  const components = module?.properties?.sections?.[0]?.components || [];
  const allComponents = getAllComponentsRecursively(components);
  return allComponents;
};

export const getUniqueLabelAndId = (allExistingComponents, defaultComponent, labelKey = null) => {
  const defaultId = defaultComponent?.id;
  const defaultLabel = labelKey ? defaultComponent?.[labelKey] : null;
  const componentType = defaultComponent?.type;
  let maxIdCount = 0;
  let maxLabelCount = 0;
  const { base: baseComponentId } = extractBaseNameAndNumber(defaultId);
  const baseComponentLabel = defaultLabel ? extractBaseNameAndNumber(defaultLabel)?.base : '';
  allExistingComponents.forEach((existingComponent) => {
    const { base: currentBaseComponentId, no: currentIdCount } = extractBaseNameAndNumber(
      existingComponent?.id,
    );
    if (baseComponentId === currentBaseComponentId) {
      maxIdCount = Math.max(maxIdCount, currentIdCount);
    }
    if (defaultLabel && componentType === existingComponent?.type) {
      const { base: currentBaseLabel, no: currentLabelCount } = extractBaseNameAndNumber(
        existingComponent?.[labelKey],
      );
      if (currentBaseLabel === baseComponentLabel) {
        maxLabelCount = Math.max(maxLabelCount, currentLabelCount);
      }
    }
  });
  if (!labelKey) return { id: `${defaultId}${maxIdCount + 1}` };
  return { id: `${defaultId}${maxIdCount + 1}_${generateUniqueID()}`, label: `${defaultLabel}${maxLabelCount + 1}` };
};

export const getDefaultComponent = (componentConfig, module) => {
  const { primaryBrandingKey: labelKey, default: defaultComponent } = componentConfig;
  const allExistingComponents = getAllComponentsInModule(module);
  const { id: newId, label: newLabel } = getUniqueLabelAndId(
    allExistingComponents,
    defaultComponent,
    labelKey || null,
  );
  const newComponent = { ...defaultComponent, id: newId };
  if (labelKey) newComponent[labelKey] = newLabel;
  return newComponent;
};

export const nonModuleNodes = ['condition', 'decline', 'approve', 'start', 'manualReview', 'goto'];
export const terminals = ['decline', 'approve', 'start', 'manualReview', 'goto'];

export const findModules = (orderOfNodes) => {
  const nodes = orderOfNodes
    .map((node) => (!nonModuleNodes.includes(node.nodeType) ? node : null))
    .filter((id) => id !== null);

  return nodes;
};

export const findModulesAndConditions = (orderOfNodes) => {
  const nodes = orderOfNodes
    .map((node) => (!terminals.includes(node.nodeType) ? node : null))
    .filter((id) => id !== null);

  return nodes;
};

export const updateEditedWorkflow = (
  value,
  key,
  component,
  selectedWorkflow,
  selectedModuleId,
  selectedComponentType,
  componentIndex,
  subComponentIndex = -1,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);

  editedWorkflow.modules.forEach((module, index) => {
    if (module.id === selectedModuleId) {
      const { components } = editedWorkflow.modules[index].properties.sections[0];
      if (subComponentIndex !== -1) {
        components[componentIndex].subComponents[subComponentIndex][key] = value;
      } else {
        components[componentIndex][key] = value;
      }
    }
  });

  return editedWorkflow;
};

export const updateFormPropertyInComponents = (value, key, pathArray, components) => {
  if (!components?.length) return [];
  if (!pathArray?.length) return components;
  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 1) {
    if (clonnedComponents.length > pathArray[0]) clonnedComponents[pathArray[0]][key] = value;
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents || [];
  if (currentSubComponents.length === 0) return clonnedComponents;
  const newChildSubComponents =
  updateFormPropertyInComponents(value, key, newPath, currentSubComponents);
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const updateFormPropertyInWorkflow = (value, key, pathArray, workflow, moduleId) => {
  const editedWorkflow = cloneDeep(workflow);
  const selectedModule = getSelectedModule(editedWorkflow, moduleId);
  if (!selectedModule) return editedWorkflow;
  const components = selectedModule?.properties?.sections[0]?.components || [];
  if (!components?.length) return editedWorkflow;
  const updatedComponents = updateFormPropertyInComponents(value, key, pathArray, components);
  selectedModule.properties.sections[0].components = updatedComponents;
  return editedWorkflow;
};

export const getFormPropertyFromWorkflow = (key, pathArray, workflow, moduleId) => {
  const selectedModule = getSelectedModule(workflow, moduleId);
  if (!selectedModule) return null;
  const selectedComponent = getSelectedComponent(selectedModule, pathArray);
  return selectedComponent?.[key] || null;
};

export const updateComponentProperties = (
  value,
  key,
  component,
) => {
  if (!component) return null;
  const editedComponent = cloneDeep(component);
  if (value === null) unset(editedComponent, key);
  else set(editedComponent, key, value);
  return editedComponent;
};
