import { cloneDeep } from 'lodash';

const updateWorkflow = (
  selectedWorkflow,
  finalCountriesSelected,
  selectedNodeId,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);

  editedWorkflow.modules.forEach((module, index) => {
    if (module.id === selectedNodeId) {
      editedWorkflow.modules[index].properties
        .countriesSupported = finalCountriesSelected.map(
          (country) => country.id,
        );
    }
  });
  return editedWorkflow;
};

export default updateWorkflow;
