import { useState } from 'react';
import { fetchDependencyList, fetchDependencyOfField } from './fetchDependencyList';
import DeleteNodeModal from '../components/DeleteNodeModal';

function withDeletionDependencyCheck(WrappedComponent) {
  return function WithDeletionDependencyCheck(props) {
    const [dependencies, setDependencies] = useState(null);

    const checkDependencies = ({ variableId, nodeId, workflow }) => {
      // Fetch dependencies
      let deps;
      if (variableId) {
        deps = fetchDependencyOfField(variableId, nodeId, workflow);
      } else {
        deps = fetchDependencyList(nodeId, workflow);
      }
      const isDependent = Boolean(Object.values(deps).reduce(
        (depsNumSoFar, currDeps) => depsNumSoFar + currDeps.length,
        0,
      ));
      if (isDependent) {
        setDependencies(deps);
      }
      return isDependent;
    };

    return (
      <>
        {
          dependencies && (
            <DeleteNodeModal
              onClose={() => setDependencies(null)}
              dependencies={dependencies}
            />
          )
        }
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WrappedComponent checkDependencies={checkDependencies} {...props} />
      </>
    );
  };
}

export default withDeletionDependencyCheck;
