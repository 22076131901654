import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import useDebounce from '../../../utils/useDebounce';

function DebouncedTextInput(props) {
  const {
    label,
    onChange,
    defaultValue,
    readOnly,
    placeholder,
  } = props;

  const [value, setValue] = useState(defaultValue || '');
  const prevValue = useRef(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);
  const onValueChange = (e) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);
  };

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onChange(debouncedValue);
    prevValue.current = debouncedValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <input
          type="text"
          className="edit-properties-div__text-input"
          value={value}
          onChange={onValueChange}
          readOnly={readOnly}
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
}

DebouncedTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

DebouncedTextInput.defaultProps = {
  placeholder: 'Please enter a value',
};

export default DebouncedTextInput;
