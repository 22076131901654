import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { convertDurationInNewUnits, convertDurationToMillis } from '../../../../utils/helper';
import useDebounce from '../../../../utils/useDebounce';
import './Duration.scss';

function Duration({
  currentDurationInMillis, isDisabled, onDurationChange, title, unitsInWorkflow,
}) {
  const [unit, setUnit] = useState(unitsInWorkflow);
  const [time, setTime] = useState(
    convertDurationInNewUnits(currentDurationInMillis, unitsInWorkflow),
  );
  const debouncedTime = useDebounce(time, 500);

  const handleUnitChange = (event) => {
    const { value: newUnit } = event.target;
    setUnit(newUnit);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const duration = parseFloat(value, 10);
    if (Number.isNaN(duration)) setTime(0);
    else setTime(duration);
  };

  useEffect(() => {
    const finalDurationInMillis = convertDurationToMillis(debouncedTime, unit);
    onDurationChange(finalDurationInMillis);
  }, [debouncedTime, unit]);

  return (
    <div className={`duration ${isDisabled ? 'duration_disabled' : ''}`}>
      <div className="duration-label">{title}</div>
      <div className="duration-details-flex">
        <input
          type="text"
          className="duration-input"
          value={time}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
        <select
          className="duration-dropdown"
          value={unit}
          onChange={handleUnitChange}
          disabled={isDisabled}
        >
          <option value="hours">Hrs</option>
          <option value="mins">Mins</option>
          <option value="secs">Secs</option>
          <option value="ms">ms</option>
        </select>
      </div>
    </div>
  );
}

Duration.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  currentDurationInMillis: PropTypes.number.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  unitsInWorkflow: PropTypes.string.isRequired,
};

export default Duration;
