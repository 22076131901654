import PropTypes from 'prop-types';

import validRuleIcon from '../../../../assests/icons/tickIcon.svg';
import invalidRuleIcon from '../../../../assests/icons/invalidRuleIcon.svg';

function DisplayRule(props) {
  const { prettyRule, onChange, isRuleValid } = props;

  return (
    <div className="condition__content__rule-box">
      <div className="condition__content__rule-box__title">Rule:</div>
      <div className={`condition__content__rule-box__input  ${isRuleValid ? '' : 'invalid-rule'}`}>
        <input
          className="condition__content__rule-box__input"
          type="text"
          value={prettyRule}
          onChange={(e) => onChange(e.target.value)}
        />
        <div className="condition__content__rule-box__rule-validity">
          <img src={isRuleValid ? validRuleIcon : invalidRuleIcon} alt="validity" />
        </div>

        <div className="condition__content__rule-box__sample-rule">
          Sample Rule: A && B || (C && D)
        </div>

      </div>
    </div>
  );
}

DisplayRule.propTypes = {
  prettyRule: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRuleValid: PropTypes.bool.isRequired,
};

export default DisplayRule;
