/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// TODO: Replace / Remove this component once conditional variables are ready
import PropTypes, { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectSelectedWorkflow } from '../../../../reducers/workflow';
import { getWorkflowInputVariables } from '../InputsToModule/utils';

function WorkflowInputPicker({ setValue, style }) {
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const [workflowInputsToDisplay, setWorkflowInputsToDisplay] = useState([]);

  useEffect(() => {
    const listToDisplay = getWorkflowInputVariables(selectedWorkflow);
    setWorkflowInputsToDisplay(listToDisplay);
  }, [selectedWorkflow]);

  const selectWorkflowInput = (inputId) => {
    setValue(inputId);
  };

  return (
    <div className="conditional-variable__picker" style={style}>
      <div className="conditional-variable__picker__content">
        {
          (workflowInputsToDisplay || []).map((wfInput) => (
            <div
              key={wfInput.id}
              className="conditional-variable__picker__value"
              onClick={() => selectWorkflowInput(wfInput.id)}
            >
              {wfInput.name || wfInput.id}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default WorkflowInputPicker;

WorkflowInputPicker.propTypes = {
  setValue: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(object).isRequired,
};
