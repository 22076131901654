import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import prettifyRule from './utils/prettifyRule';
import DisplayRule from './DisplayRule';
import regenerateRule from './utils/regenerateRule';
import getNextVariableName from './utils/getNextVariableName';
import validateRule from './utils/validateRule';
import Rule from './Rule';
import Modal from '../../../Common/Modal';
import './RuleGenerator.scss';
import splitRuleUnit from '../ConditionalVariablesV2/utils/splitRule';

function RuleGenerator(props) {
  const {
    rule: initialRule,
    onRuleChange,
    leftSideDomain,
    rightSideDomain,
    operatorsAllowed,
  } = props;

  const {
    calculatedRuleUnitMap: initialRuleUnitMap,
    calculatedPrettifiedRule: initialPrettifiedRule,
  } = prettifyRule(initialRule);

  const [prettyRule, setPrettyRule] = useState(initialPrettifiedRule);
  const [ruleUnitMap, setRuleUnitMap] = useState(initialRuleUnitMap);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [deleteRuleUnit, setDeleteRuleUnit] = useState(null);

  useEffect(() => {
    if (deleteRuleUnit) {
      const newRuleUnitMap = cloneDeep(ruleUnitMap);
      delete newRuleUnitMap[deleteRuleUnit];
      setRuleUnitMap(newRuleUnitMap);
      const isRuleValid = validateRule(prettyRule, newRuleUnitMap);
      const finalRule = regenerateRule(prettyRule, newRuleUnitMap);
      onRuleChange({ rule: finalRule, isRuleValid });
      setDeleteRuleUnit(null);
    }
  }, [deleteRuleUnit]);

  const handleFinalRuleChange = (newPrettyRule, newRuleUnitMap) => {
    const finalRule = regenerateRule(newPrettyRule, newRuleUnitMap);
    const isRuleValid = validateRule(newPrettyRule, newRuleUnitMap);
    onRuleChange({ rule: finalRule, isRuleValid });
  };

  const handleRuleInputTextEdit = (newPrettyRule, currRuleUnitMap) => {
    // Calculate newRule using ruleMap and decompiledRule -> compileRule
    setPrettyRule(newPrettyRule);
    handleFinalRuleChange(newPrettyRule, currRuleUnitMap);
  };

  const onAddRuleUnit = (currPrettifiedRule, currRuleUnitMap) => {
    const existingVariables = Object.keys(currRuleUnitMap);
    const newRuleName = getNextVariableName(existingVariables);
    const newRuleUnitMap = { ...currRuleUnitMap, [newRuleName]: 'NONE_NONE == NONE_NONE' };
    const newPrettyRule = currPrettifiedRule.trim().length
      ? `${currPrettifiedRule} && ${newRuleName}`
      : newRuleName;
    setPrettyRule(newPrettyRule);
    setRuleUnitMap(newRuleUnitMap);
    handleFinalRuleChange(newPrettyRule, newRuleUnitMap);
  };

  const onRuleUnitChange = (
    currPrettifiedRule,
    currRuleUnitMap,
    ruleNameToBeUpdated,
    newRuleUnitValue,
  ) => {
    const newRuleMap = { ...currRuleUnitMap, [ruleNameToBeUpdated]: newRuleUnitValue };
    setRuleUnitMap(newRuleMap);
    handleFinalRuleChange(currPrettifiedRule, newRuleMap);
  };

  const canDeleteRuleUnit = (currRuleUnitMap) => Object.keys(currRuleUnitMap).length > 1;

  const onDeleteRuleUnit = (
    currPrettifiedRule,
    ruleNameToBeDeleted,
  ) => {
    // check if rule unit name exists in pretty rule
    if (currPrettifiedRule.includes(ruleNameToBeDeleted)) {
      setShowDeleteModal(ruleNameToBeDeleted);
    } else {
      setDeleteRuleUnit(ruleNameToBeDeleted);
    }
  };

  return (
    <>
      <DisplayRule
        prettyRule={prettyRule}
        onChange={(newRule) => handleRuleInputTextEdit(newRule, ruleUnitMap)}
        isRuleValid={validateRule(prettyRule, ruleUnitMap)}
      />
      {
        Object.entries(ruleUnitMap)
          .map(([ruleName, ruleUnit]) => (
            <Rule
              key={ruleName}
              name={ruleName}
              ruleUnit={ruleUnit}
              onRuleUnitChange={(value) => onRuleUnitChange(
                prettyRule,
                ruleUnitMap,
                ruleName,
                value,
              )}
              leftSideDomain={leftSideDomain}
              rightSideDomain={rightSideDomain}
              splitRuleUnit={splitRuleUnit}
              onDeleteRuleUnit={() => onDeleteRuleUnit(prettyRule, ruleName)}
              canDeleteRuleUnit={canDeleteRuleUnit(ruleUnitMap)}
              operatorsAllowed={operatorsAllowed}
            />
          ))
      }
      <button
        type="button"
        className="condition__content__rules__add"
        onClick={() => onAddRuleUnit(prettyRule, ruleUnitMap)}
      >
        + Add
      </button>
      <Modal
        isOpen={!!showDeleteModal}
        onClose={() => {
          setShowDeleteModal(null);
        }}
        headerText="Do you want to delete this rule unit?"
        onSave={() => {
          setDeleteRuleUnit(showDeleteModal);
          setShowDeleteModal(null);
        }}
        buttonText="Confirm"
      >
        You are using this rule unit in the current rule,
        deleting this will make the rule invalid.
        Do you still want to continue?
      </Modal>
    </>
  );
}

RuleGenerator.propTypes = {
  rule: PropTypes.string.isRequired,
  onRuleChange: PropTypes.func.isRequired,
  leftSideDomain: PropTypes.array.isRequired,
  rightSideDomain: PropTypes.array.isRequired,
  operatorsAllowed: PropTypes.array.isRequired,
};

export default RuleGenerator;
