import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';
import ModuleInput from './ModuleInput';
import ModuleOutput from './ModuleOutput';
import ParametersDisplay from './ParametersDisplay';

function GenericModuleDrawer({ selectedNode, workflowConfig }) {
  const [nodeConfig, setNodeConfig] = useState({});
  const [moduleUIConfig, setUIConfig] = useState({
    drawer: {},
  });
  const [parameters, setParameters] = useState({});
  const workflowModules = useSelector((state) => state.workflow.modules);

  const resetState = () => {
    setNodeConfig({});
    setUIConfig({
      drawer: {},
    });
    setParameters({});
  };

  useEffect(() => {
    resetState();
    const { modules } = workflowConfig;
    const found = modules.find((element) => element.id === selectedNode.id);
    setNodeConfig(found);
    const { uiConfig } = workflowModules[selectedNode.nodeType];
    if (uiConfig) {
      setUIConfig(workflowModules[selectedNode.nodeType].uiConfig);
      const { parameters: uiParameters } = uiConfig;
      if (uiParameters) { setParameters(uiParameters); }
    }
  }, [selectedNode]);

  return (
    <BaseDrawer
      heading={moduleUIConfig.drawer.heading}
      subHeading={moduleUIConfig.drawer.subHeading}
    >
      <ModuleInput nodeConfig={nodeConfig} workflowConfig={workflowConfig} />
      <ParametersDisplay parameters={parameters} />
      <ModuleOutput selectedNode={selectedNode} />
    </BaseDrawer>
  );
}

GenericModuleDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GenericModuleDrawer;
