import { cloneDeep } from 'lodash';

const updateWorkflow = (
  selectedWorkflow,
  selectedNodeId,
  selectedModuleConfig,
  workflowKey,
  selectedOptions,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);
  editedWorkflow.modules.forEach((module, index) => {
    if (module.id === selectedNodeId) {
      // find the workflow key from Moduleconfig
      const workflowProperty = selectedModuleConfig.properties[workflowKey];
      if (workflowProperty) {
        editedWorkflow.modules[index].properties[workflowKey] = selectedOptions;
      }
    }
  });

  return editedWorkflow;
};

export default updateWorkflow;
