import PropTypes from 'prop-types';
import searchIcon from '../../assests/icons/search.svg';
import './searchBoxStyles.scss';

function SearchBox(props) {
  const { value, placeHolder, onSearch } = props;

  const onSearchValueChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <div className="search_box">
      <img src={searchIcon} alt="" className="search_box__icon" />
      <input type="text" value={value} onChange={onSearchValueChange} placeholder={placeHolder} className="search_box__input" />
    </div>
  );
}

SearchBox.propTypes = {
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchBox;
