import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './Workflow.scss';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import closeButton from '../assests/icons/closeButton.svg';
import addIcon from '../assests/icons/addIcon.svg';
import genericModuleIcon from '../assests/icons/genericModuleIcon.svg';
import conditionIcon from '../assests/icons/condition.svg';

function AddNodeModal({ onClose, addNewNode, addNewCondition }) {
  const [moduleNodes, setmoduleNodes] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [conditionModule, setconditionModule] = useState(null);
  const workflowModules = useSelector((state) => state.workflow.modules);

  const conditionModuleHeading = 'If Else Condition';
  const inputRef = useRef();

  useEffect(() => {
    // Event listener for clicks outside the modal
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.add_node_modal')) {
        onClose();
      }
    };
    // Event listener for the escape key press
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    // Add event listeners when the modal is open
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    const endStates = [];
    const modules = [];
    inputRef.current.focus();
    Object.keys(workflowModules).forEach((module) => {
      if (workflowModules[module].nodeDisplayType === 'output') { endStates.push(workflowModules[module]); }
      if (module.endsWith('_uiConfig') && workflowModules[module].DRAWER_TYPE !== 'condition') {
        const currentModuleNames = modules.map((mod) => mod.moduleName);

        if (!currentModuleNames.includes(workflowModules[module].moduleName)) {
          modules.push({ ...workflowModules[module], ...workflowModules[module.slice(0, -9)] });
        }
      }
      if (workflowModules[module].nodeDisplayType === 'condition') { setconditionModule(workflowModules[module]); }
    });
    modules.sort((a, b) => a.moduleName?.localeCompare(b.moduleName));
    setmoduleNodes(modules);
  }, [workflowModules]);

  const moduleNodesToDisplay = useMemo(() => moduleNodes?.filter(
    ({ moduleName, description }) => moduleName.toLowerCase().includes(inputValue.toLowerCase())
      || description.toLowerCase().includes(inputValue.toLowerCase()),
  ), [moduleNodes, inputValue]);

  const displayCondition = () => (
    <>
      <div className="modal_module_type_heading">CONDITION</div>
      <div className="modal_node">
        <div className="modal_node__icon">
          <img className="modal_node__icon__image" src={conditionIcon} alt="icon" />
        </div>
        <div className="modal_node__content">
          <div className="modal_node__content__heading">{conditionModuleHeading}</div>
          <div className="modal_node__content__subheading">{conditionModule.uiConfig.drawer.subHeading}</div>
        </div>
        <button type="button" className="modal_node__addButton" onClick={addNewCondition}>
          <img
            src={addIcon}
            alt="close"
          />
        </button>
      </div>
    </>
  );
  const displayModuleNodes = () => (
    <>
      <div className="modal_module_type_heading">MODULES</div>
      {
        moduleNodesToDisplay.filter((node) => node.type !== 'countries').map((state, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${state.moduleName}_${index}`} className="modal_node">
            <div className="modal_node__icon">
              <img className="modal_node__icon__image" src={state.icon || genericModuleIcon} alt="icon" />
            </div>
            <div className="modal_node__content">
              <div className="modal_node__content__heading">{state.moduleName}</div>
              <div className="modal_node__content__subheading">{state.description}</div>
            </div>
            <button type="button" className="modal_node__addButton" onClick={() => addNewNode(state)}>
              <img
                src={addIcon}
                alt="close"
              />
            </button>
          </div>
        ))
      }
    </>
  );
  const displayModalContent = (
    conditionModuleParam,
    conditionModuleHeadingParam,
    moduleNodesToDisplayParam,
  ) => {
    const shouldDisplayCondition = conditionModuleParam
    && conditionModuleHeadingParam.toLowerCase().includes(inputValue.toLowerCase());
    const shouldDisplayModules = moduleNodesToDisplayParam.length;
    if (shouldDisplayCondition || shouldDisplayModules) {
      return (
        <>
          {shouldDisplayCondition ? displayCondition() : null}
          {shouldDisplayModules ? displayModuleNodes() : null}
        </>
      );
    }
    return (
      <div className="modal_placeholder">
        Nothing to display!
      </div>
    );
  };

  return (
    <div className="add_node_overlay">
      <div className="add_node_modal">
        <div className="add_node_modal_header">
          <span>
            Add to Workflow
          </span>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>
        <input
          className="add_node_modal_searchbox"
          type="text"
          placeholder="Search for a module or condition"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          ref={inputRef}
        />
        <div className="add_node_modal_content">
          {
            displayModalContent(conditionModule, conditionModuleHeading, moduleNodesToDisplay)
          }
        </div>
      </div>
    </div>
  );
}

AddNodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  addNewNode: PropTypes.func.isRequired,
  addNewCondition: PropTypes.func.isRequired,
};

export default AddNodeModal;
