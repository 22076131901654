/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import CustomTooltip from '../Common/CustomTooltip';
import AlertCode from '../../constants/alertCodes';
import BaseDrawer from './BaseDrawer';
import DrawerTable from './DrawerTable';
import { updateCloseBrandingWindow } from '../../reducers/editBranding';
import WorkflowProperties from './WorkflowProperties';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import EditBrandingContainer from '../../containers/Branding/EditBrandingContainer';
import Branding from '../../pages/Branding';

function DefaultDrawer({ workflowConfig, workflowId, workflowName }) {
  // eslint-disable-next-line no-unused-vars
  const [sdkVersions, setSdkVersions] = useState({});
  const [sdkInputs, setSdkInputs] = useState({});
  const [workflowCreatedAt, setWorkflowCreatedAt] = useState();
  const [workflowUpdatedAt, setWorkflowUpdatedAt] = useState();
  const [activeTab, setActiveTab] = useState(2);

  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const moduleConfigs = useSelector((state) => state.workflow.modules);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);

  const dispatch = useDispatch();

  const closeBrandingWindow = useSelector(
    (state) => state.editBranding.closeBrandingWindow,
  );

  const handleClick = (tabNumber) => {
    if (tabNumber === 1) {
      dispatch(updateCloseBrandingWindow({ closeBrandingWindow: false }));
    }
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    if (closeBrandingWindow === true) {
      setActiveTab(2);
    }
  }, [closeBrandingWindow]);

  useEffect(() => {
    const {
      // eslint-disable-next-line camelcase
      properties, createdAt, updatedAt,
    } = workflowConfig;
    // eslint-disable-next-line camelcase
    if (properties) {
      if (properties.sdk_versions) setSdkVersions(properties.sdk_versions);
      if (properties.inputsRequired) setSdkInputs(properties.inputsRequired);
    }
    setWorkflowCreatedAt(createdAt);
    setWorkflowUpdatedAt(updatedAt);
  }, [workflowConfig]);

  const isUIScreensDisabled = useMemo(() => {
    const { listOfListOfModules } = getGroupedListOfModules(
      selectedWorkflow,
      workflowModules,
      supportedScreens,
      moduleConfigs,
    );
    return (!listOfListOfModules.length);
  }, [moduleConfigs, selectedWorkflow, supportedScreens, workflowModules]);

  const renderTabElements = () => {
    if (activeTab === 1 && !closeBrandingWindow) {
      return (
        <Navigate to={`/view/ui-screens?id=${workflowId}`} />
      );
    }
    return (
      <WorkflowProperties
        workflowCreatedAt={workflowCreatedAt}
        workflowUpdatedAt={workflowUpdatedAt}
        workflowId={workflowId}
      />
    );
  };

  return (
    <BaseDrawer
      heading={workflowName}
    >
      <div className="options-ribbon">
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
          onClickCapture={() => handleClick(1)}
          disabled={isUIScreensDisabled}
        >
          UI Screens
        </button>
        <button
          type="button"
          className={`options-ribbon__tab__workflow_info ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
          onClickCapture={() => handleClick(2)}
        >
          Info
        </button>
      </div>
      {renderTabElements()}
    </BaseDrawer>

  );
}

DefaultDrawer.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  workflowId: PropTypes.string.isRequired,
  workflowName: PropTypes.string.isRequired,
};

export default DefaultDrawer;
