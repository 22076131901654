const DRAWER_TYPES = {
  api_drawer: 'API_DRAWER',
  condition: 'CONDITION',
  countries: 'COUNTRY_SELECTION',
  id_card_verification: 'ID_CARD_VERIFICATION',
  none: 'NONE',
  output: 'OUTPUT',
  goto: 'GOTO',
  start: 'START',
};

const endStates = {
  approve: {
    uiConfig: {
      node: {
        heading: 'Auto Approve',
        subHeading: 'this will let customer move ahead',
      },
      drawer: {
        heading: 'Auto Approve',
        subHeading: 'This will let customer move ahead',
      },
    },
  },
  decline: {
    uiConfig: {
      node: {
        heading: 'Auto Decline',
        subHeading: 'this will NOT let customer move ahead',
      },
      drawer: {
        heading: 'Auto Decline',
        subHeading: 'This will NOT let customer move ahead',
      },
    },
  },
  manualReview: {
    uiConfig: {
      node: {
        heading: 'Send to Review',
        subHeading: 'Customer will wait until decision',
      },
      drawer: {
        heading: 'Send to Review',
        subHeading: 'Customer will wait until decision',
      },
    },
  },
};

const condition = {
  uiConfig: {
    drawer: {
      heading: 'Condition',
      subHeading:
        'Refers to a set of checks that get evaluated and corresponding actions that get executed',
    },
  },
};

export {
  endStates, DRAWER_TYPES, condition,
};
