import { cloneDeep } from 'lodash';

const updatedWorkflow = (selectedWorkflow, validSelectedDocuments, selectedNodeId) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);

  editedWorkflow.modules.forEach((module, index) => {
    if (module.id === selectedNodeId) {
      editedWorkflow.modules[index].properties.documentsSupported = validSelectedDocuments
        .reduce((acc, { country, documents }) => {
          acc[country] = documents;
          return acc;
        }, {});
      editedWorkflow.modules[index].properties.documentsOverride = validSelectedDocuments
        .reduce((acc, { country, configurations }) => {
          acc[country] = configurations;
          return acc;
        }, {});
    }
  });
  return editedWorkflow;
};

export default updatedWorkflow;
