const setIntialCountries = (selectedModule, countriesList) => {
  const countriesInWorkflow = selectedModule.properties.countriesSupported;
  const t = countriesList.filter(({ id }) => countriesInWorkflow.includes(id))
    .map(({ id, name }) => ({ id, name }));

  if (t.length > 0) { return t; }
  return ([{ id: '', name: '' }]);
};

export default setIntialCountries;
