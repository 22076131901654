/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import PropTypes, { object } from 'prop-types';
import CustomInputForRule from './CustomInputForRule';
import ModuleInputForRule from './ModuleInputForRule';
import deleteIcon from '../../../../assests/icons/deleteRuleIcon.svg';

function IndividualRule({
  ruleKey, ruleTypeMap, rule, textRule,
  ruleMap, setShowFieldTypesOption, showOption,
  handleRuleTextInput, selectedRuleKey, selectedSide, handleDelete, isConditionalVar, handleOperatorChange,
}) {
  const deleteRuleKey = () => {
    handleDelete(ruleKey);
  };
  return (
    <>
      <div className="conditional__content__rules_info">
        <div className="conditional__content__rules_info_heading">Rules for writing custom Input!</div>
        Strings should be in single quotes, Numbers should be like 200, 300, 400,
        Boolean should be among true or false.
      </div>
      <div className={!isConditionalVar ? 'conditional__content__var' : 'condition__content__var'}>
        <div className="condition__content__var__name">
          <span className="condition__content__var__name__var">
            <div>{ruleKey}</div>
          </span>
          {' '}
          is
        </div>
        <Grid container alignItems="center" rowSpacing={2}>
          <Grid
            item
            xs={5}
          >
            {ruleTypeMap.leftTypes[ruleKey] === 'module' && (
            <ModuleInputForRule
              side="left"
              handleShowOptionClick={setShowFieldTypesOption}
              ruleKey={ruleKey}
              textRule={textRule}
              showOption={showOption}
              selectedRuleKey={selectedRuleKey}
              selectedSide={selectedSide}
            />
            )}
            {ruleTypeMap.leftTypes[ruleKey] === 'custom' && (
            <CustomInputForRule
              side="left"
              rule={rule}
              ruleTypeMap={ruleTypeMap}
              ruleMap={ruleMap}
              ruleKey={ruleKey}
              setShowFieldTypesOption={setShowFieldTypesOption}
              handleRuleTextInput={handleRuleTextInput}
            />
            )}
          </Grid>
          <Grid item xs={2}>
            <select
              className="condition__content__var__dropDown"
              onChange={(e) => handleOperatorChange(e.target.value, ruleKey)}
            >
              <option
                value=""
                disabled
                selected
                hidden
              >
                Select Operator
              </option>
              <option
                value="=="
                selected={rule.operator === '=='}
              >
                is equal to
              </option>
              <option
                value="!="
                selected={rule.operator === '!='}
              >
                is not equal to
              </option>
              <option
                value=">="
                selected={rule.operator === '>='}
              >
                is greater than or equal to
              </option>
              <option
                value="<="
                selected={rule.operator === '<='}
              >
                is less than or equal to
              </option>
              <option
                value="<"
                selected={rule.operator === '<'}
              >
                is less than
              </option>
              <option
                value=">"
                selected={rule.operator === '>'}
              >
                is greater than
              </option>
            </select>
          </Grid>
          <Grid item xs={5}>
            {ruleTypeMap.rightTypes[ruleKey] === 'custom' && (
            <CustomInputForRule
              side="right"
              rule={rule}
              ruleTypeMap={ruleTypeMap}
              ruleMap={ruleMap}
              ruleKey={ruleKey}
              setShowFieldTypesOption={setShowFieldTypesOption}
              handleRuleTextInput={handleRuleTextInput}
            />
            )}
            {ruleTypeMap.rightTypes[ruleKey] === 'module' && (
            <ModuleInputForRule
              side="right"
              handleShowOptionClick={setShowFieldTypesOption}
              ruleKey={ruleKey}
              textRule={textRule}
              showOption={showOption}
              selectedRuleKey={selectedRuleKey}
              selectedSide={selectedSide}
            />
            )}
          </Grid>
        </Grid>
        <div className="condition__content__var__delete-copy-div">
          <button type="button" className="condition__content__var__delete" onClick={deleteRuleKey}>
            <img className="condition__content__var__delete-copy-icon" src={deleteIcon} alt="delete" />
          </button>
          {/* <div className="condition__content__var__copy">
            <img
              className="condition__content__var__delete-copy-icon"
              src={copyIcon}
              alt="copy"
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default IndividualRule;

IndividualRule.propTypes = {
  ruleKey: PropTypes.string.isRequired,
  ruleTypeMap: PropTypes.instanceOf(object).isRequired,
  ruleMap: PropTypes.instanceOf(object).isRequired,
  rule: PropTypes.instanceOf(object).isRequired,
  textRule: PropTypes.instanceOf(object).isRequired,
  setShowFieldTypesOption: PropTypes.func.isRequired,
  showOption: PropTypes.bool.isRequired,
  handleRuleTextInput: PropTypes.func.isRequired,
  selectedRuleKey: PropTypes.func.isRequired,
  selectedSide: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isConditionalVar: PropTypes.bool.isRequired,
  handleOperatorChange: PropTypes.func.isRequired,
};
