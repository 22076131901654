import PropTypes from 'prop-types';
import Toggle from './Toggle';
import ShowConditionDrawer from './ShowConditionDrawer';

function ToggleWithCondition(props) {
  const {
    label,
    onChange,
    defaultValue,
  } = props;

  const toggleValue = defaultValue !== 'no';
  const handleOnChange = (isOn) => {
    const value = isOn ? 'yes' : 'no';
    onChange(value);
  };

  return (
    <div className="edit-settings-div__toggle-condition">
      <Toggle
        label={label}
        value={toggleValue}
        onChange={handleOnChange}
      />
      {toggleValue && defaultValue !== undefined && (
        <ShowConditionDrawer
          defaultValue={defaultValue}
          onChange={(value) => onChange(value)}
        />
      )}
    </div>
  );
}

ToggleWithCondition.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default ToggleWithCondition;
