/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import TextInput from './TextInput';
import createWorkflowId from './helper';
import basicStandardFlow from './basicStandartWorkflow';
import useShowErrorAlert from '../../utils/customHooks';
import AlertCode from '../../constants/alertCodes';
import CreateWorkflowButton from './CreateWorkflowButton';
import useApiHooks from '../../utils/useApiHooks';

function CreateWorkflow({ currentAppId }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpload, setEnableUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const showErrorAlert = useShowErrorAlert();
  const { publishWorkflow } = useApiHooks();

  const handleTextChange = (value, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleImportButton = () => {
    setWorkflowData({});
    setIsLoading(false);
    setShowDrawer((status) => !status);
  };

  const handleUploadButton = async () => {
    if (currentAppId
        && workflowData?.name?.length
        && workflowData?.description?.length) {
      setIsLoading(true);
      const { name, description } = workflowData;
      const workflowId = createWorkflowId();
      const basicFLow = cloneDeep(basicStandardFlow);
      basicFLow.properties.name = name;
      basicFLow.properties.description = description;
      const status = await publishWorkflow(currentAppId, workflowId, basicFLow);
      setIsLoading(false);
      if (status.isSuccessful) {
        handleImportButton();
        navigate(`/view?id=${workflowId}`);
      } else {
        showErrorAlert({ error: status.error, message: AlertCode.error.errorCreatingWorkflow });
      }
    }
  };

  useEffect(() => {
    setEnableUpload(Boolean(currentAppId
        && workflowData?.name?.length
        && workflowData?.description?.length));
  }, [workflowData, currentAppId]);

  return (
    <>
      <div className="import_workflow__item">
        <button type="button" onClick={handleImportButton} className="import_workflow__item_button">
          Create Workflow
        </button>
      </div>
      { showDrawer
        && (
          <div className="import_workflow__drawer_background">
            <div className="import_workflow__drawer">
              <TextInput
                label="Name of Workflow"
                placeholder="PayBook - Liveness + Facematch"
                onChange={(value) => handleTextChange(value, 'name')}
              />
              <TextInput
                label="Description"
                placeholder="Describe what is this workflow about"
                onChange={(value) => handleTextChange(value, 'description')}
              />
              <div className="import_workflow__item">
                <button type="button" onClick={handleImportButton} className="import_workflow__button">Cancel</button>
                { enableUpload
                  ? (
                    <CreateWorkflowButton
                      onButtonClick={handleUploadButton}
                      normalButtonText="Create"
                      loadingButtonText="Creating"
                      isLoading={isLoading}
                      buttonClassName="import_workflow__button__primary"
                    />
                  )
                  : null}
              </div>
            </div>
          </div>
        ) }
    </>
  );
}

export default CreateWorkflow;

CreateWorkflow.propTypes = {
  currentAppId: PropTypes.string.isRequired,
};
