import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';
import WorkflowConfigurationModal from './WorkflowConfigurationModal';
import DisplaySDKOutputVariables from './DisplaySDKOutputVariables';
import { selectModules, selectOrderOfNodes, selectSelectedWorkflow } from '../../reducers/workflow';
import generateUniqueID from '../../utils/generateUniqueId';
import '../Workflow.scss';

function OutputDrawer({ selectedNode }) {
  const [moduleUIConfig, setUIConfig] = useState({
    drawer: {},
  });
  const [showOutputs, setShowOutputs] = useState(false);
  const workflowModules = useSelector(selectModules);
  const workflowConfig = useSelector(selectSelectedWorkflow);
  const orderOfModules = useSelector(selectOrderOfNodes);
  const [sdkResponse, setSdkResponse] = useState([]);

  useEffect(() => {
    const outputs = workflowConfig.sdkResponse || {};
    if (outputs) {
      setSdkResponse(
        Object.entries(outputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, value }
          ),
        ),
      );
    }
  }, [workflowConfig]);

  const resetState = () => {
    setUIConfig({
      drawer: {},
    });
  };

  useEffect(() => {
    resetState();
    const { uiConfig } = workflowModules[selectedNode.nodeType];
    if (uiConfig) {
      setUIConfig(workflowModules[selectedNode.nodeType].uiConfig);
    }
  }, [selectedNode]);

  return (
    <>
      <BaseDrawer
        heading={moduleUIConfig.drawer.heading}
        subHeading={moduleUIConfig.drawer.subHeading}
      >
        <div className="options-ribbon">
          <button
            type="button"
            className="options-ribbon__active options-ribbon__tab"
          >
            Properties
          </button>
          <button
            type="button"
            className="options-ribbon__tab"
            disabled
          >
            UI Screens
          </button>
          <button
            type="button"
            className="options-ribbon__tab__info"
          >
            Info
          </button>
        </div>
        <DisplaySDKOutputVariables
          sdkResponse={sdkResponse}
          setShowModalType={() => setShowOutputs(true)}
          workflowConfig={workflowConfig}
          selectedNodeId={selectedNode.id}
          orderOfModules={orderOfModules}
        />
      </BaseDrawer>
      {showOutputs && (
      <WorkflowConfigurationModal
        onClose={() => { setShowOutputs(false); }}
        page="output"
      />
      )}
      ;
    </>
  );
}

OutputDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OutputDrawer;
