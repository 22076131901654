import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  updateSelectedScreenState,
  updateSelectedModuleState,
  updateSelectedModuleSubType,
  updateSelectedModuleType,
  updateCloseBrandingWindow,
  selectSelectedModuleSubType,
  updateCloseSDK,
  selectSelectedModuleType,
  updateBrandingElements,
  selectSelectedModuleState,
  selectCloseSDK,
  selectDefaultTextConfig,
  updateSelectedLanguage,
  selectSelectedLanguage,
  updateCustomTextConfig,
  selectCustomTextConfig,
  selectFontStyleSheets,
} from '../../reducers/editBranding';
import { selectReloadSdk } from '../../reducers/dynamicForm';
import './EditBrandingContainer.scss';
import BrandingNavBar from '../../components/Branding/BrandingNavBar';
import ShowListOfModuleScreens from '../../components/Branding/ShowListOfModuleScreens';
import DisplaySDKScreen from '../../components/Branding/DisplaySDKScreen';
import ShowListOfElements from '../../components/Branding/ShowListOfElements';
import { closeExistingSDK } from '../../components/utils';
import EditSDKBranding from './EditSDKBranding';
import EditIndividualScreens from './EditIndividualScreens';
import FormModuleDrawer from '../FormModule/FormModuleDrawer';
import { selectSelectedNode, selectSelectedWorkflow } from '../../reducers/workflow';
import useApiHooks from '../../utils/useApiHooks';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import languageCodes from '../../components/Branding/utils/languageCodeMapping';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';

function EditBrandingContainer(props) {
  const { workflowId } = props;
  const dispatch = useDispatch();
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const selectedNode = useSelector(selectSelectedNode);
  const uiConfig = useSelector((state) => state.editBranding.customUiConfig);
  const textConfig = useSelector(selectCustomTextConfig);
  const defaultConfigs = useSelector(selectDefaultTextConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const selectedModuleType = useSelector(selectSelectedModuleType);
  const selectedModuleSubType = useSelector(selectSelectedModuleSubType);
  const brandingElements = useSelector((state) => state.editBranding.brandingElements);
  const selectedScreen = useSelector((state) => state.editBranding.selectedScreenState);
  const selectedModuleId = useSelector(selectSelectedModuleState);

  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const customStyleSheets = useSelector(selectFontStyleSheets);
  const reloadSdk = useSelector(selectReloadSdk);
  const closeSdk = useSelector(selectCloseSDK);

  const [selectedElementIndex, setSelectedElementIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(2);
  const [closePopup, setClosePopup] = useState(false);
  // TODO: Move this state inside ShowListOfModulesSuper
  const [screensToDisplay, setScreensToDisplay] = useState([]);

  // TODO: Move to page
  const navigate = useNavigate();
  const selectedElement = useMemo(
    () => brandingElements[selectedElementIndex] || {},
    [brandingElements, selectedElementIndex],
  );

  const { fetchCustomTextConfig } = useApiHooks();

  const languagesSupported = useMemo(() => {
    const textConfigKeys = Object.keys(defaultConfigs || {})
      .filter((configName) => configName.endsWith('text_config') && languageCodes[configName.split('_')[1] || '']);
    return textConfigKeys.map((key) => {
      const languageCode = key.split('_')[1] || '';
      return {
        id: languageCode,
        name: languageCodes[languageCode],
        value: languageCode,
      };
    });
  }, [defaultConfigs]);

  const onLanguageChange = (value) => {
    dispatch(updateSelectedLanguage({ language: value }));
  };

  const handleClick = (tabNumber) => {
    if (activeTab !== tabNumber) {
      setActiveTab(tabNumber);
    }
  };

  const updateScreenName = useCallback(
    (module) => {
      setScreensToDisplay((oldScreens) => {
        if (oldScreens.includes(module)) {
          return oldScreens.filter((screen) => screen !== module);
        }
        return [...oldScreens, module];
      });
    },
    [setScreensToDisplay],
  );

  const handleScreenSelect = useCallback(
    (screen, module) => {
      dispatch(updateSelectedModuleState({ module }));
      dispatch(updateSelectedModuleSubType({ module }));
      dispatch(updateSelectedModuleType({ module }));
      dispatch(updateSelectedScreenState({ screen }));
    },
    [dispatch],
  );

  // Set modules to be displayed in UI screens
  const modulesAndCompiledWorkflow = useMemo(() => {
    const { listOfListOfModules, workflow } = getGroupedListOfModules(
      selectedWorkflow,
      workflowModules,
      supportedScreens,
    );
    return { listOfListOfModules, workflow };
  /* selectedWorkflow can change from any children of EditBrandingContainer,
     but we don't want to recalculate modulesInWorkflow to avoid re-triggers,
     hence it should be skipped from the deps array */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowModules, supportedScreens, JSON.stringify(selectedWorkflow.modules)]);

  useEffect(() => {
    if (textConfig && Object.keys(textConfig).length) return;

    fetchCustomTextConfig(workflowId, selectedLanguage)
      .then((res) => {
        const editedWorkflow = cloneDeep(selectedWorkflow);
        editedWorkflow.properties.textConfigSource = {
          ...(editedWorkflow.properties.textConfigSource),
          [selectedLanguage]: Object.keys(res || {}).length ? 'custom' : 'default',
        };
        updateWorkflowInState(editedWorkflow);
        dispatch(updateCustomTextConfig({ textConfig: res, language: selectedLanguage }));
      }).catch(() => {
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, workflowId]);

  useEffect(() => {
    dispatch(updateCloseSDK({ closeSDK: false }));

    return () => {
      dispatch(updateCloseSDK({ closeSDK: true }));
    };
  }, [dispatch]);

  const onSetSelectedElement = (index) => {
    setSelectedElementIndex(index);
  };

  // Runs once on mount
  useEffect(() => {
    const currentModule =
      modulesAndCompiledWorkflow.listOfListOfModules.find(
        (module) => module.id === selectedNode?.id,
      ) || modulesAndCompiledWorkflow.listOfListOfModules[0];
    updateScreenName(currentModule);

    const currentModuleType = currentModule?.modules[0].moduleType || 'NA';
    const currentScreen = supportedScreens?.[currentModuleType]?.[0].state || supportedScreens?.[currentModuleType]?.[0].name || '';
    handleScreenSelect(currentScreen, currentModule?.modules[0] || { id: '', nodeType: '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedNode?.id, updateScreenName, supportedScreens, handleScreenSelect,
  ]);

  // Update brandingElements when selectedModuleType, selectedScreen change
  useEffect(() => {
    const selectedScreenBrandingConfig = supportedScreens[selectedModuleType]?.find(
      (screens) => screens.state === selectedScreen,
    );
    if (selectedScreenBrandingConfig?.brandingKeys) {
      const { brandingKeys } = selectedScreenBrandingConfig;
      dispatch(updateBrandingElements({ brandingElements: brandingKeys }));
    } else {
      dispatch(updateBrandingElements({ brandingElements: [] }));
    }
    setSelectedElementIndex(0);
  }, [selectedModuleType, selectedScreen, dispatch, supportedScreens]);

  const onElementUpdate = (element) => {
    const newBrandingKeys = cloneDeep(brandingElements);
    newBrandingKeys[selectedElementIndex] = element;
    dispatch(updateBrandingElements({ brandingElements: newBrandingKeys }));
  };

  const handleSave = async () => {
    dispatch(updateCloseBrandingWindow({ closeBrandingWindow: true }));
    closeExistingSDK();
    dispatch(updateCloseSDK({ closeSDK: true }));
    setClosePopup(true);
    navigate(`/view?id=${workflowId}`);
  };

  const renderSDKBranding = () => (
    activeTab === 1 ? <EditSDKBranding /> : null
  );

  const renderFormModuleDrawer = () => (
    (activeTab === 2 && selectedModuleSubType === 'form') ? <FormModuleDrawer /> : null
  );

  const renderOtherUIElements = () => {
    if (activeTab === 2 && selectedModuleSubType !== 'form') {
      return selectedElement &&
        Object.keys(selectedElement) &&
        brandingElements.length > 0 &&
        brandingElements.find((elem) => Boolean(elem.textConfig)) ? (
          <Grid container>
            <Grid item xs={5}>
              <ShowListOfElements
                selectedElement={selectedElement}
                brandingElements={brandingElements}
                setSelectedElement={onSetSelectedElement}
                selectedModuleId={selectedModuleId}
                selectedWorkflow={selectedWorkflow}
              />
            </Grid>
            <Grid item xs={7} alignItems="center">
              {selectedElement && Object.keys(selectedElement) && (
                <EditIndividualScreens
                  selectedElement={selectedElement}
                  onElementUpdate={onElementUpdate}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div className="edit-branding-popup__no-content">Nothing to edit here!</div>
        );
    }
    return null;
  };

  return closePopup === false ? (
    <div className="edit-branding-popup-background">
      <div className="edit-branding-popup">
        <BrandingNavBar
          activeTab={activeTab}
          handleClick={handleClick}
          isSaving={false}
          handleSave={handleSave}
          languagesSupported={languagesSupported}
          onLanguageChange={onLanguageChange}
          selectedLanguage={languageCodes[selectedLanguage || 'en']}
        />
        <div className="edit-branding-popup__body">
          <ShowListOfModuleScreens
            modulesInWorkflow={modulesAndCompiledWorkflow.listOfListOfModules}
            screensToDisplay={screensToDisplay}
            supportedScreens={supportedScreens}
            updateScreenNames={updateScreenName}
            handleScreenSelect={handleScreenSelect}
            selectedScreen={selectedScreen}
            selectedModuleType={selectedModuleType}
            selectedModuleId={selectedModuleId}
          />
          <div className="sdk-screen">
            <DisplaySDKScreen
              workflowModules={workflowModules}
              selectedWorkflow={modulesAndCompiledWorkflow.workflow}
              screenToTeleport={selectedScreen}
              uiConfig={uiConfig}
              textConfig={textConfig}
              defaultTextConfig={defaultConfigs[`default_${selectedLanguage}_text_config`]}
              moduleToTeleport={selectedModuleId}
              jwtToken={jwtToken}
              customStyleSheets={customStyleSheets}
              reloadSdk={reloadSdk}
              closeSdk={closeSdk}
              selectedLanguage={selectedLanguage}
              selectedElement={selectedElement}
              workflowId={workflowId}
            />
          </div>
          <div className="edit-branding-popup__body__settings">
            {renderSDKBranding()}
            {renderFormModuleDrawer()}
            {renderOtherUIElements()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

EditBrandingContainer.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default EditBrandingContainer;
